import React from 'react';
import styled from 'styled-components';

const MenuContainer = styled.section`
  padding: 4rem 2rem;
`;

const MenuTitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const MenuCategory = styled.div``;

const CategoryTitle = styled.h3`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const MenuItem = styled.div`
  margin-bottom: 1rem;
`;

const ItemName = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`;

const ItemDescription = styled.p`
  font-size: 1rem;
  color: #666;
`;

const ItemPrice = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: #007bff;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #999;
  margin-right: 10px;
`;

const DiscountedPrice = styled.span`
  color: #e74c3c;
`;

const Menu = ({ menuData }) => {
  if (!menuData || menuData.length === 0) {
    return <MenuContainer>No menu items available.</MenuContainer>;
  }

  // Group menu items by category
  const groupedMenuItems = menuData.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  const calculateDiscountedPrice = (price, discountPercentage) => {
    return price - (price * discountPercentage / 100);
  };

  return (
    <MenuContainer>
      <MenuTitle>Menu</MenuTitle>
      <MenuGrid>
        {Object.entries(groupedMenuItems).map(([category, items]) => (
          <MenuCategory key={category}>
            <CategoryTitle>{category}</CategoryTitle>
            {items.map((item) => (
              <MenuItem key={item.uid}>
                <ItemName>{item.name}</ItemName>
                <ItemDescription>{item.description}</ItemDescription>
                <ItemPrice>
                  {item.hasDiscount ? (
                    <>
                      <OriginalPrice>${item.price.toFixed(2)}</OriginalPrice>
                      <DiscountedPrice>
                        ${calculateDiscountedPrice(item.price, item.discountPercentage).toFixed(2)}
                      </DiscountedPrice>
                    </>
                  ) : (
                    `$${item.price.toFixed(2)}`
                  )}
                </ItemPrice>
              </MenuItem>
            ))}
          </MenuCategory>
        ))}
      </MenuGrid>
    </MenuContainer>
  );
};

export default Menu;