import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
  font-size: 0.9rem;
  color: #666;
`;

const PublicPageFooter = () => {
  return (
    <FooterContainer>
      <span>© 2024 Hyperlink.it</span>
    </FooterContainer>
  );
};

export default PublicPageFooter;
