import React, { useState } from 'react';
import { Button, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { optInCompany, optOutCompany } from '../../../redux/authSlice';
import { userOptIn, userOptOut } from '../../../api/userApi';
import { useAuth } from '../../../hooks/useAuth';

const ViewOrJoin = ({ business }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const { idToken } = useAuth();
  const pointsEntry = user.points?.find(point => point.companyId === business.uid);
  const isActive = pointsEntry?.isActive ?? false;

  const handleViewClick = () => {
    const formattedCompanyName = business.companyName.toLowerCase().replace(/\s+/g, '-');
    navigate(`/${formattedCompanyName}`);
  };

  const handleJoinLeaveClick = async () => {
    setLoading(true);
    try {
      if (isActive) {
        await userOptOut(user.uid, business.uid, idToken);
        dispatch(optOutCompany(business.uid));
      } else {
        await userOptIn(user.uid, business.uid, idToken);
        dispatch(optInCompany({
          companyId: business.uid,
          companyName: business.companyName,
          logo: business.logo
        }));
      }
      const cacheKey = `optedCompanies_${user.uid}`; 
      localStorage.removeItem(cacheKey);
    } catch (error) {
      console.error('Error updating opt-in status:', error);
      // You might want to show an error message to the user here
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Button 
        variant="contained" 
        size="small" 
        onClick={handleViewClick}
        sx={{ 
          backgroundColor: '#4285F4', 
          color: '#333',
          '&:hover': { backgroundColor: '#4285F4' }
        }}
      >
        {t('view')}
      </Button>
      <Button 
        variant="contained" 
        size="small" 
        onClick={handleJoinLeaveClick}
        disabled={loading}
        sx={{ 
          backgroundColor: '#4285F4', 
          color: '#333',
          '&:hover': { backgroundColor: '#4285F4' },
          minWidth: '80px'  // Ensure button doesn't resize when showing loading indicator
        }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          isActive ? t('leaveRewards') : t('join')
        )}
      </Button>
    </Box>
  );
};

export default ViewOrJoin;
