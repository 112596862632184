import apiCall from './apiCall';

const API_ENDPOINT = '/pointApi';

export const addPointsToUser = async (userId, companyId, receiptTotal, idToken) => {
  try {
    const response = await apiCall('POST', `${API_ENDPOINT}/${companyId}/add`, {
      userId,
      receiptTotal
    }, false, idToken);
    return response.data;
  } catch (error) {
    console.error('Error in addPointsToUser:', error);
    throw error;
  }
};

export const redeemPointsForUser = async (userId, userPinCode, reward, companyId, idToken) => {
  try {
    const response = await apiCall('POST', `${API_ENDPOINT}/redeem/${companyId}`, {
      userId,
      userPinCode,
      reward
    }, false, idToken);
    return response.data;
  } catch (error) {
    console.error('Error in redeemPointsForUser:', error);
    throw error;
  }
};

export const getPointsRules = async (companyId, idToken) => {
  try {
    const response = await apiCall('GET', `${API_ENDPOINT}/${companyId}/rules`, null, false, idToken);
    return response.data;
  } catch (error) {
    console.error('Error in getPointsRules:', error);
    throw error;
  }
};

export const updatePointsRules = async (companyId, rulesData, idToken) => {
  try {
    const response = await apiCall('PUT', `${API_ENDPOINT}/${companyId}/rules`, rulesData, false, idToken);
    return response.data;
  } catch (error) {
    console.error('Error in updatePointsRules:', error);
    throw error;
  }
};