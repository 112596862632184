import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSetting, undo, redo, setPageSettings, setInitialSettings } from '../../redux/editPageSlice';
import { saveBusinessPage } from '../../api/businessPagesApi';
import ColorPickerButton from './ColorPickerButton';
import { 
  Drawer, 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Stack,
  IconButton,
  Divider,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { ChevronLeft, Image as ImageIcon } from 'lucide-react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import HistoryIcon from '@mui/icons-material/History';

const CustomizeSidebar = ({ open, onClose, onLogoModalOpen, onHeroImageModalOpen, idToken, businessPageId }) => {
  console.log('CustomizeSidebar rendered');
  const settings = useSelector(state => state.editPage.settings);
  const initialSettings = useSelector(state => state.editPage.initialSettings);
  const currentIndex = useSelector(state => state.editPage.currentIndex);
  const history = useSelector(state => state.editPage.history);
  const userData = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [hasChanges, setHasChanges] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  
  console.log('Current state:', { settings, currentIndex, historyLength: history.length });
  console.log({settings})
  useEffect(() => {
    if (JSON.stringify(settings) !== JSON.stringify(initialSettings)) {
      const changes = {};
      Object.keys(settings).forEach(key => {
        if (settings[key] !== initialSettings[key]) {
          changes[key] = settings[key];
        }
      });
      console.log('Changes:', changes);
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [settings, initialSettings]);

  const handleSettingChange = (key, value) => {
    console.log(`Updating setting: ${key} to ${value}`);
    dispatch(updateSetting({ key, value }));
  };

  const handleUndo = () => {
    dispatch(undo());
  };

  const handleRedo = () => {
    dispatch(redo());
  };

  const handleBackToPreviousSave = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmBackToPreviousSave = () => {
    dispatch(setPageSettings(initialSettings));
    setOpenConfirmModal(false);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await saveBusinessPage(
        businessPageId,
        {}, // templateRules (empty object)
        userData.uid,
        userData.companyProfile.uid,
        idToken,
        true, // isTestMode
        settings // testModeTemplateRules
      );
      console.log('Business page saved:', response);
      setHasChanges(false);
      dispatch(setInitialSettings(settings));
      // You might want to show a success message to the user here
    } catch (error) {
      console.error('Error saving business page:', error);
      // You might want to show an error message to the user here
    }
  };

  const SubSectionTitle = ({ children }) => (
    <Typography variant="subtitle2" sx={{ mt: 2, mb: 1, fontWeight: 'bold', color: '#555' }}>
      {children}
    </Typography>
  );

  const textShadowOptions = [
    { value: 'none', label: 'None' },
    { value: '1px 1px 2px rgba(0,0,0,0.5)', label: 'Light' },
    { value: '2px 2px 4px rgba(0,0,0,0.5)', label: 'Medium' },
    { value: '3px 3px 6px rgba(0,0,0,0.5)', label: 'Heavy' },
  ];

  const letterSpacingOptions = [
    { value: 'normal', label: 'Normal' },
    { value: '0.5px', label: 'Slight' },
    { value: '1px', label: 'Medium' },
    { value: '2px', label: 'Wide' },
    { value: '3px', label: 'Extra Wide' },
  ];

  const logoPositionOptions = [
    { value: 'top-left', label: 'Top Left' },
    { value: 'top-center', label: 'Top Center' },
    { value: 'top-right', label: 'Top Right' },
    { value: 'center-left', label: 'Center Left' },
    { value: 'center', label: 'Center' },
    { value: 'center-right', label: 'Center Right' },
    { value: 'bottom-left', label: 'Bottom Left' },
    { value: 'bottom-center', label: 'Bottom Center' },
    { value: 'bottom-right', label: 'Bottom Right' },
  ];

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        width: open ? 320 : 30,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 320,
          boxSizing: 'border-box',
          bgcolor: '#f5f5f5',
          color: '#333',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2, bgcolor: '#1976d2', color: 'white' }}>
        <Typography variant="h6">Customize</Typography>
        <IconButton onClick={onClose} sx={{ color: 'white' }}>
          <ChevronLeft />
        </IconButton>
      </Box>
      <Box sx={{ p: 2, overflowY: 'auto', height: 'calc(100% - 120px)' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Basic Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <TextField
                fullWidth
                label="Business Name"
                name="businessName"
                value={settings.businessName}
                onChange={(e) => handleSettingChange('businessName', e.target.value)}
              />
              <TextField
                fullWidth
                label="URL Slug"
                name="slug"
                value={userData.companyProfile.uniqueUrl}
                disabled
                helperText={`Your website will be available at: hyperlink.it.com/${userData.companyProfile.uniqueUrl || 'your-business-name'}`}
              />
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={settings.description}
                onChange={(e) => handleSettingChange('description', e.target.value)}
                multiline
                rows={3}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Header Design</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SubSectionTitle>Background</SubSectionTitle>
            <Stack spacing={2}>
              <ColorPickerButton
                color={settings.headerBackgroundColor}
                colorType="headerBackgroundColor"
                label="Header Background Color"
                onChange={handleSettingChange}
              />
              <Button variant="outlined" startIcon={<ImageIcon />} onClick={onHeroImageModalOpen}>
                {settings.heroImage ? 'Change Hero Image' : 'Choose Hero Image'}
              </Button>
              <TextField
                fullWidth
                type="number"
                label="Hero Image Blur"
                value={settings.heroImageBlur}
                onChange={(e) => handleSettingChange('heroImageBlur', e.target.value)}
                inputProps={{ min: 0, max: 20 }}
              />
              <TextField
                fullWidth
                type="number"
                label="Header Height (px)"
                value={settings.headerHeight}
                onChange={(e) => handleSettingChange('headerHeight', e.target.value)}
                inputProps={{ min: 100 }} // Set a minimum height
              />
            </Stack>

            <SubSectionTitle>Logo</SubSectionTitle>
            <Stack spacing={2}>
              <Button variant="outlined" startIcon={<ImageIcon />} onClick={onLogoModalOpen}>
                {settings?.logoUrl ? 'Change Logo' : 'Choose Logo'}
              </Button>
              <TextField
                fullWidth
                select
                label="Logo Position"
                value={settings.logoPosition}
                onChange={(e) => handleSettingChange('logoPosition', e.target.value)}
              >
                {logoPositionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                select
                label="Logo Order"
                value={settings.logoOrder}
                onChange={(e) => handleSettingChange('logoOrder', e.target.value)}
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </TextField>
              <TextField
                fullWidth
                type="number"
                label="Logo Width"
                value={settings.logoWidth}
                onChange={(e) => handleSettingChange('logoWidth', e.target.value)}
              />
              <TextField
                fullWidth
                type="number"
                label="Logo Height"
                value={settings.logoHeight}
                onChange={(e) => handleSettingChange('logoHeight', e.target.value)}
              />
              <TextField
                fullWidth
                select
                label="Logo Column Width"
                value={settings.logoColumnWidth}
                onChange={(e) => handleSettingChange('logoColumnWidth', parseInt(e.target.value))}
                helperText="Choose how many columns out of 12 the logo should occupy"
              >
                {[1, 2, 3, 4, 5, 6].map((width) => (
                  <MenuItem key={width} value={width}>{width}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                type="number"
                label="Logo Scale"
                value={settings.logoScale}
                onChange={(e) => handleSettingChange('logoScale', parseFloat(e.target.value))}
                inputProps={{ step: 0.1, min: 0.1, max: 5 }}
                helperText="Scale factor (1 = 100%, 0.5 = 50%, 2 = 200%, etc.)"
              />
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Business Name</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <TextField
                fullWidth
                type="number"
                label="Font Size"
                value={settings.businessNameFontSize}
                onChange={(e) => handleSettingChange('businessNameFontSize', e.target.value)}
              />
              <ColorPickerButton
                color={settings.businessNameColor}
                colorType="businessNameColor"
                label="Color"
                onChange={handleSettingChange}
              />
              <TextField
                fullWidth
                select
                label="Font Weight"
                value={settings?.businessNameFontWeight}
                onChange={(e) => handleSettingChange('businessNameFontWeight', e.target.value)}
              >
                {['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'].map((weight) => (
                  <MenuItem key={weight} value={weight}>{weight}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                select
                label="Text Shadow"
                value={settings.businessNameTextShadow}
                onChange={(e) => handleSettingChange('businessNameTextShadow', e.target.value)}
              >
                {textShadowOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                select
                label="Letter Spacing"
                value={settings.businessNameLetterSpacing}
                onChange={(e) => handleSettingChange('businessNameLetterSpacing', e.target.value)}
              >
                {letterSpacingOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                select
                label="Text Transform"
                value={settings.businessNameTextTransform}
                onChange={(e) => handleSettingChange('businessNameTextTransform', e.target.value)}
              >
                {['none', 'uppercase', 'lowercase', 'capitalize'].map((transform) => (
                  <MenuItem key={transform} value={transform}>{transform}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                select
                label="Text Alignment"
                value={settings.businessNameAlignment}
                onChange={(e) => handleSettingChange('businessNameAlignment', e.target.value)}
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="center">Center</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </TextField>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Description</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <TextField
                fullWidth
                type="number"
                label="Font Size"
                value={settings.descriptionFontSize}
                onChange={(e) => handleSettingChange('descriptionFontSize', e.target.value)}
              />
              <ColorPickerButton
                color={settings.descriptionColor}
                colorType="descriptionColor"
                label="Color"
                onChange={handleSettingChange}
              />
              <TextField
                fullWidth
                select
                label="Font Weight"
                value={settings.descriptionFontWeight}
                onChange={(e) => handleSettingChange('descriptionFontWeight', e.target.value)}
              >
                {['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'].map((weight) => (
                  <MenuItem key={weight} value={weight}>{weight}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                select
                label="Text Shadow"
                value={settings.descriptionTextShadow}
                onChange={(e) => handleSettingChange('descriptionTextShadow', e.target.value)}
              >
                {textShadowOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                select
                label="Letter Spacing"
                value={settings.descriptionLetterSpacing}
                onChange={(e) => handleSettingChange('descriptionLetterSpacing', e.target.value)}
              >
                {letterSpacingOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                label="Line Height"
                value={settings.descriptionLineHeight}
                onChange={(e) => handleSettingChange('descriptionLineHeight', e.target.value)}
              />
              <TextField
                fullWidth
                select
                label="Text Alignment"
                value={settings.descriptionAlignment}
                onChange={(e) => handleSettingChange('descriptionAlignment', e.target.value)}
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="center">Center</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </TextField>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Items Section</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <Switch
                checked={settings.showItems}
                onChange={(e) => handleSettingChange('showItems', e.target.checked)}
                name="showItems"
                color="primary"
              />
              <TextField
                fullWidth
                type="number"
                label="Title Font Size"
                value={settings.itemsTitleFontSize}
                onChange={(e) => handleSettingChange('itemsTitleFontSize', e.target.value)}
              />
              <ColorPickerButton
                color={settings.itemsTitleColor}
                colorType="itemsTitleColor"
                label="Title Color"
                onChange={handleSettingChange}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Rewards Section</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <Switch
                checked={settings.showRewards}
                onChange={(e) => handleSettingChange('showRewards', e.target.checked)}
                name="showRewards"
                color="primary"
              />
              <TextField
                fullWidth
                type="number"
                label="Title Font Size"
                value={settings.rewardsTitleFontSize}
                onChange={(e) => handleSettingChange('rewardsTitleFontSize', e.target.value)}
              />
              <ColorPickerButton
                color={settings.rewardsTitleColor}
                colorType="rewardsTitleColor"
                label="Title Color"
                onChange={handleSettingChange}
              />
               <ColorPickerButton
                color={settings.rewardsSecondaryColor}
                colorType="rewardsSecondaryColor"
                label="Secondary Color"
                onChange={handleSettingChange}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Contact and Hours</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <ColorPickerButton
                color={settings.contactAndHoursBackgroundColor}
                colorType="contactAndHoursBackgroundColor"
                label="Background Color"
                onChange={handleSettingChange}
              />
              <ColorPickerButton
                color={settings.contactAndHoursSecondaryColor}
                colorType="contactAndHoursSecondaryColor"
                label="Secondary Color"
                onChange={handleSettingChange}
              />
              <ColorPickerButton
                color={settings.contactTextColor}
                colorType="contactTextColor"
                label="Contact Text Color"
                onChange={handleSettingChange}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>General Design</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <ColorPickerButton
                color={settings.primaryColor}
                colorType="primaryColor"
                label="Primary Color"
                onChange={handleSettingChange}
              />
              <ColorPickerButton
                color={settings.cardColor}
                colorType="cardColor"
                label="Card Color"
                onChange={handleSettingChange}
              />
              <ColorPickerButton
                color={settings.cardTextColor}
                colorType="cardTextColor"
                label="Card Text Color"
                onChange={handleSettingChange}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>iFrame</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
            <Switch
                checked={settings.showiFrame}
                onChange={(e) => handleSettingChange('showiFrame', e.target.checked)}
                name="showiFrame"
                color="primary"
              />
            <TextField
              fullWidth
              label="iFrame Link"
              name="iFrameLink"
              value={settings.iFrameLink}
              onChange={(e) => handleSettingChange('iFrameLink', e.target.value)}
            />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0', bgcolor: '#fff' }}>
        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={handleUndo}
            disabled={currentIndex <= 0}
            startIcon={<UndoIcon />}
          >
            Undo
          </Button>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={handleBackToPreviousSave}
            disabled={!hasChanges || JSON.stringify(settings) === JSON.stringify(initialSettings)}
            startIcon={<HistoryIcon />}
          >
            Back to Previous Save
          </Button>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={handleRedo}
            disabled={currentIndex >= history.length - 1}
            startIcon={<RedoIcon />}
          >
            Redo
          </Button>
        </Stack>
        <Button 
          variant="contained" 
          color="primary" 
          fullWidth 
          onClick={handleSaveEdit}
          disabled={!hasChanges}
        >
          Save Edit
        </Button>
      </Box>
      <Dialog
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Action"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to revert to the previous saved version? All unsaved changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmBackToPreviousSave} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};

export default React.memo(CustomizeSidebar);
