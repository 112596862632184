import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

const ProductDisplay = ({ settings, products }) => {
  return (
    <Box sx={{ mb: 4 }} className="isEditableComponent-productsSection">
      <Typography 
        variant="h5" 
        sx={{ 
          mb: 2, 
          fontWeight: 'bold',
          fontSize: `${settings.itemsTitleFontSize}px`,
          color: settings.itemsTitleColor,
        }} 
        className="isEditableComponent-productsTitle"
      >
        Our Products
      </Typography>
      <Grid container spacing={2} className="isEditableComponent-productsGrid">
        {products.map((product, index) => (
          <Grid item xs={12} sm={6} md={4} key={`${index}-${product.name}`}>
            <Card sx={{ bgcolor: settings.cardColor }}>
              <CardMedia
                component="img"
                height="140"
                image={product.image}
                alt={product.name}
              />
              <CardContent sx={{ color: settings.cardTextColor }}>
                <Typography variant="h6">{product.name}</Typography>
                <Typography>{product.description}</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>${product.price}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default React.memo(ProductDisplay);

