import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitContactForm } from '../redux/authSlice';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/Home.css';

const Home = ({ deferredPrompt, handleInstallClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contactFormStatus = useSelector((state) => state.auth.contactFormStatus);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      companyName: e.target.companyName.value,
      message: e.target.message.value
    };
    try {
      await dispatch(submitContactForm(formData)).unwrap();
      e.target.reset();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="home-page">
      <section className="hero-section">
        <div className="hero-content">
          <h1>{t('heroTitle')}</h1>
          <p className="hero-subtitle">{t('heroSubtitle')}</p>
          <p className="hero-description">{t('heroDescription')}</p>
          <div className="hero-cta">
            <Link to="/signup" className="btn btn-primary">{t('getStartedButton')}</Link>
            <Link to="/login" className="btn btn-secondary">{t('loginButton')}</Link>
          </div>
        </div>
        <div className="hero-image">
          <img src="/defaultHeroImage.png" alt="Company Logo" />
          {/* Add an image or illustration here */}
        </div>
      </section>

      <section className="features-section">
        <h2>{t('features')}</h2>
        <div className="feature-list">
          <div className="feature-item">
            <h3>{t('rewardsProgram')}</h3>
            <p>{t('rewardsProgramDesc')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('customProfiles')}</h3>
            <p>{t('customProfilesDesc')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('easyIntegration')}</h3>
            <p>{t('easyIntegrationDesc')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('analytics')}</h3>
            <p>{t('analyticsDesc')}</p>
          </div>
        </div>
      </section>

      <section className="how-it-works">
        <h2>{t('howItWorks')}</h2>
        <div className="steps">
          <div className="step">
            <div className="step-number">1</div>
            <h3>{t('step1Title')}</h3>
            <p>{t('step1Desc')}</p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <h3>{t('step2Title')}</h3>
            <p>{t('step2Desc')}</p>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <h3>{t('step3Title')}</h3>
            <p>{t('step3Desc')}</p>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <h2>{t('getStarted')}</h2>
        <p>{t('contactUs')}</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder={t('yourName')} required />
          <input type="email" name="email" placeholder={t('yourEmail')} required />
          <input type="text" name="companyName" placeholder={t('companyName')} required />
          <textarea name="message" placeholder={t('message')} required></textarea>
          <button type="submit" className="btn btn-primary" disabled={contactFormStatus === 'loading'}>
            {contactFormStatus === 'loading' ? t('sending') : t('sendMessage')}
          </button>
        </form>
        {contactFormStatus === 'succeeded' && (
          <p className="success-message">{t('thankYou')}</p>
        )}
        {contactFormStatus === 'failed' && (
          <p className="error-message">{t('errorMessage')}</p>
        )}
      </section>

      <section className="pwa-section">
        <h2>{t('downloadApp')}</h2>
        <p>{t('installApp')}</p>
        {deferredPrompt ? (
          <button onClick={handleInstallClick} className="btn btn-primary">
            {t('installButton')}
          </button>
        ) : (
          <p>{t('installFromBrowser')}</p>
        )}
      </section>
    </div>
  );
};

export default Home;
