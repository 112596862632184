import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAllCompanyItems } from '../../api/companyApi';
import '../../styles/ViewCompanyDetails.css';
import '../../styles/ViewCompanyItems.css';
import { useAuth } from '../../hooks/useAuth';

const ViewCompanyItems = () => {
  const { companyId } = useParams();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { idToken } = useAuth();

  useEffect(() => {
    idToken && fetchItems();
  }, [companyId, idToken]);

  const handleEditItem = (item) => {
    console.log({item})
  };

  const handleDeleteItem = (item) => {
    console.log({item});
  };

  const fetchItems = async () => {
    try {
      const fetchedItems = await getAllCompanyItems(companyId, idToken);
      setItems(fetchedItems.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch company items');
      setLoading(false);
    }
  };

  if (loading) return <div className="view-company-items">Loading...</div>;
  if (error) return <div className="view-company-items">{error}</div>;
  console.log({items});
  const renderTable = (itemType) => {
    // const filteredItems = items?.filter(item => item.itemType === itemType);
    const filteredItems = items

    return (
      <div>
        <h2>{itemType.charAt(0).toUpperCase() + itemType.slice(1)} Items</h2>
        {filteredItems.length === 0 ? (
          <p>No {itemType} items found.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Price</th>
                <th>Category</th>
                <th>Available</th>
                <th>Image</th>
                <th>Featured</th>
                <th>Has Discount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems?.map((item) => (
                <tr key={item.uid}>
                  <td>{item.name}</td>
                  <td>${item.price.toFixed(2)}</td>
                  <td>{item.category}</td>
                  <td>{item.isAvailable ? 'Yes' : 'No'}</td>
                  <td><img src={item.image} alt={item.name} /></td>
                  <td>{item.isFeatured ? 'Yes' : 'No'}</td>
                  <td>{item.hasDiscount ? `${item.discountPercentage}%` : 'No'}</td>
                  <td>
                    <button onClick={() => handleEditItem(item)}>Edit</button>
                    <button onClick={() => handleDeleteItem(item)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  return (
    <div className="view-company-items">
      <h1>Company Items</h1>
      {renderTable('menu')}
      {renderTable('product')}
      {renderTable('service')}
    </div>
  );
};

export default ViewCompanyItems;
