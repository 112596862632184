import apiCall from './apiCall';

const API_DOMAIN = '/stripeApi';

export const createCheckoutSession = async (lookupKey, idToken, user) => {
  try {
    const response = await apiCall('POST', `${API_DOMAIN}/create-checkout-session`, { lookup_key: lookupKey, user }, false, idToken);
    return response;
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};

export const createCustomerPortalSession = async (idToken, user) => {
  try {
    const response = await apiCall('POST', `${API_DOMAIN}/create-portal-session`, { user }, false, idToken);
    return response;
  } catch (error) {
    console.error('Error creating customer portal session:', error);
    throw error;
  }
};
