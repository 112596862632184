import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getStorageUrl } from '../../../firebaseConfig';

const RewardList = ({ rewards, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const handleImageError = (e, rewardName) => {
    console.error(`Error loading image for reward: ${rewardName}`);
    e.target.style.display = 'none';
    e.target.nextSibling.style.display = 'inline';
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('name')}</TableCell>
            <TableCell>{t('description')}</TableCell>
            <TableCell>{t('pointsCost')}</TableCell>
            <TableCell>{t('image')}</TableCell>
            <TableCell>{t('isAvailable')}</TableCell>
            <TableCell>{t('actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rewards?.map((reward) => {
            console.log({reward});
            return (
              <TableRow key={reward.uid}>
                <TableCell>{reward.name}</TableCell>
                <TableCell>{reward.description}</TableCell>
                <TableCell>{reward.pointsCost}</TableCell>
                <TableCell>
                  {reward.image ? (
                    <>
                      <img 
                        src={reward.image}
                        alt={reward.name} 
                        style={{ width: '50px', height: '50px' }} 
                        onError={(e) => handleImageError(e, reward.name)}
                      />
                      <span style={{ display: 'none' }}>{t('imageNotAvailable')}</span>
                    </>
                  ) : (
                    <span>{t('noImage')}</span>
                  )}
                </TableCell>
                <TableCell>{reward.isAvailable ? t('yes') : t('no')}</TableCell>
                <TableCell>
                  <IconButton onClick={() => onEdit(reward.uid)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => onDelete(reward.uid)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RewardList;