import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const CategoryList = ({ categories }) => {
  const { t } = useTranslation();
  
  return (
    <CategoryContainer>
      <StyledSwiper
        spaceBetween={10}
        slidesPerView={5}
        freeMode={true}
      >
        {categories.map((category) => (
          <SwiperSlide key={category.id} style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <CategoryItem style={{
              borderRadius: '50%',
              border: '1px solid #ccc',
              backgroundColor: '#fff',
              width: '35px',
              height: '35px',
              marginBottom: '5px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}>
              <CategoryEmoji>{category.icon}</CategoryEmoji>
            </CategoryItem>
            <CategoryName>{t(category.nameKey) || category.name}</CategoryName>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </CategoryContainer>
  );
};

const CategoryContainer = styled.div`
  width: 100%;
  /* margin: 10px 0px 0px 0px; */
  margin: 10px;
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
`;

const CategoryItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const CategoryEmoji = styled.span`
  font-size: 16px;
  margin-bottom: 5px;
`;

const CategoryName = styled.p`
  font-size: 11px;
  text-align: center;
`;

export default CategoryList;
