import apiCall from './apiCall';

const API_ENDPOINT = '/itemApi';

export const createItem = async (companyId, itemData, imageFile, idToken) => {
  const formData = new FormData();
  Object.keys(itemData).forEach(key => {
    formData.append(key, itemData[key]);
  });
  if (imageFile) {
    formData.append('image', imageFile);
  }
  formData.append('companyId', companyId);
  return apiCall('POST', `${API_ENDPOINT}`, formData, true, idToken);
};

export const getItem = async (itemId) => {
  return apiCall('GET', `${API_ENDPOINT}/${itemId}`);
};

export const updateItem = async (itemId, itemData, imageFile, idToken) => {
  const formData = new FormData();
  Object.keys(itemData).forEach(key => {
    formData.append(key, itemData[key]);
  });
  if (imageFile) {
    formData.append('image', imageFile);
  }
  return apiCall('PUT', `${API_ENDPOINT}/${itemId}`, formData, true, idToken);
};

export const deleteItem = async (itemId, idToken) => {
  return apiCall('DELETE', `${API_ENDPOINT}/${itemId}`, null, false, idToken);
};

export const listItems = async (companyId, category, isAvailable = true) => {
  let url = `${API_ENDPOINT}/list`;
  if (companyId) {
    url += `?companyId=${companyId}`;
    if (category) {
      url += `&category=${category}`;
    }
    if (!isAvailable) {
      url += `&isAvailable=${isAvailable}`;
    }
  } else if (category) {
    url += `?category=${category}`;
  }
  return apiCall('GET', url);
};

export const searchItems = async (query) => {
  return apiCall('GET', `${API_ENDPOINT}/search?query=${encodeURIComponent(query)}`);
};
