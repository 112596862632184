import React, { useState, useEffect } from 'react';
import { Typography, Box, Card, CardContent, CircularProgress, TextField, Select, MenuItem, FormControl, InputLabel, Chip, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { getOptedInCompanies } from '../../api/userApi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AdSenseAd from '../../services/AdSenseAd';

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const OptedRewards = () => {
  const { t } = useTranslation();
  const { idToken } = useAuth();
  const user = useSelector((state) => state.auth.user);
  const [optedCompanies, setOptedCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');
  const [nameFilter, setNameFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOptedCompanies = async () => {
      if (idToken && user?.uid) {
        setLoading(true);
        const cacheKey = `optedCompanies_${user.uid}`;
        const cachedData = localStorage.getItem(cacheKey);

        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          if (Date.now() - timestamp < CACHE_DURATION) {
            setOptedCompanies(data);
            setLoading(false);
            return;
          }
        }

        try {
          const realCompaniesResponse = await getOptedInCompanies(user.uid, idToken);
          const realCompanies = realCompaniesResponse.optedInCompanies;
          realCompanies.forEach(company => {
            const userPoints = user.points.find(point => point.companyId === company.uid);
            company.points = userPoints ? { pointsBalance: userPoints.pointsBalance } : { pointsBalance: 0 };
          });
          
          setOptedCompanies(realCompanies);
          localStorage.setItem(cacheKey, JSON.stringify({ data: realCompanies, timestamp: Date.now() }));
        } catch (error) {
          console.error('Error fetching opted companies:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchOptedCompanies();
  }, [idToken, user]);

  const handleCompanyClick = (company) => {
    navigate(`/rewards/${company.uid}`);
  }
  console.log({optedCompanies});
  const filteredAndSortedCompanies = optedCompanies
    .filter(company => 
      company?.companyName?.toLowerCase().includes(nameFilter.toLowerCase()) &&
      company?.category?.toLowerCase().includes(categoryFilter.toLowerCase()) &&
      company?.address?.city?.toLowerCase().includes(cityFilter.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.companyName.localeCompare(b.companyName);
      } else {
        return b.companyName.localeCompare(a.companyName);
      }
    });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ textAlign: 'center', px: 2, pt: 3, color: '#333' }} className="opted-rewards-container">
      <Typography variant="h4" gutterBottom>
        {t('rewardsTitle')}
      </Typography>
      {/* <AdSenseAd /> */}

      {!optedCompanies.length && !loading ? (
        <Typography variant="h6" gutterBottom>
          {t('noOptedInCompanies')}
        </Typography>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, flexDirection: 'column', alignItems: 'center', }} className="opted-rewards-filters">
            <Button sx={{ marginBottom: 2, color: '#333', borderColor: '#333' }} variant="outlined" color={showFilters ? 'secondary' : 'primary'} onClick={() => setShowFilters(!showFilters)}>
              {t('filters')}
            </Button>
           
            {showFilters && (
              <>
                <FormControl sx={{ marginTop: 2, borderRadius: 2, backgroundColor: 'white' }}>
                  <TextField
                    value={nameFilter}
                    label={t('filterByName')}
                    onChange={(e) => setNameFilter(e.target.value)}
                    size="small"
                    color="primary"
                    sx={{ color: 'white' }}
                  />
                </FormControl>
                <FormControl sx={{ marginTop: 2, borderRadius: 2, backgroundColor: 'white' }}>
                  <TextField
                    value={categoryFilter}
                    label={t('filterByCategory')}
                    onChange={(e) => setCategoryFilter(e.target.value)}
                    size="small"
                    color="primary"
                  />
                </FormControl>
                <FormControl sx={{ marginTop: 2, borderRadius: 2, backgroundColor: 'white' }}>
                  <TextField
                    value={cityFilter}
                    label={t('filterByCity')}
                    onChange={(e) => setCityFilter(e.target.value)}
                    size="small"
                    color="primary"
                  />
                </FormControl>
              </>
            )}
            <FormControl size="small" sx={{ marginTop: 2, borderRadius: 2, backgroundColor: '#f0f3f6' }}>
              <InputLabel id="sort-order-label" sx={{ color: '#333' }}>{t('sortOrder')}</InputLabel>
              <Select
                labelId="sort-order-label"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                label={t('sortOrder')}
                sx={{ color: '#333', borderColor: '#333' }}
              >
                <MenuItem value="asc">{t('nameAZ')}</MenuItem>
                <MenuItem value="desc">{t('nameZA')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 3,
            justifyContent: 'center',
            alignItems: 'stretch',
          }}>
            {filteredAndSortedCompanies.map((company) => (
              <Card key={company.uid} sx={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#f0f3f6' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={company.avatar || company.logo} alt={company.companyName} width="35" height="35" />
                  </Box>
                  <Typography variant="subtitle1" component="div" noWrap onClick={() => handleCompanyClick(company)}>
                    {company.companyName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {t(company.category.toLowerCase())}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {company.address.city}
                  </Typography>
                  <Chip label={`${company?.points?.pointsBalance || 0} ${t('pointsTitle')}`} size="small" color={company?.points?.pointsBalance > 0 ? 'primary' : 'warning'} />
                </CardContent>
              </Card>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default OptedRewards;
