import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, updateUser } from './redux/authSlice';
import { loginUser } from './api/userApi';
import { useAuth } from './hooks/useAuth';
import { CircularProgress, Box } from '@mui/material';
import './App.css';
import MainLayout from './layouts/MainLayout';
import LoggedOutLayout from './layouts/LoggedOutLayout';
import Home from './pages/Home';
import UserDashboard from './pages/UserDashboard';
import AdminDashboard from './pages/AdminDashboard';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import EditProfile from './components/users/EditProfile';
import CreateCompany from './components/admin/CreateCompany';
import ViewCompanies from './components/admin/ViewCompanies';
import ViewUsers from './components/admin/ViewUsers';
import ViewRewards from './components/admin/ViewRewards';
import ViewProducts from './components/admin/ViewProducts';
import ViewAds from './components/admin/ViewAds';
import ViewEvents from './components/admin/ViewEvents';
import ViewCalendars from './components/admin/ViewCalendars';
import ViewCompanyRewards from './components/admin/ViewCompanyRewards';
import CompanySignUp from './components/companies/CompanySignUp';
import CompanyDashboard from './pages/CompanyDashboard';
import ProfileSettings from './components/companies/ProfileSettings';
import CustomizeBusinessPage from './components/companies/CustomizeBusinessPage';
import RewardsManagement from './components/companies/RewardsManagement';
import EventsManagement from './components/companies/EventsManagement';
import CalendarManagement from './components/companies/CalendarManagement';
import AdsManagement from './components/companies/AdsManagement';
import PointsManagement from './components/companies/PointsManagement';
// import PublicCompanyPage from './components/PublicCompanyPage';
import PublicCompanyLayout from './layouts/PublicCompanyLayout';
import ManageMyPoints from './components/users/userPointsOverviewComponents/ManageMyPoints';
import ViewCompanyItems from './components/admin/ViewCompanyItems';
import ItemsManagement from './components/companies/ItemsManagement';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import CustomImages from './components/companies/CustomImages';
import Settings from './components/users/Settings';
import Points from './components/users/Points';
import OptedRewards from './components/users/OptedRewards';
import CompanyRewards from './components/users/CompanyRewards';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import WebsiteGenerator from './components/companyBusinessPage/WebsiteGenerator';
import PreviewWebsite from './components/companyBusinessPage/PreviewWebsite';
import UserHome from './pages/UserHome';
// import 'swiper/swiper-bundle.css';
import { Helmet } from 'react-helmet';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';

const ProtectedRoute = React.memo(({ children, adminOnly = false, companyOnly = false }) => {
  const userData = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      navigate('/login', { replace: true });
    } else if (adminOnly && !userData.isAdmin) {
      navigate('/', { replace: true });
    } else if (companyOnly && !userData.isCompanyAdmin) {
      navigate('/', { replace: true });
    }
  }, [userData, adminOnly, companyOnly, navigate]);

  return userData ? children : null;
});

ProtectedRoute.displayName = 'ProtectedRoute';

function App() {
  const [loading, setLoading] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const location = useLocation();

  const { user, idToken } = useAuth();

  useEffect(() => {
    console.log('useEffect loginUser');
    const fetchUserData = async () => {
      if (user && idToken && !userData) {
        try {
          const fetchedUserData = await loginUser(user.email, idToken);
          if (fetchedUserData.isCompanyAdmin && fetchedUserData.companyId) {
            const companyRef = doc(db, "companies", fetchedUserData.companyId);
            const companySnap = await getDoc(companyRef);
            if (companySnap.exists()) {
              fetchedUserData.companyProfile = companySnap.data();
            }
          }
          dispatch(updateUser(fetchedUserData));
        } catch (error) {
          console.error("Error fetching user data:", error);
          console.log('logout________%%%%%%%%%');
          dispatch(logout());
        }
      } else if (!user && userData && !localStorage.getItem('user')) {
        console.log('logout________**********');
        dispatch(logout());
      }
      setLoading(false);
    };

    fetchUserData();
  }, [user, idToken, dispatch, userData, navigate]);

  useEffect(() => {
    console.log('useEffect onAuthStateChanged');
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userRef = doc(db, 'users', firebaseUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (userData.createdAt && typeof userData.createdAt.toDate === 'function') {
            userData.createdAt = userData.createdAt.toDate().toISOString();
          }
          dispatch(updateUser({ ...userData, uid: firebaseUser.uid }));
        }
      } else {
        dispatch(logout());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    console.log('useEffect handleBeforeInstallPrompt');
    const handleBeforeInstallPrompt = (e) => {
      // console.log('handleBeforeInstallPrompt');
      e.preventDefault();
      setDeferredPrompt(e);
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // console.log('User accepted the install prompt');
        } else {
          // console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    console.log('useEffect logout from timeout');
    const timeout = setTimeout(() => {
      if (loading) {
        console.log('logout from timeout');
        setLoading(false);
        dispatch(logout());
        navigate('/login',);
      }
    }, 10000);

    return () => clearTimeout(timeout);
  }, [loading, dispatch, navigate]);

  useEffect(() => {
    if (analytics && process.env.NODE_ENV !== 'development') {
      logEvent(analytics, 'page_view', {
        page_path: location.pathname,
        page_title: document.title,
        page_location: window.location.href
      });
    }
  }, [location]);

  const layoutProps = {
    deferredPrompt,
    handleInstallClick,
    user: userData,
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        {/* For iOS Safari */}
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="theme-color" content="#D32F2F" />
        
        {/* For Chrome, Firefox OS and Opera */}
        <meta name="theme-color" content="#D32F2F" />
        
        {/* For Windows Phone */}
        <meta name="msapplication-navbutton-color" content="#D32F2F" />
      </Helmet>
      <Routes>
        <Route 
          element={userData ? <MainLayout {...layoutProps} /> : <LoggedOutLayout {...layoutProps} />}
        >
          <Route index element={ <UserHome />} />
          <Route path="signup" element={userData ? <Navigate to="/" replace /> : <SignUp />} />
          <Route path="login" element={userData ? <Navigate to="/" replace /> : <Login />} />
          <Route path="edit-profile" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
          <Route path="info" element={ <Home />} />
          
          {/* Admin routes */}
          <Route path="admin" element={
            <ProtectedRoute adminOnly>
              <AdminDashboard />
            </ProtectedRoute>
          } />
          <Route path="admin/create-company" element={
            <ProtectedRoute adminOnly>
              <CreateCompany />
            </ProtectedRoute>
          } />
          <Route path="admin/companies" element={<ProtectedRoute adminOnly><ViewCompanies /></ProtectedRoute>} />
          <Route path="admin/users" element={<ProtectedRoute adminOnly><ViewUsers /></ProtectedRoute>} />
          <Route path="admin/rewards" element={<ProtectedRoute adminOnly><ViewRewards /></ProtectedRoute>} />
          <Route path="admin/products" element={<ProtectedRoute adminOnly><ViewProducts /></ProtectedRoute>} />
          <Route path="admin/ads" element={<ProtectedRoute adminOnly><ViewAds /></ProtectedRoute>} />
          <Route path="admin/events" element={<ProtectedRoute adminOnly><ViewEvents /></ProtectedRoute>} />
          <Route path="admin/calendars" element={<ProtectedRoute adminOnly><ViewCalendars /></ProtectedRoute>} />
          <Route path="admin/company/:companyId/rewards" element={<ProtectedRoute adminOnly><ViewCompanyRewards /></ProtectedRoute>} />
          <Route path="admin/company/:companyId/items" element={<ProtectedRoute adminOnly><ViewCompanyItems /></ProtectedRoute>} />
          
          {/* Company routes */}
          <Route path="company-signup" element={<CompanySignUp />} />
          <Route path="company-dashboard" element={
            <ProtectedRoute companyOnly>
              <CompanyDashboard />
            </ProtectedRoute>
          } />
          <Route path="/company/profile" element={<ProtectedRoute companyOnly><ProfileSettings /></ProtectedRoute>} />
          <Route path="/company/website" element={<ProtectedRoute companyOnly><WebsiteGenerator /></ProtectedRoute>} />
          {/* <Route path="/company/website" element={<ProtectedRoute companyOnly><CustomizeBusinessPage /></ProtectedRoute>} /> */}
          <Route path="/company/items" element={<ProtectedRoute companyOnly><ItemsManagement /></ProtectedRoute>} />
          <Route path="/company/rewards" element={<ProtectedRoute companyOnly><RewardsManagement /></ProtectedRoute>} />
          <Route path="/company/events" element={<ProtectedRoute companyOnly><EventsManagement /></ProtectedRoute>} />
          <Route path="/company/calendar" element={<ProtectedRoute companyOnly><CalendarManagement /></ProtectedRoute>} />
          <Route path="/company/ads" element={<ProtectedRoute companyOnly><AdsManagement /></ProtectedRoute>} />
          <Route path="/company/points" element={<ProtectedRoute companyOnly><PointsManagement /></ProtectedRoute>} />
          <Route path="/company/gallery" element={<ProtectedRoute companyOnly><CustomImages /></ProtectedRoute>} />
          <Route path="/manage-points/:companyId" element={<ProtectedRoute><ManageMyPoints /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/points" element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
          <Route path="/rewards" element={<ProtectedRoute><OptedRewards /></ProtectedRoute>} />
          <Route path="/rewards/:companyId" element={<ProtectedRoute><CompanyRewards /></ProtectedRoute>} />
        </Route>
        
        {/* Public company page route */}
        <Route element={<PublicCompanyLayout />}>
          <Route path="/:companyName" element={<PreviewWebsite />} />
          {/* <Route path="/:companyName" element={<PublicCompanyPage />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
