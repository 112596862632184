import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Card, CardMedia, CardActions, IconButton, CardHeader } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { uploadGalleryImage, deleteGalleryImage, getCompanyGallery } from '../../api/companyApi';
import { useAuth } from '../../hooks/useAuth';

const CustomImages = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.user);
    const [images, setImages] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageName, setImageName] = useState('');
    const { idToken } = useAuth();

    useEffect(() => {
        idToken && fetchImages();
    }, [idToken]);

    const fetchImages = async () => {
        try {
            const fetchedImages = await getCompanyGallery(user.companyId, idToken);
            setImages(fetchedImages);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        setImageFile(null);
        setImagePreview(null);
        setImageName('');
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpload = async () => {
        try {
            await uploadGalleryImage(user.companyId, imageFile, imageName, idToken);
            handleCloseDialog();
            fetchImages();
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleDelete = async (imageId) => {
        try {
            await deleteGalleryImage(user.companyId, imageId, idToken);
            fetchImages();
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    return (
        <Box>
            <h1>{t('customImages')}</h1>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenDialog}>
                {t('uploadImage')}
            </Button>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}>
                {images.map((image) => (
                    <Card key={image.uid} sx={{ width: 200, textAlign: 'center' }}>
                        <CardHeader
                            // title={image.name}
                            subheader={image.name}
                        />
                        <CardMedia
                            component="img"
                            height="140"
                            image={image.imageUrl}
                            alt={image.name}
                        />
                        <CardActions sx={{ justifyContent: 'end' }}>
                            <IconButton onClick={() => handleDelete(image.uid)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </CardActions>
                    </Card>
                ))}
            </Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{t('uploadImage')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('imageName')}
                        fullWidth
                        value={imageName}
                        onChange={(e) => setImageName(e.target.value)}
                    />
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleImageChange}
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" component="span">
                            {t('chooseFile')}
                        </Button>
                    </label>
                    {imagePreview && (
                        <Box sx={{ mt: 2, maxWidth: '100%' }}>
                            <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%' }} />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{t('cancel')}</Button>
                    <Button onClick={handleUpload} color="primary" disabled={!imageFile || !imageName}>
                        {t('upload')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CustomImages;
