import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Header, RewardsSection, ViewiFrameOnly } from './PreviewWebsiteComponents';
import ContactAndHours from './ContactAndHours';
import MenuDisplay from './MenuDisplay';
import ProductDisplay from './ProductDisplay';
import ServiceDisplay from './ServiceDisplay';
import { useLocation, useParams } from 'react-router';
import { useAuth } from '../../hooks/useAuth';
import { getPublicBusinessPage } from '../../api/siteApi';
import { setPageSettings } from '../../redux/editPageSlice';
// import { updateSetting } from '../../redux/editPageSlice';

const PreviewWebsite = ({isEditable = false}) => {
  console.log('PreviewWebsite rendered');
  const { companyName } = useParams();
  const location = useLocation();
  const [companyData, setCompanyData] = useState(null);
  const [businessPageIsLive, setBusinessPageIsLive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuItems, setMenuItems] = useState(null);
  const [products, setProducts] = useState(null);
  const [services, setServices] = useState(null);
  const [rewards, setRewards] = useState(null);
  const { idToken } = useAuth();
  const settings = useSelector(state => state.editPage.settings);
  // const defaultSettings = useSelector(state => state.editPage.defaultSettings);
  const userData = useSelector(state => state.auth.user);

  const dispatch = useDispatch();
  
  // useEffect(() => {
  //   if (JSON.stringify(settings) === JSON.stringify(defaultSettings)) {
  //     const storedSettings = localStorage.getItem('websiteSettings');
  //     if (storedSettings) {
  //       const parsedSettings = JSON.parse(storedSettings);
  //       if (JSON.stringify(parsedSettings) === JSON.stringify(defaultSettings)) {
  //         dispatch(updateSetting(parsedSettings));
  //       }
  //     }
  //   }
  // }, [dispatch, defaultSettings]);

  useEffect(() => {
    const fetchBusinessPageData = async () => {
      try {
        let data;
        data = await getPublicBusinessPage(companyName || userData.companyProfile.uniqueUrl);
        console.log({data});
        console.log("isLive", data.businessPageData.testModeTemplateRules?.isLive);
        if (data.businessPageData?.isLive) {
          dispatch(setPageSettings(data.businessPageData.testModeTemplateRules));
          setBusinessPageIsLive(true);
        } else {
          setBusinessPageIsLive(false);
        }
        setCompanyData(data.companyData);
        
        let itemsList = data.companyData.itemsList;
        
        console.log({itemsList});
        if (itemsList?.length > 0) {
          setMenuItems(itemsList.filter(item => item?.itemType === 'menuItem'));
          setProducts(itemsList.filter(item => item?.itemType === 'product'));
          setServices(itemsList.filter(item => item?.itemType === 'service'));
        }
        if (data.companyData.rewardsList?.length > 0) {
          setRewards(data.companyData.rewardsList);
        }
        setLoading(false);
        setError(null); 
      } catch (err) {
        console.error('Error fetching business page data:', err);
        setError('Failed to load business page data');
        setLoading(false);
      }
    };

    fetchBusinessPageData();
  }, [companyName, location.state, dispatch]);
  
  const headerSettings = useMemo(() => ({
    headerBackgroundColor: settings.headerBackgroundColor,
    heroImage: settings.heroImage,
    heroImageBlur: settings.heroImageBlur,
    headerHeight: settings.headerHeight,
  }), [settings.headerBackgroundColor, settings.heroImage, settings.heroImageBlur, settings.headerHeight]);

  const descriptionSettings = useMemo(() => ({
    description: settings.description,
    descriptionFontSize: settings.descriptionFontSize,
    descriptionColor: settings.descriptionColor,
    descriptionFontWeight: settings.descriptionFontWeight,
    descriptionTextShadow: settings.descriptionTextShadow,
    descriptionLetterSpacing: settings.descriptionLetterSpacing,
    descriptionLineHeight: settings.descriptionLineHeight,
    descriptionAlignment: settings.descriptionAlignment,
  }), [settings.description, settings.descriptionFontSize, settings.descriptionColor, settings.descriptionFontWeight, settings.descriptionTextShadow, settings.descriptionLetterSpacing, settings.descriptionLineHeight, settings.descriptionAlignment]);

  const logoSettings = useMemo(() => ({
    logoUrl: settings.logoUrl,
    logoPosition: settings.logoPosition,
    logoAlignment: settings.logoAlignment,
    logoWidth: settings.logoWidth,
    logoHeight: settings.logoHeight,
    logoColumnWidth: settings.logoColumnWidth,
    logoOrder: settings.logoOrder,
    logoTop: settings.logoTop,
    logoLeft: settings.logoLeft,
    logoRight: settings.logoRight,
    logoBottom: settings.logoBottom,
    logoScale: settings.logoScale,
  }), [settings.logoUrl, settings.logoPosition, settings.logoAlignment, settings.logoWidth, settings.logoHeight, settings.logoColumnWidth, settings.logoOrder, settings.logoTop, settings.logoLeft, settings.logoRight, settings.logoBottom, settings.logoScale]);

  const businessNameSettings = useMemo(() => ({
    businessName: settings.businessName,
    businessNameFontSize: settings.businessNameFontSize,
    businessNameColor: settings.businessNameColor,
    businessNameFontWeight: settings.businessNameFontWeight,
    businessNameTextShadow: settings.businessNameTextShadow,
    businessNameLetterSpacing: settings.businessNameLetterSpacing,
    businessNameTextTransform: settings.businessNameTextTransform,
    businessNameAlignment: settings.businessNameAlignment,  
  }), [settings.businessName, settings.businessNameFontSize, settings.businessNameColor, settings.businessNameFontWeight, settings.businessNameTextShadow, settings.businessNameLetterSpacing, settings.businessNameTextTransform, settings.businessNameAlignment]);

  const displaySettings = useMemo(() => ({
    itemsTitleFontSize: settings.itemsTitleFontSize,
    itemsTitleColor: settings.itemsTitleColor,
    rewardsTitleFontSize: settings.rewardsTitleFontSize,
    rewardsTitleColor: settings.rewardsTitleColor,
    rewardsSecondaryColor: settings.rewardsSecondaryColor,
    cardColor: settings.cardColor,
    cardTextColor: settings.cardTextColor,
  }), [settings.itemsTitleFontSize, settings.itemsTitleColor, settings.rewardsTitleFontSize, settings.rewardsTitleColor, settings.rewardsSecondaryColor, settings.cardColor, settings.cardTextColor]);

  const contactAndHoursSettings = useMemo(() => ({
    backgroundColor: settings.contactAndHoursBackgroundColor,
    secondaryColor: settings.contactAndHoursSecondaryColor, 
    contactTextColor: settings.contactTextColor,
  }), [settings.contactAndHoursBackgroundColor, settings.contactAndHoursSecondaryColor, settings.contactTextColor]);
  
  const iFrameSettings = useMemo(() => ({
    iFrameLink: settings.iFrameLink,
    showiFrame: settings.showiFrame,
  }), [settings.iFrameLink, settings.showiFrame]);
  // Assume we have these data from the company profile or some other source
  // const items = [
  //   // Menu Items
  //   { id: 1, name: "Classic Cheeseburger", description: "Juicy beef patty with melted cheese", price: 9.99, type: "menuItem", image: "/avatars/avatar1.jpg" },
  //   { id: 2, name: "Caesar Salad", description: "Crisp romaine lettuce with Caesar dressing", price: 7.99, type: "menuItem", image: "/avatars/avatar2.jpg" },
  //   { id: 3, name: "Margherita Pizza", description: "Fresh mozzarella, tomatoes, and basil", price: 12.99, type: "menuItem", image: "/avatars/avatar3.jpg" },
  //   { id: 4, name: "Grilled Salmon", description: "Served with lemon butter sauce", price: 16.99, type: "menuItem", image: "/avatars/avatar4.jpg" },
  //   { id: 5, name: "Chicken Alfredo", description: "Creamy pasta with grilled chicken", price: 13.99, type: "menuItem", image: "/avatars/avatar5.jpg" },
  //   { id: 6, name: "Veggie Wrap", description: "Assorted vegetables in a whole wheat wrap", price: 8.99, type: "menuItem", image: "/avatars/avatar6.jpg" },
  //   { id: 7, name: "Steak Frites", description: "Grilled steak with crispy fries", price: 18.99, type: "menuItem", image: "/avatars/avatar7.jpg" },
  //   { id: 8, name: "Fish and Chips", description: "Battered cod with tartar sauce", price: 14.99, type: "menuItem", image: "/avatars/avatar1.jpg" },
  //   { id: 9, name: "Mushroom Risotto", description: "Creamy arborio rice with wild mushrooms", price: 15.99, type: "menuItem", image: "/avatars/avatar2.jpg" },
  //   { id: 10, name: "BBQ Ribs", description: "Slow-cooked ribs with tangy BBQ sauce", price: 19.99, type: "menuItem", image: "/avatars/avatar3.jpg" },
    
  //   // Products
  //   { id: 11, name: "Gourmet Coffee Beans", description: "Freshly roasted Arabica beans", price: 14.99, type: "product", image: "/avatars/avatar4.jpg" },
  //   { id: 12, name: "Artisanal Chocolate Bar", description: "72% dark chocolate with sea salt", price: 6.99, type: "product", image: "/avatars/avatar5.jpg" },
  //   { id: 13, name: "Organic Honey", description: "Raw, unfiltered local honey", price: 9.99, type: "product", image: "/avatars/avatar6.jpg" },
  //   { id: 14, name: "Handcrafted Soap Set", description: "Natural ingredients, various scents", price: 12.99, type: "product", image: "/avatars/avatar7.jpg" },
  //   { id: 15, name: "Vintage Wine Opener", description: "Elegant design, easy to use", price: 24.99, type: "product", image: "/avatars/avatar1.jpg" },
  //   { id: 16, name: "Gourmet Spice Set", description: "12 exotic spices for cooking", price: 29.99, type: "product", image: "/avatars/avatar2.jpg" },
  //   { id: 17, name: "Leather Wallet", description: "Handmade, genuine leather", price: 39.99, type: "product", image: "/avatars/avatar3.jpg" },
  //   { id: 18, name: "Scented Candle", description: "Long-lasting, soy wax candle", price: 16.99, type: "product", image: "/avatars/avatar4.jpg" },
  //   { id: 19, name: "Craft Beer Sampler", description: "6 local microbrewery beers", price: 21.99, type: "product", image: "/avatars/avatar5.jpg" },
  //   { id: 20, name: "Portable Bluetooth Speaker", description: "Waterproof, 10-hour battery life", price: 49.99, type: "product" },
    
  //   // Services
  //   { id: 21, name: "Professional Photography", description: "1-hour photo session", price: 150, type: "service", image: "/avatars/avatar6.jpg" },
  //   { id: 22, name: "Home Cleaning", description: "2-hour deep clean service", price: 80, type: "service", image: "/avatars/avatar7.jpg" },
  //   { id: 23, name: "Personal Training", description: "1-hour fitness session", price: 60, type: "service", image: "/avatars/avatar1.jpg" },
  //   { id: 24, name: "Car Detailing", description: "Interior and exterior cleaning", price: 120, type: "service", image: "/avatars/avatar2.jpg" },
  //   { id: 25, name: "Lawn Mowing", description: "Up to 5000 sq ft lawn", price: 40, type: "service", image: "/avatars/avatar3.jpg" },
  //   { id: 26, name: "Yoga Class", description: "1-hour group yoga session", price: 15, type: "service", image: "/avatars/avatar4.jpg" },
  //   { id: 27, name: "Computer Repair", description: "Diagnostics and basic fixes", price: 75, type: "service", image: "/avatars/avatar5.jpg" },
  //   { id: 28, name: "Pet Grooming", description: "Bath, trim, and nail clipping", price: 50, type: "service", image: "/avatars/avatar6.jpg" },
  //   { id: 29, name: "Resume Writing", description: "Professional resume creation", price: 100, type: "service", image: "/avatars/avatar7.jpg" },
  //   { id: 30, name: "Massage Therapy", description: "1-hour relaxation massage", price: 80, type: "service", image: "/avatars/avatar1.jpg" },
  // ];

  // const rewards = [
  //   { id: 1, title: "Free Dessert", description: "Get a free dessert with any main course purchase", points: 50, image: "/avatars/avatar1.jpg" },
  //   { id: 2, title: "10% Off Next Purchase", description: "Enjoy a 10% discount on your next order", points: 100, image: "/avatars/avatar2.jpg" },
  //   { id: 3, title: "Priority Seating", description: "Skip the line with priority seating", points: 150, image: "/avatars/avatar3.jpg" },
  //   { id: 4, title: "Exclusive Tasting Event", description: "Invitation to our monthly tasting event", points: 200, image: "/avatars/avatar4.jpg" },
  //   { id: 5, title: "Personal Chef Experience", description: "Have our chef prepare a meal at your home", points: 500, image: "/avatars/avatar5.jpg" },
  //   { id: 6, title: "Cooking Class", description: "Join our head chef for a hands-on cooking class", points: 300, image: "/avatars/avatar6.jpg" },
  // ];
  // const menuItems = items.filter(item => item.type === 'menuItem');
  // const products = items.filter(item => item.type === 'product');
  // const services = items.filter(item => item.type === 'service');
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!companyData) return <div>No business page data found</div>;
  console.log({businessPageIsLive});
  console.log({isEditable});
  return (
    !loading && (businessPageIsLive || isEditable) ? (
      <Box sx={{ bgcolor: settings.primaryColor, borderRadius: 2, overflow: 'hidden' }} className="isEditableComponent-container">
        {
          !settings.showiFrame && (
            <>
              <Header 
                headerSettings={headerSettings} 
                logoSettings={logoSettings} 
                businessNameSettings={businessNameSettings} 
                descriptionSettings={descriptionSettings} 
              />
              <Box sx={{ maxWidth: '64rem', mx: 'auto', p: 3 }} className="isEditableComponent-content">
                {settings.showItems && menuItems?.length > 0 && <MenuDisplay settings={displaySettings} menuItems={menuItems} />}
                {settings.showItems && products?.length > 0 && <ProductDisplay settings={displaySettings} products={products} />}
                {settings.showItems && services?.length > 0 && <ServiceDisplay settings={displaySettings} services={services} />}
                {settings.showRewards && rewards?.length > 0 && <RewardsSection settings={displaySettings} rewards={rewards} />}
                <ContactAndHours 
                  companyProfile={userData?.companyProfile || companyData}
                  backgroundColor={contactAndHoursSettings.backgroundColor}
                  secondaryColor={contactAndHoursSettings.secondaryColor}
                  contactTextColor={contactAndHoursSettings.contactTextColor}
                />
              </Box>
            </>
          )
        }
          {settings.showiFrame && iFrameSettings.iFrameLink && <ViewiFrameOnly link={iFrameSettings.iFrameLink} />}
      </Box>
    ) : (
      <div>Website is under construction</div>
    )
  );
};

export default React.memo(PreviewWebsite);
