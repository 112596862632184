import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAnalytics, isSupported } from "firebase/analytics";

let firebaseConfig = {};

if (process.env.NODE_ENV === 'development') {
  firebaseConfig = { 
    apiKey: "test-api-key",
    authDomain: "http://localhost:9099",
    projectId: "tecolotlanrewards-52e97",
    storageBucket: "demo-storage-bucket",
    messagingSenderId: "test-messaging-sender-id",
    appId: "test-app-id",
    measurementId: "test-measurement-id"
  };
} else {
  firebaseConfig = { 
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
const isEmulator = process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true';

// Initialize analytics conditionally
let analytics = null;
isSupported().then(yes => {
  if (yes && process.env.NODE_ENV !== 'development') {
    analytics = getAnalytics(app);
  }
}).catch(err => console.log('Analytics not supported:', err));

const getStorageUrl = (path) => {
  if (!path) {
    console.warn('getStorageUrl called with empty path');
    return '';
  }
  
  // Remove any leading 'https://storage.googleapis.com/' from the path
  const cleanPath = path.replace(/^https:\/\/storage\.googleapis\.com\//, '');
  
  if (isEmulator) {
    return `http://localhost:9199/${cleanPath}`;
  } else {
    return `https://storage.googleapis.com/${cleanPath}`;
  }
};

if (process.env.NODE_ENV === 'development' && isEmulator) {
  const host = window.location.hostname;
  console.log(`Connecting to Firebase emulators on ${host}...`);
  connectAuthEmulator(auth, `http://${host}:9099`);
  connectFirestoreEmulator(db, host, 8082);
  connectStorageEmulator(storage, host, 9199);
}

export { app, auth, db, storage, getStorageUrl, googleProvider, analytics };
