import React from 'react';
import { useTranslation } from 'react-i18next';

const EventsManagement = () => {
  const { t } = useTranslation();

  return (
    <div className="events-management">
      <h2>{t('eventsManagement')}</h2>
      {/* Add events management functionality here */}
    </div>
  );
};

export default EventsManagement;