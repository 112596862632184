import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCompanies } from '../../api/adminApi';
import { useAuth } from '../../hooks/useAuth';
import '../../styles/ViewCompanies.css';

const ViewCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { idToken } = useAuth();

  useEffect(() => {
    idToken && fetchCompanies();
  }, [idToken]);

  const fetchCompanies = async () => {
    try {
      const fetchedCompanies = await getCompanies(idToken);
      // console.log(fetchedCompanies);
      setCompanies(fetchedCompanies.companies);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch companies');
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="view-companies">
      <h1>Companies</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>Created At</th>
            <th>Company Items</th>
            <th>Company Rewards</th>
          </tr>
        </thead>
        <tbody>
          {companies?.map((company) => (
            <tr key={company.uid}>
              <td>{company.companyName}</td>
              <td>{company.email}</td>
              <td>{company.location?.country || company.country || company.address?.country || 'N/A'}</td>
              <td>{new Date(company?.createdAt)?.toLocaleDateString() || 'N/A'}</td>
              <td>
                <Link to={`/admin/company/${company.uid}/items`}>
                  View Company Items
                </Link>
              </td>
              <td>
                <Link to={`/admin/company/${company.uid}/rewards`}>
                  View Company Rewards
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewCompanies;