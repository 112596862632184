import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCompanyRewards } from '../../api/adminApi';
import '../../styles/ViewCompanyDetails.css';
import { useAuth } from '../../hooks/useAuth';
const ViewCompanyRewards = () => {
  const { companyId } = useParams();
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { idToken } = useAuth();
  
  useEffect(() => {
    fetchRewards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const fetchRewards = async () => {
    try {
      const items = await getCompanyRewards(companyId, idToken);
      setRewards(items);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch rewards');
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="view-company-details">
      <h1>Company Rewards</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Points Cost</th>
            <th>Available</th>
          </tr>
        </thead>
        <tbody>
          {rewards.map((reward) => (
            <tr key={reward.id}>
              <td>{reward.name}</td>
              <td>{reward.description}</td>
              <td>{reward.pointsCost}</td>
              <td>{reward.isAvailable ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewCompanyRewards;