import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Template1 from '../companyBusinessPage/templates/template1/Template1';
import Sidebar from '../companyBusinessPage/Sidebar';
import { db } from '../../firebaseConfig';
import { doc, getDoc, setDoc, onSnapshot, serverTimestamp, collection } from 'firebase/firestore';
import { popularFonts } from '../../assets/files/fonts';
import { setError } from '../../redux/errorSlice';
import ErrorDisplay from '../common/ErrorDisplay';
import { saveBusinessPage } from '../../api/businessPagesApi';
import { setSelectedElement, setSelectedSection } from '../../redux/sectionSlice';
import { useAuth } from '../../hooks/useAuth';
import { generateUniqueUrl } from '../../utils/url';

const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

const ContentArea = styled.div`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
`;

const SaveButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const UndoRedoContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
`;

const UndoRedoButton = styled.button`
  padding: 10px 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #5a6268;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const CustomizeBusinessPage = () => {
  const { t } = useTranslation();
  const [templateRules, setTemplateRules] = useState({
    isLive: false,
    sections: []
  });
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fontSearch, setFontSearch] = useState('');
  const [showFontList, setShowFontList] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const businessId = user.companyId;
  const companyName = user.companyProfile.companyName;
  const description = user.companyProfile.description;

  const fontSelectRef = useRef(null);
  const {selectedElement, selectedSection} = useSelector((state) => state.section);
  const [businessPageId, setBusinessPageId] = useState(null);
  const [history, setHistory] = useState([]);
  const [future, setFuture] = useState([]);
  const { idToken } = useAuth();
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    let unsubscribe = () => {};

    const fetchMenuItems = async (menuId) => {
      try {
        const menuDoc = await getDoc(doc(db, 'menus', menuId));
        if (menuDoc.exists()) {
          return menuDoc.data().items;
        }
        return null;
      } catch (error) {
        console.error('Error fetching menu items:', error);
        return null;
      }
    };

    const checkUserPermission = async () => {
      try {
        const companyRef = doc(db, 'companies', businessId);
        const companyDoc = await getDoc(companyRef);
        
        if (!companyDoc.exists()) {
          throw new Error('Company document not found');
        }

        const companyData = companyDoc.data();
        if (!companyData.admins || !companyData.admins.includes(user.uid)) {
          throw new Error('User does not have permission to edit this company profile');
        }
        setCompanyData(companyData);
        // Fetch menu items
        let fetchedMenuItems = [];
        if (user.companyProfile.menu) {
          fetchedMenuItems = await fetchMenuItems(user.companyProfile.menu);
        }
        
        if (!fetchedMenuItems || fetchedMenuItems.length === 0) {
          // Create dummy data if no menu items exist
          fetchedMenuItems = [
            {
              uid: 'dummy1',
              companyId: businessId,
              name: 'Sample Item 1',
              price: 9.99,
              description: 'This is a sample menu item',
              image: 'https://example.com/sample-image.jpg',
              category: 'Main Course',
              isAvailable: true,
              isFeatured: false,
              hasDiscount: false,
              discountPercentage: 0,
              discountExpiration: null,
            },
            {
              uid: 'dummy2',
              companyId: businessId,
              name: 'Sample Item 2',
              price: 5.99,
              description: 'Another sample menu item',
              image: 'https://example.com/sample-image-2.jpg',
              category: 'Appetizer',
              isAvailable: true,
              isFeatured: true,
              hasDiscount: true,
              discountPercentage: 10,
              discountExpiration: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7 days from now
            },
          ];
        }
        setMenuItems(fetchedMenuItems);

        // Find or create the business page
        let businessPageRef;
        if (companyData.businessPageId) {
          businessPageRef = doc(db, 'businessPages', companyData.businessPageId);
          setBusinessPageId(companyData.businessPageId);
        } else {
          // Create a new business page
          businessPageRef = doc(collection(db, 'businessPages'));
          setBusinessPageId(businessPageRef.id);

          // Update company document with the new businessPageId
          await setDoc(companyRef, { businessPageId: businessPageRef.id }, { merge: true });

          // Create the new business page document
          await setDoc(businessPageRef, {
            companyId: businessId,
            templateRules: templateRules,
            lastUpdate: {
              timestamp: serverTimestamp(),
              userId: user.uid
            },
            uniqueUrl: generateUniqueUrl(companyName)
          });
        }

        unsubscribe = onSnapshot(businessPageRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            setTemplateRules(prevRules => ({
              ...prevRules,
              ...data.templateRules,
            }));
          }
          setLoading(false);
        });

      } catch (error) {
        console.error('Error checking user permission:', error);
        dispatch(setError({ message: error.message, type: 'error' }));
        navigate('/dashboard');
      }
    };

    checkUserPermission();

    const handleClickOutside = (event) => {
      if (fontSelectRef.current && !fontSelectRef.current.contains(event.target)) {
        setShowFontList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      unsubscribe();
    };
  }, [businessId, user.uid, user.companyProfile.menu, dispatch, navigate]);

  const addToHistory = (oldState) => {
    setHistory(prevHistory => [...prevHistory, oldState]);
    setFuture([]); // Clear redo stack when a new action is performed
  };

  const handleUpdateSettings = (field, value, sectionId, remove = false, elementType = null) => {
    addToHistory({ ...templateRules });
    console.log({field});
    const updatedSections = templateRules.sections.map(section => {
      if (section.id === sectionId) {
        if (remove) {
          const { [field]: _, ...rest } = section;
          // If there's a selected element, remove it
          if (selectedElement && selectedElement.startsWith(sectionId)) {
            // If the selected element is in the section, remove it from the section
            const updatedSection = { ...section, [field]: null };
            console.log({updatedSection});
            dispatch(setSelectedElement(null));
            return updatedSection;
          }
          return rest;
        }
        return { ...section, [field]: value };
      }
      return section;
    });
    setTemplateRules(prevRules => ({
      ...prevRules,
      sections: updatedSections
    }));
    setHasUnsavedChanges(true);
  };

  const fontSizeOptions = Array.from({ length: 100 }, (_, i) => i + 1);

  const filteredFonts = Array.from(new Set(popularFonts.filter(f => 
    f.toLowerCase().includes(fontSearch.toLowerCase())
  )));

  const updateSectionColumns = (sectionId, numberOfColumns) => {
    addToHistory({ ...templateRules });
    const updatedSections = templateRules.sections.map(section => {
      if (section.id === sectionId) {
        const newColumns = Array(numberOfColumns).fill().map((_, index) => {
          let width;
          switch(numberOfColumns) {
            case 2:
              width = '50%';
              break;
            case 3:
              width = '33.33%';
              break;
            case 4:
              width = '25%';
              break;
            default:
              width = '100%';
          }
          return {
            width: width,
            componentType: index === 0 ? section.columns[0].componentType : 'Empty'
          };
        });
        return { ...section, columns: newColumns };
      }
      return section;
    });

    setTemplateRules(prevRules => ({
      ...prevRules,
      sections: updatedSections
    }));
    setHasUnsavedChanges(true);
  };

  const handleSave = async () => {
    if (!businessPageId) return;

    try {
      await saveBusinessPage(businessPageId, templateRules, user.uid, businessId, idToken);
      setHasUnsavedChanges(false);
      dispatch(setError({ message: t('changesSaved'), type: 'success' }));
    } catch (error) {
      console.error('Error saving changes:', error);
      dispatch(setError({ message: t('errorSavingChanges'), type: 'error' }));
    }
  };

  const removeSection = (sectionId) => {
    addToHistory({ ...templateRules });
    setTemplateRules(prevRules => ({
      ...prevRules,
      sections: prevRules.sections.filter(section => section.id !== sectionId)
    }));
    setHasUnsavedChanges(true);
    dispatch(setSelectedSection(null));
  };

  const handleUndo = () => {
    if (history.length > 0) {
      const previousState = history[history.length - 1];
      setFuture(prevFuture => [templateRules, ...prevFuture]);
      setTemplateRules(previousState);
      setHistory(prevHistory => prevHistory.slice(0, -1));
      setHasUnsavedChanges(true);
    }
  };

  const handleRedo = () => {
    if (future.length > 0) {
      const nextState = future[0];
      setHistory(prevHistory => [...prevHistory, templateRules]);
      setTemplateRules(nextState);
      setFuture(prevFuture => prevFuture.slice(1));
      setHasUnsavedChanges(true);
    }
  };

  const addNewSection = useCallback(() => {
    const newSection = {
      id: `section-${Date.now()}`, // Generate a unique ID
      columns: [{ width: '100%', componentType: 'Empty' }],
      title: 'New Section',
      type: 'Empty'
    };

    setTemplateRules(prevRules => ({
      ...prevRules,
      sections: [...prevRules.sections, newSection]
    }));

    setHasUnsavedChanges(true);
    // dispatch(setSelectedSection(newSection.id, newSection));
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const updateSectionType = (sectionId, type) => {
    console.log({sectionId});
    console.log({type});
    const updatedSections = templateRules.sections.map(section =>
      section.id === sectionId ? { ...section, type, columns: [{ width: '100%', componentType: type }] } : section
    );
    setTemplateRules(prevRules => ({
      ...prevRules,
      sections: updatedSections
    }));
    setHasUnsavedChanges(true);
  };

  return (
    <PageContainer>
      <ErrorDisplay />
      <Sidebar
        companyData={companyData}
        // * Edit Sections
        templateRules={templateRules}
        handleUpdateSettings={handleUpdateSettings}
        sections={templateRules.sections}
        selectedSection={selectedSection}
        selectedElement={selectedElement}
        updateSectionType={updateSectionType}
        updateSectionColumns={updateSectionColumns}
        removeSection={removeSection}
        addNewSection={addNewSection}
        // * Edit Specific Section
        fontSearch={fontSearch}
        showFontList={showFontList}
        fontSizeOptions={fontSizeOptions}
        filteredFonts={filteredFonts}
        setFontSearch={setFontSearch}
        setShowFontList={setShowFontList}
        fontSelectRef={fontSelectRef}
      />
      <ContentArea>
        <Template1
          companyMainData={companyData}
          companyData={{
            templateRules: templateRules,
            companyName: companyName,
            description: description,
            menuItems: menuItems
          }}
          isEditable={true}
        />
      </ContentArea>
      {hasUnsavedChanges && (
        <SaveButton onClick={handleSave}>{t('saveChanges')}</SaveButton>
      )}
      <UndoRedoContainer>
        <UndoRedoButton onClick={handleUndo} disabled={history.length === 0}>
          {t('undo')}
        </UndoRedoButton>
        <UndoRedoButton onClick={handleRedo} disabled={future.length === 0}>
          {t('redo')}
        </UndoRedoButton>
      </UndoRedoContainer>
    </PageContainer>
  );
};

export default CustomizeBusinessPage;
