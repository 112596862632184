import { createSlice } from '@reduxjs/toolkit';

// make a default settings object
const defaultSettings = {
    isDefault: true,
    businessName: '',
    slug: '',
    description: '',
    primaryColor: '#4F46E5',
    showiFrame: false,
    iFrameLink: '',
    // secondaryColor: '#818CF8',
    cardColor: '#FFFFFF',
    cardTextColor: '#000000',
    logoUrl: null,
    heroImage: null,
    layout: 'modern',
    showRewards: true,
    showItems: true,
    featuredItems: [],
    headerBackgroundColor: '#4F46E5',
    heroImageBlur: 0,
    
    // Logo settings
    logoPosition: 'center', // This controls the position within its container
    logoOrder: 'left', // This will control whether the logo appears on the left or right side of the header
    logoAlignment: 'center',
    logoWidth: '200',
    logoHeight: '200',
    logoTop: 'auto',
    logoLeft: 'auto',
    logoRight: 'auto',
    logoBottom: 'auto',
    
    // Business Name settings
    businessNameFontSize: '32',
    businessNameColor: '#FFFFFF',
    businessNameFontWeight: 'bold',
    businessNameTextShadow: 'none',
    businessNameLetterSpacing: 'normal',
    businessNameTextTransform: 'none',
    businessNameAlignment: 'left',
    
    // Description settings
    descriptionFontSize: '18',
    descriptionColor: '#FFFFFF',
    descriptionFontWeight: 'normal',
    descriptionTextShadow: 'none',
    descriptionLetterSpacing: 'normal',
    descriptionLineHeight: '1.5',
    descriptionAlignment: 'left',
    
    // Products section settings
    itemsTitleFontSize: '24',
    itemsTitleColor: '#000000',
    
    // Rewards section settings
    rewardsTitleFontSize: '24',
    rewardsTitleColor: '#000000',
    rewardsSecondaryColor: '#818CF8',
    logoColumnWidth: 3, // This means the logo takes up 3 columns out of 12
    headerHeight: '400', // Default height in pixels
    logoScale: 1, // Default scale is 1 (100%)
  }

const initialState = {
    defaultSettings: defaultSettings,
    settings: defaultSettings,
    initialSettings: defaultSettings,
    history: [],
    currentIndex: -1,
};

const editPageSlice = createSlice({
  name: 'editPage',
  initialState,
  reducers: {
    updateSetting: (state, action) => {
      const { key, value } = action.payload;
      // Remove future history if we're not at the latest change
      if (state.currentIndex < state.history.length) {
        state.history = state.history.slice(0, state.currentIndex + 1);
      }
      // Update the setting
      state.settings[key] = value;
      // Add current state to history
      state.history.push(JSON.parse(JSON.stringify(state.settings)));
      state.currentIndex = state.history.length - 1;
      localStorage.setItem('websiteSettings', JSON.stringify(state.settings));
    },
    undo: (state) => {
      if (state.currentIndex > 0) {
        state.currentIndex--;
        state.settings = JSON.parse(JSON.stringify(state.history[state.currentIndex]));
        localStorage.setItem('websiteSettings', JSON.stringify(state.settings));
      }
    },
    redo: (state) => {
      if (state.currentIndex < state.history.length - 1) {
        state.currentIndex++;
        state.settings = JSON.parse(JSON.stringify(state.history[state.currentIndex]));
        localStorage.setItem('websiteSettings', JSON.stringify(state.settings));
      }
    },
    resetSettings: (state) => {
      state.settings = initialState.settings;
      state.history = [JSON.parse(JSON.stringify(initialState.settings))];
      state.currentIndex = 0;
      localStorage.setItem('websiteSettings', JSON.stringify(initialState.settings));
    },
    setPageSettings: (state, action) => {
      state.settings = action.payload;
      state.initialSettings = action.payload;
      state.history = [JSON.parse(JSON.stringify(action.payload))];
      state.currentIndex = 0;
      localStorage.setItem('websiteSettings', JSON.stringify(state.settings));
    },
    setInitialSettings: (state, action) => {
      state.initialSettings = action.payload;
    }
  }
});

export const { updateSetting, undo, redo, resetSettings, setPageSettings, setInitialSettings } = editPageSlice.actions;

export default editPageSlice.reducer;
