import React, { useState, useEffect } from 'react';
import { TextField, Button, DialogActions, DialogContent, DialogTitle, Input, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const RewardForm = ({ reward, onChange, onImageChange, onSubmit, onClose, isNew }) => {
  const { t } = useTranslation();
  const [imagePreview, setImagePreview] = useState(reward.image);

  useEffect(() => {
    setImagePreview(reward.image);
  }, [reward.image]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        onImageChange(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <DialogTitle>{isNew ? t('createNewReward') : t('editReward')}</DialogTitle>
      <DialogContent>
        <TextField
          label={t('name')}
          value={reward.name}
          onChange={(e) => onChange('name', e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t('description')}
          value={reward.description}
          onChange={(e) => onChange('description', e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />
        <TextField
          label={t('pointsCost')}
          type="number"
          value={reward.pointsCost}
          onChange={(e) => onChange('pointsCost', e.target.value)}
          fullWidth
          margin="normal"
        />
        <Input
          type="file"
          onChange={handleImageChange}
          accept="image/*"
          style={{ display: 'none' }}
          id="reward-image-upload"
        />
        <label htmlFor="reward-image-upload">
          <Button variant="contained" component="span">
            {t('uploadImage')}
          </Button>
        </label>
        {imagePreview && (
          <img src={imagePreview} alt="Reward preview" style={{ maxWidth: '100%', marginTop: '10px' }} />
        )}
        <TextField
          select
          label={t('isAvailable')}
          value={reward.isAvailable}
          onChange={(e) => onChange('isAvailable', e.target.value)}
          fullWidth
          margin="normal"
        >
          <MenuItem value={true}>{t('yes')}</MenuItem>
          <MenuItem value={false}>{t('no')}</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button type="submit" color="primary">{isNew ? t('create') : t('save')}</Button>
      </DialogActions>
    </form>
  );
};

export default RewardForm;