import React from 'react';
import { createCustomerPortalSession } from '../../api/stripeApi';
import { Button } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';

const StripeCustomerPortal = ({ user }) => {
  const { idToken } = useAuth();

  const handlePortalAccess = async () => {
    try {
      const { url } = await createCustomerPortalSession(idToken, user);
      window.location.href = url;
    } catch (error) {
      console.error('Error accessing customer portal:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <Button 
      variant="contained" 
      color="primary" 
      fullWidth 
      onClick={handlePortalAccess}
    >
      Manage your billing information
    </Button>
  );
};

export default StripeCustomerPortal;
