import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const CompanyActionModal = ({ isOpen, onClose, company, isOptedIn, onVisitSite, onToggleRewards, userData }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;
  const userIsOptedIn = userData?.optedInCompanies?.includes(company?.uid);
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CompanyHeader>
          <CompanyLogo src={company.logo} alt={company.companyName} />
          <CompanyName>{company.companyName}</CompanyName>
        </CompanyHeader>
        <ButtonContainer>
          <ActionButton onClick={onVisitSite}>
            {t('visitSite')}
          </ActionButton>
          <ActionButton onClick={() => onToggleRewards(userIsOptedIn)} disabled={!userData?.uid}>
            {userIsOptedIn ? t('leaveRewards') : t('joinRewards')}
          </ActionButton>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 320px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CompanyHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CompanyLogo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 12px;
`;

const CompanyName = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #333;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ActionButton = styled.button`
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #3498db;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2980b9;
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export default CompanyActionModal; 