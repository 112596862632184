import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../redux/authSlice';
import { updateCompanyProfile } from '../../api/companyApi';
import { Avatar, Button, FormControl, InputLabel, OutlinedInput, Typography, Box, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { isValidAddress, isValidPhoneNumber, isValidDescription, sanitizeInput, isValidZipCode } from '../../utils/validation';
import { useAuth } from '../../hooks/useAuth'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import '../../App.css';
import { googleMapsConfig } from '../../utils/googleMapsConfig';

const Input = styled('input')({
  display: 'none',
});

const mapContainerStyle = {
  width: '100%',
  height: '300px',
  marginBottom: '20px'
};

const ProfileSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idToken } = useAuth();
  const company = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    avatar: '',
    companyName: '',
    address: {
      street: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
    },
    category: '',
    employeeCount: '',
    phone: '',
    website: '',
    description: '',
    latitude: company.companyProfile?.latitude || null,
    longitude: company.companyProfile?.longitude || null,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);

  const { isLoaded } = useJsApiLoader(googleMapsConfig);

  const [mapCenter, setMapCenter] = useState({ lat: 20.0176, lng: -104.0719 });

  useEffect(() => {
    if (company) {
      const newFormData = {
        ...formData,
        avatar: company.companyProfile?.avatar || '',
        companyName: company.companyProfile?.companyName || company.displayName || '',
        address: {
          street: company?.companyProfile?.address?.street || '',
          city: company?.companyProfile?.address?.city || '',
          state: company?.companyProfile?.address?.state || '',
          country: company?.companyProfile?.address?.country || '',
          zipCode: company?.companyProfile?.address?.zipCode || '',
        },
        category: company?.companyProfile?.category || '',
        employeeCount: company?.companyProfile?.employeeCount || '',
        phone: company?.companyProfile?.phone || '',
        website: company?.companyProfile?.website || '',
        description: company?.companyProfile?.description || '',
        latitude: company?.companyProfile?.latitude || null,
        longitude: company?.companyProfile?.longitude || null,
      };
      setFormData(newFormData);

      if (newFormData.latitude && newFormData.longitude) {
        setMapCenter({ lat: parseFloat(newFormData.latitude), lng: parseFloat(newFormData.longitude) });
      }
    }
  }, [company]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormData(prevState => ({
        ...prevState,
        address: {
          ...prevState.address,
          [addressField]: sanitizeInput(value)
        }
      }));
      validateField(name, value);
    } else {
      setFormData({ ...formData, [name]: sanitizeInput(value) });
      validateField(name, value);
    }
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };
    switch (name) {
      case 'address.street':
        newErrors['address.street'] = isValidAddress(value) ? '' : t('invalidStreet');
        break;
      case 'address.zipCode':
        newErrors['address.zipCode'] = isValidZipCode(value) ? '' : t('invalidZipCode');
        break;
      case 'phone':
        newErrors.phone = isValidPhoneNumber(value) ? '' : t('invalidPhone');
        break;
      case 'description':
        newErrors.description = isValidDescription(value) ? '' : t('invalidDescription');
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatarFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, avatar: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMapClick = useCallback((e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    setFormData(prevState => ({
      ...prevState,
      latitude: newLat,
      longitude: newLng
    }));
    setMapCenter({ lat: newLat, lng: newLng });
  }, []);

  const mapOptions = useMemo(() => ({
    center: mapCenter,
    zoom: 10
  }), [mapCenter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    // Validate all fields before submission
    const newErrors = {
      // ... (keep existing validations)
    };
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const updatedCompanyResponse = await updateCompanyProfile(company.companyId, formData, avatarFile, idToken);
      console.log('Updated company:', updatedCompanyResponse.data);
      const updatedUser = { ...company, companyProfile: updatedCompanyResponse.data };
      console.log('Updated company:', updatedUser);
      dispatch(login(updatedUser));
      setIsSubmitted(true);
      setAvatarFile(null);
    } catch (err) {
      setError(t('errorUpdatingProfile'));
      console.error('Error updating profile:', err);
    }
  };

  const categories = [
    'Retail', 'Restaurant', 'Healthcare', 'Technology', 'Education',
    'Finance', 'Manufacturing', 'Construction', 'Entertainment', 'Automotive',
    'RealEstate', 'Travel', 'Fitness', 'Beauty', 'HomeServices',
    'ProfessionalServices', 'NonProfit', 'Agriculture', 'Energy', 'Telecommunications'
  ];

  const employeeCountOptions = ['0', '1-5', '6-10', '11-20', '21-50', '50+'];

  return (
    <Box className="profile-settings-container" component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('companyProfileSettings')}
      </Typography>
      {!isSubmitted && (
        <Typography variant="body1" color="text.secondary" gutterBottom marginBottom={3}>
          {t('profileSettingsInfo')}
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, maxWidth: '70%' }}>
        <Box className="avatar-upload-section" sx={{ flex: { xs: '1', md: '0 0 33%' } }}>
          <Avatar
            src={formData.avatar || '/default-avatar.png'}
            sx={{ width: 120, height: 120, mb: 2 }}
          />
          <label htmlFor="avatar-upload">
            <Input
              accept="image/*"
              id="avatar-upload"
              type="file"
              onChange={handleAvatarChange}
            />
            <Button variant="contained" component="span">
              {t('uploadLogo')}
            </Button>
          </label>
        </Box>
        <Box sx={{ flex: { xs: '1', md: '0 0 66%' }, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="companyName">{t('companyName')}</InputLabel>
            <OutlinedInput
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              label={t('companyName')}
            />
          </FormControl>
          <FormControl fullWidth required error={!!errors['address.street']}>
            <InputLabel htmlFor="address.street">{t('street')}</InputLabel>
            <OutlinedInput
              id="address.street"
              name="address.street"
              value={formData.address.street}
              onChange={handleChange}
              label={t('street')}
            />
            {errors['address.street'] && <Typography color="error" variant="caption">{errors['address.street']}</Typography>}
          </FormControl>
          <FormControl fullWidth required>
            <InputLabel htmlFor="address.city">{t('city')}</InputLabel>
            <OutlinedInput
              id="address.city"
              name="address.city"
              value={formData.address.city}
              onChange={handleChange}
              label={t('city')}
            />
          </FormControl>
          <FormControl fullWidth required>
            <InputLabel htmlFor="address.state">{t('state')}</InputLabel>
            <OutlinedInput
              id="address.state"
              name="address.state"
              value={formData.address.state}
              onChange={handleChange}
              label={t('state')}
            />
          </FormControl>
          <FormControl fullWidth required>
            <InputLabel htmlFor="address.country">{t('country')}</InputLabel>
            <OutlinedInput
              id="address.country"
              name="address.country"
              value={formData.address.country}
              onChange={handleChange}
              label={t('country')}
            />
          </FormControl>
          <FormControl fullWidth required error={!!errors['address.zipCode']}>
            <InputLabel htmlFor="address.zipCode">{t('zipCode')}</InputLabel>
            <OutlinedInput
              id="address.zipCode"
              name="address.zipCode"
              value={formData.address.zipCode}
              onChange={handleChange}
              label={t('zipCode')}
            />
            {errors['address.zipCode'] && <Typography color="error" variant="caption">{errors['address.zipCode']}</Typography>}
          </FormControl>
          <FormControl fullWidth required>
            <InputLabel id="category-label">{t('category')}</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              label={t('category')}
            >
              <MenuItem value="">
                <em>{t('selectCategory')}</em>
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {t(category.charAt(0).toLowerCase() + category.slice(1))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth required>
            <InputLabel id="employeeCount-label">{t('employeeCount')}</InputLabel>
            <Select
              labelId="employeeCount-label"
              id="employeeCount"
              name="employeeCount"
              value={formData.employeeCount}
              onChange={handleChange}
              label={t('employeeCount')}
            >
              <MenuItem value="">
                <em>{t('selectEmployeeCount')}</em>
              </MenuItem>
              {employeeCountOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth required error={!!errors.phone}>
            <InputLabel htmlFor="phone">{t('phone')}</InputLabel>
            <OutlinedInput
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              label={t('phone')}
            />
            {errors.phone && <Typography color="error" variant="caption">{errors.phone}</Typography>}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="website">{t('website')}</InputLabel>
            <OutlinedInput
              id="website"
              name="website"
              value={formData.website}
              onChange={handleChange}
              label={t('website')}
            />
          </FormControl>
          <FormControl fullWidth required error={!!errors.description}>
            <InputLabel htmlFor="description">{t('description')}</InputLabel>
            <OutlinedInput
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              label={t('description')}
              multiline
              rows={4}
            />
            {errors.description && <Typography color="error" variant="caption">{errors.description}</Typography>}
          </FormControl>
           {isLoaded ? (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                {t('selectLocation')}
              </Typography>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                options={mapOptions}
                onClick={handleMapClick}
              >
                {formData.latitude && formData.longitude && (
                  <Marker
                    position={{ lat: parseFloat(formData.latitude), lng: parseFloat(formData.longitude) }}
                  />
                )}
              </GoogleMap>
              {errors.location && <Typography color="error" variant="caption">{errors.location}</Typography>}
            </Box>
          ) : <Typography>{t('loadingMap')}</Typography>}
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {t('saveChanges')}
        </Button>
      </Box>
      {isSubmitted && (
        <Typography variant="body1" color="success.main" sx={{ mt: 2 }}>
          {t('profileUpdatedSuccess')}
        </Typography>
      )}
      {error && (
        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default ProfileSettings;
