import React, { useState, useEffect } from 'react';
import { Typography, Box, Card, CardContent, CircularProgress, TextField, Select, MenuItem, FormControl, InputLabel, Chip, Button, Modal, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { getRewardsListFromSingleCompany } from '../../api/rewardsApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AdSenseAd from '../../services/AdSenseAd';

const CompanyRewards = () => {
  const { t } = useTranslation();
  const { idToken } = useAuth();
  const { companyId } = useParams();
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');
  const [nameFilter, setNameFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const user = useSelector(state => state.auth.user);
  const [selectedReward, setSelectedReward] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pinCode, setPinCode] = useState('');
  const [showQR, setShowQR] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  useEffect(() => {
    const fetchRewards = async () => {
      if (idToken && companyId) {
        try {
          const fetchedRewards = await getRewardsListFromSingleCompany(companyId, idToken);
          
          // // Dummy rewards data
          // const dummyRewards = [
          //   {
          //     "uid": "reward2",
          //     "createdAt": { "_seconds": 1675296000, "_nanoseconds": 0 },
          //     "image": "/avatars/avatar4.jpg",
          //     "isAvailable": true,
          //     "companyId": companyId,
          //     "redemptionCount": 5,
          //     "name": "10% Discount",
          //     "description": "Get 10% off on your next purchase",
          //     "pointsCost": 100,
          //     "updatedAt": { "_seconds": 1675296000, "_nanoseconds": 0 }
          //   },
          //   {
          //     "uid": "reward3",
          //     "createdAt": { "_seconds": 1675382400, "_nanoseconds": 0 },
          //     "image": "/avatars/avatar4.jpg",
          //     "isAvailable": true,
          //     "companyId": companyId,
          //     "redemptionCount": 2,
          //     "name": "Free Dessert",
          //     "description": "Enjoy a complimentary dessert with your meal",
          //     "pointsCost": 75,
          //     "updatedAt": { "_seconds": 1675382400, "_nanoseconds": 0 }
          //   },
          //   {
          //     "uid": "reward4",
          //     "createdAt": { "_seconds": 1675468800, "_nanoseconds": 0 },
          //     "image": "/avatars/avatar4.jpg",
          //     "isAvailable": false,
          //     "companyId": companyId,
          //     "redemptionCount": 10,
          //     "name": "VIP Experience",
          //     "description": "Exclusive VIP treatment for your next visit",
          //     "pointsCost": 500,
          //     "updatedAt": { "_seconds": 1675468800, "_nanoseconds": 0 }
          //   },
          //   {
          //     "uid": "reward5",
          //     "createdAt": { "_seconds": 1675555200, "_nanoseconds": 0 },
          //     "image": "/avatars/avatar4.jpg",
          //     "isAvailable": true,
          //     "companyId": companyId,
          //     "redemptionCount": 1,
          //     "name": "Buy One Get One Free",
          //     "description": "Purchase any item and get another one for free",
          //     "pointsCost": 200,
          //     "updatedAt": { "_seconds": 1675555200, "_nanoseconds": 0 }
          //   }
          // ];
          
          console.log({fetchedRewards});
          // Combine fetched rewards with dummy rewards
          // const combinedRewards = [...fetchedRewards, ...dummyRewards];
          setRewards(fetchedRewards);
        } catch (error) {
          console.error('Error fetching rewards:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    idToken && companyId && fetchRewards();
  }, [idToken, companyId]);

  const filteredAndSortedRewards = rewards
    .filter(reward => 
      reward.name.toLowerCase().includes(nameFilter.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.pointsCost - b.pointsCost;
      } else {
        return b.pointsCost - a.pointsCost;
      }
    });

  const handleRedeem = (reward) => {
    setSelectedReward(reward);
    setIsModalOpen(true);
    setPinCode('');
    setShowQR(false);
  };

  const handleConfirmRedeem = () => {
    // Here you would typically make an API call to redeem the reward
    // For now, we'll just show the QR code
    setShowQR(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedReward(null);
    setPinCode('');
    setShowQR(false);
  };

  const handleOpenDetailsModal = (reward) => {
    setSelectedReward(reward);
    setDetailsModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setDetailsModalOpen(false);
    setSelectedReward(null);
  };

  // console.log({rewards})

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ textAlign: 'center', px: 2, pt: 3, color: '#333' }} className="company-rewards-container">
      <Typography variant="h4" gutterBottom>
        {t('companyRewardsTitle')}
      </Typography>
      {/* <AdSenseAd /> */}

      {!rewards.length && !loading ? (
        <Typography variant="h6" gutterBottom>
          {t('noRewardsAvailable')}
        </Typography>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, flexDirection: 'column', alignItems: 'center', }} className="company-rewards-filters">
            <Button sx={{ marginBottom: 2, color: '#333', borderColor: '#333' }} variant="outlined" color={showFilters ? 'secondary' : 'primary'} onClick={() => setShowFilters(!showFilters)}>
              {t('filters')}
            </Button>
            {
              showFilters && (
                <>
                  <FormControl sx={{ marginTop: 2, borderRadius: 2, backgroundColor: 'white' }}>
                    <InputLabel id="filter-by-name-label"></InputLabel>
                    <TextField
                      labelId="filter-by-name-label"
                      value={nameFilter}
                      label={t('filterByName')}
                      onChange={(e) => setNameFilter(e.target.value)}
                      size="small"
                      color="primary"
                    />
                    </FormControl>
                </>
              )
            }
            <FormControl size="small" sx={{ marginTop: 2, borderRadius: 2, backgroundColor: '#f0f3f6' }}>
              <InputLabel id="sort-order-label" sx={{ color: '#333' }}>{t('sortOrder')}</InputLabel>
              <Select
                labelId="sort-order-label"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                label={t('sortOrder')}
                sx={{ color: '#333', borderColor: '#333' }}   
              >
                <MenuItem value="asc">{t('pointsCostLowToHigh')}</MenuItem>
                <MenuItem value="desc">{t('pointsCostHighToLow')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 3,
            justifyContent: 'center',
            alignItems: 'stretch',
          }}>
            {filteredAndSortedRewards.map((reward) => {
              const hasEnoughPoints = user?.points?.find(p => p.companyId === reward?.companyId)?.pointsBalance >= reward?.pointsCost;

              return (
                <Card key={reward.uid} sx={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#f0f3f6' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={reward.image} alt={reward.name} width="35" height="35" />
                  </Box>
                  <Typography variant="subtitle1" component="div">
                    {reward.name}
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => handleOpenDetailsModal(reward)}
                    endIcon={<ArrowDownwardIcon />}
                  >
                    {t('moreDetails')}
                  </Button>
                  <Chip label={`${reward.pointsCost} ${t('pointsTitle')}`} size="small" color="primary" />
                  <Button 
                    variant="contained" 
                    size="small" 
                    sx={{ 
                      marginTop: 1,
                      backgroundColor: hasEnoughPoints ? '#3498db' : '#3A3B3C', // Blue if enough points, dark grey if not
                      '&:hover': { 
                        backgroundColor: hasEnoughPoints ? '#1b5780' : '#3A3B3C', // Darker blue on hover if enough points
                      },
                      color: 'white',
                    }}
                    disabled={!hasEnoughPoints}
                    onClick={() => handleRedeem(reward)}
                  >
                    {hasEnoughPoints ? t('redeem') : t('notEnoughPoints')}
                  </Button>
                  </CardContent>
                </Card>
              )
            })}
          </Box>
        </>
      )}
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>{t('redeemReward')}</DialogTitle>
        <DialogContent>
          {showQR ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" gutterBottom>
                {t('showQRToRedeem')}
              </Typography>
              <QRCodeSVG value={JSON.stringify({
                  userId: user.uid,
                  userPinCode: pinCode,
                  reward: {
                    uid: selectedReward.id,
                    name: selectedReward.name,
                    description: selectedReward.description,
                    pointsCost: selectedReward.pointsCost,
                    isAvailable: selectedReward.isAvailable,
                    image: selectedReward.image
                  }
                })} size={200} />
            </Box>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                {t('confirmRedeem', { name: selectedReward?.name, pointsCost: selectedReward?.pointsCost })}
              </Typography>
              <TextField
                label={t('pin')}
                type="password"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
                fullWidth
                margin="normal"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>{t('cancel')}</Button>
          {!showQR && <Button onClick={handleConfirmRedeem}>{t('confirm')}</Button>}
        </DialogActions>
      </Dialog>

      {/* Details Modal */}
      <Dialog open={detailsModalOpen} onClose={handleCloseDetailsModal}>
        <DialogTitle>{selectedReward?.name}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <img src={selectedReward?.image} alt={selectedReward?.name} width="100" height="100" />
            <Typography variant="body1">{selectedReward?.description}</Typography>
            <Chip label={`${selectedReward?.pointsCost} ${t('pointsTitle')}`} color="primary" />
            <Button 
              variant="contained" 
              color="success"
              onClick={() => {
                handleCloseDetailsModal();
                handleRedeem(selectedReward);
              }}
              sx={{ 
                mt: 2,
                backgroundColor: '#3498db', // A nice blue color
                '&:hover': {
                  // Slightly darker blue on hover
                  backgroundColor: '#1b5780', 
                },
              }}
            >
              {t('redeem')}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailsModal}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CompanyRewards;
