import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

const MainBanner = ({ ads }) => {
  return (
    <BannerContainer>
      <StyledSwiper
        modules={[Autoplay]}
        spaceBetween={2}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {ads.map((ad) => (
          <SwiperSlide key={ad.id} style={{ overflow: 'hidden', borderRadius: '5px' }}>
            <SlideContent>
              <BannerImage src={ad.image} alt={ad.title} onClick={() => window.open(ad.uniqueUrl, '_blank')} />
            </SlideContent>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  width: 100%;
  height: 120px; // Fixed height for the banner
  overflow: hidden;
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
`;

const SlideContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const BannerImage = styled.img`
  height: 100%;
  width: auto;
  object-fit: contain;
  border-radius: 5px;
`;

export default MainBanner;
