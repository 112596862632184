import axios from 'axios';

let API_BASE_URL;

switch (true) {
  case window.location.hostname.startsWith('192.168'):
    API_BASE_URL = 'http://192.168.0.113:5001/tecolotlanrewards-52e97/us-central1';
    console.log('API_BASE_URL', API_BASE_URL);
    break;
  case !!process.env.REACT_APP_API_BASE_URL:
    API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    break;
  case window.location.hostname === 'tecolotlanrewards-52e97.web.app':
    API_BASE_URL = 'https://us-central1-tecolotlanrewards-52e97.cloudfunctions.net';
    break;
  default:
    API_BASE_URL = 'http://127.0.0.1:5001/tecolotlanrewards-52e97/us-central1';
}

const apiCall = async (method, endpoint, data = null, isFormData = false, idToken = null) => {
  console.log('API Call NEW:', method, endpoint, data, isFormData, idToken);
  try {
    const config = {
      method,
      url: `${API_BASE_URL}${endpoint.startsWith('/') ? '' : '/'}${endpoint}`,
      headers: {
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
      },
      data: isFormData ? data : (data ? JSON.stringify(data) : undefined),
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    };
    console.log('token', idToken);
    if (idToken) {
      config.headers['Authorization'] = `Bearer ${idToken}`;
      // config.headers['Access-Control-Allow-Origin'] = 'https://tecolotlanrewards-52e97.web.app';
      // config.headers['Access-Control-Allow-Credentials'] = 'true';
      // config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS';
      // config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization, Content-Length, Accept-Encoding, X-CSRF-Token, X-Requested-With, Accept, Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin, Access-Control-Allow-Credentials';

      console.log('config.headers', config.headers);
    }

    const response = await axios(config);
    console.log('API Response:', response);
    return response.data;
  } catch (error) {
    console.error('API Call Error:', error);
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access
      console.log('Unauthorized access');
      console.log('error.response.data', error.response.data);
      console.log('error.response', error.response);
    }
    throw error.response?.data || error.message;
  }
};

export default apiCall;
