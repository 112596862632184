import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const CartButton = styled.button`
  background-color: ${props => props.$isAdded ? '#2ecc71' : '#3498db'};
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
`;

const ItemList = ({ items, cartItems, onAddToCart, onRemoveFromCart }) => {
  const handleIncreaseQuantity = (item) => {
    onAddToCart(item);
  };

  const handleDecreaseQuantity = (item) => {
    onRemoveFromCart(item);
  };

  return (
    <ItemListContainer>
      <StyledSwiper
        spaceBetween={0}
        slidesPerView={2.4}
        freeMode={true}
      >
        {items.map((item) => {
          const quantity = cartItems.items.find(cartItem => cartItem.uid === item.uid)?.quantity || 0;
          
          return (
            <SwiperSlide key={item.uid}>
              <ItemCard>
                <ItemImage src={item.image} alt={item.name} />
                <CompanyName>{item.companyName}</CompanyName>
                <ItemName>{item.name}</ItemName>
                <ItemDescription>{item.description}</ItemDescription>
                <ItemFooter>
                  <ItemPrice>${item.price}</ItemPrice>
                  {quantity > 0 ? (
                    <QuantityContainer>
                      <QuantityButton onClick={() => handleDecreaseQuantity(item)}>
                        -
                      </QuantityButton>
                      <Quantity>{quantity}</Quantity>
                      <QuantityButton onClick={() => handleIncreaseQuantity(item)}>
                        +
                      </QuantityButton>
                    </QuantityContainer>
                  ) : (
                    <CartButton 
                      onClick={() => handleIncreaseQuantity(item)}
                      $isAdded={false}
                    >
                      +
                    </CartButton>
                  )}
                </ItemFooter>
              </ItemCard>
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </ItemListContainer>
  );
};

const CompanyName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 2px 5px 0px 5px;
`;

const QuantityButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  color: #3498db;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 5px;
  
  &:hover {
    color: #2980b9;
  }
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 15px;
`;

const Quantity = styled.span`
  font-size: 14px;
  margin: 0 5px;
`;

const ItemListContainer = styled.div`
  width: 95%;
  margin: 5px auto;
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
`;

const ItemCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 85%;
  height: 180px;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 65px;
  max-height: 65px;
  object-fit: cover;
`;

const ItemName = styled.h4`
  font-size: 14px;
  margin: 0;
  padding: 0 5px;
`;

const ItemDescription = styled.p`
  font-size: 10px;
  color: #666;
  margin: 0;
  padding: 0 5px;
  flex-grow: 1;
  overflow: auto;
  /* text-overflow: ellipsis; */
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
`;

const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;

const ItemPrice = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export default ItemList;
