import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Typography, Dialog, Card, CardMedia, CardContent, CardActions, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import QrScanner from 'react-qr-scanner';
import { redeemPointsForUser } from '../../../api/pointsApi';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';

const RedeemPoints = () => {
  const { t } = useTranslation();
  const [isScanning, setIsScanning] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [message, setMessage] = useState('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [facingMode, setFacingMode] = useState('environment');
  const { user: employeeData } = useSelector((state) => state.auth);
  const { idToken } = useAuth();
  const qrScannerRef = useRef(null);
  
  const handleScan = (data) => {
    if (data) {
      try {
        const parsedData = JSON.parse(data.text);
        setCustomerData(parsedData);
        setIsScanning(false);
      } catch (err) {
        console.error('Error parsing QR code data:', err);
        setMessage(t('errorScanningQR'));
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setMessage(t('errorScanningQR'));
  };

  const handleRedeemClick = () => {
    setIsConfirmOpen(true);
  };

  const handleConfirmRedeem = async () => {
    setIsConfirmOpen(false);
    try {
      await redeemPointsForUser(customerData.userId, customerData.userPinCode, customerData.reward, employeeData.companyId, idToken);
      setMessage(t('redeemSuccessful'));
      setCustomerData(null);
    } catch (error) {
      if (error.message.includes('401')) {
        setMessage(t('errorUnauthorized'));
      } 
      else if (error.message.includes('400')) {
        setMessage(t('errorInsufficientPoints'));
      } else {
        setMessage(t('errorRedeeming'));
      }
      console.error('Error redeeming points:', error);
    }
  };

  const handleCancelRedeem = () => {
    setIsConfirmOpen(false);
  };

  const toggleCamera = () => {
    setFacingMode(prevMode => prevMode === 'environment' ? 'user' : 'environment');
    if (qrScannerRef.current) {
      qrScannerRef.current.openImageDialog();
    }
  };

  return (
    <Box sx={{ 
      maxWidth: 600, 
      margin: 'auto', 
      backgroundColor: '#2C2D2E', 
      padding: 3, 
      borderRadius: 2,
      color: '#E8E6E3'
    }}>
      <Typography variant="h6" gutterBottom sx={{ color: '#E8E6E3' }}>
        {t('redeemPointsForUser')}
      </Typography>

      {!customerData || !customerData.reward ? (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => setIsScanning(true)}
          sx={{ mb: 2, backgroundColor: '#4E9EF5', '&:hover': { backgroundColor: '#6FB2FF' } }}
        >
          {t('scanCustomerQR')}
        </Button>
      ) : (
        <Card sx={{ mb: 2, backgroundColor: '#3A3B3C', color: '#E8E6E3' }}>
          <CardMedia
            component="img"
            height="200"
            image={customerData.reward.image}
            alt={customerData.reward.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ color: '#E8E6E3' }}>
              {customerData.reward.name}
            </Typography>
            <Typography variant="body2" sx={{ color: '#B0B3B8' }}>
              {customerData.reward.description}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, color: '#E8E6E3' }}>
              {t('pointsCost')}: {customerData.reward.pointsCost}
            </Typography>
            <Typography variant="body1" sx={{ color: customerData.reward.isAvailable ? '#4CAF50' : '#F44336' }}>
              {customerData.reward.isAvailable ? t('available') : t('notAvailable')}
            </Typography>
          </CardContent>
          <CardActions>
            <Button 
              size="small" 
              color="primary" 
              onClick={handleRedeemClick}
              disabled={!customerData.reward.isAvailable}
              sx={{ color: '#4E9EF5' }}
            >
              {t('redeem')}
            </Button>
          </CardActions>
        </Card>
      )}

      {message && (
        <Typography sx={{ mt: 2, color: message.includes('error') ? '#F44336' : '#4CAF50' }}>
          {message}
        </Typography>
      )}

      <Dialog 
        open={isScanning} 
        onClose={() => setIsScanning(false)}
        PaperProps={{
          style: {
            backgroundColor: '#2C2D2E',
            color: '#E8E6E3',
          },
        }}
      >
        <Box sx={{ width: '300px', height: '300px', position: 'relative' }}>
          <QrScanner
            ref={qrScannerRef}
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
            constraints={{
              video: { facingMode: facingMode }
            }}
          />
          <Button
            onClick={toggleCamera}
            sx={{
              position: 'absolute',
              bottom: 10,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              color: '#4E9EF5',
            }}
          >
            {t('switchCamera')}
          </Button>
        </Box>
      </Dialog>

      <Dialog
        open={isConfirmOpen}
        onClose={handleCancelRedeem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: '#2C2D2E',
            color: '#E8E6E3',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: '#E8E6E3' }}>
          {t('confirmRedeemTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: '#B0B3B8' }}>
            {t('confirmRedeem', { name: customerData?.reward?.name, pointsCost: customerData?.reward?.pointsCost })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRedeem} sx={{ color: '#4E9EF5' }}>
            {t('cancel')}
          </Button>
          <Button onClick={handleConfirmRedeem} sx={{ color: '#4E9EF5' }} autoFocus>
            {t('redeem')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RedeemPoints;
