import React, { useState } from 'react';
import { Box, Typography, Avatar, Paper, Modal } from '@mui/material';
import Slider from 'react-slick';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TileClickDecision from './TileClickDecision';

const PointsTileSlider = ({ points, isMobile }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTileClick = (company) => {
    setSelectedCompany(company);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCompany(null);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
        }
      }
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  if (points.length === 0 || !points) {
    return <div>No points available</div>;
  }
  // Generate a unique key based on the points data
  const sliderKey = points.map(p => `${p.companyId}-${p.pointsBalance}-${p.isActive}`).join('|');
  // console.log({sliderKey});
  // console.log({points});

  return (
    <Box sx={{ width: '100%', maxWidth: isMobile ? 250 : 500, margin: '0 auto', backgroundColor: '#adcce9' }}>
      <Slider {...settings} key={sliderKey}>
        {points.map((pointObj, index) => (
          <Box key={pointObj.companyId} sx={{ p: 0.5 }}>
            <Paper
              elevation={2}
              sx={{
                width: '100%',
                maxWidth: { xs: 80, sm: 90 },
                height: { xs: 80, sm: 90 },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                backgroundColor: '#f0f3f6',
                cursor: 'pointer',
                position: 'relative', // Added for positioning the checkmark
              }}
              onClick={() => handleTileClick(pointObj)}
            >
              <Avatar
                src={pointObj.logo}
                alt={pointObj.companyName}
                sx={{ width: { xs: 20, sm: 30 }, height: { xs: 20, sm: 30 }, mb: 0.5 }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: { xs: '0.6rem', sm: '0.7rem' }, mb: 0.2, maxWidth: '100%', textAlign: 'center' }}
              >
                {pointObj.companyName}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: 'bold', fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
              >
                {pointObj.pointsBalance}
              </Typography>
              <CheckCircleIcon 
                sx={{
                  position: 'absolute',
                  top: 2,
                  right: 2,
                  fontSize: '1rem',
                  color: pointObj.isActive ? 'green' : 'grey',
                }}
              />
            </Paper>
          </Box>
        ))}
      </Slider>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="tile-click-decision-modal"
        aria-describedby="choose-action-for-selected-company"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <TileClickDecision 
            company={selectedCompany} 
            onClose={handleCloseModal}
          />
        </Box>
      </Modal>
      <style>{`
        .slick-prev:before,
        .slick-next:before {
          color: #1976d2 !important;
        }
        .slick-prev,
        .slick-next {
          width: 20px;
          height: 20px;
        }
        @media (max-width: 600px) {
          .slick-prev {
            left: -15px;
          }
          .slick-next {
            right: -15px;
          }
        }
      `}</style>
    </Box>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "-10px"}}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "-10px" }}
      onClick={onClick}
    />
  );
};

export default PointsTileSlider;
