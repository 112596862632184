import React, { useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { updateUser } from '../redux/authSlice';
import '../styles/AdminDashboard.css';

const AdminDashboard = React.memo(() => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const unsubscribeRef = useRef();

  const sections = useMemo(() => [
    { name: 'Create Company', path: '/admin/create-company' },
    { name: 'View Companies', path: '/admin/companies' },
    { name: 'View Users', path: '/admin/users' },
    { name: 'View Rewards', path: '/admin/rewards' },
    { name: 'View Products', path: '/admin/products' },
    { name: 'View Ads', path: '/admin/ads' },
    { name: 'View Events', path: '/admin/events' },
    { name: 'View Calendars', path: '/admin/calendars' },
  ], []);

  useEffect(() => {
    if (user?.uid && !unsubscribeRef.current) {
      const userRef = doc(db, 'users', user.uid);
      unsubscribeRef.current = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const newUserData = doc.data();
          if (newUserData.createdAt && typeof newUserData.createdAt.toDate === 'function') {
            newUserData.createdAt = newUserData.createdAt.toDate().toISOString();
          }
          if (JSON.stringify(newUserData) !== JSON.stringify(user)) {
            dispatch(updateUser(newUserData));
          }
        }
      });

      return () => {
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
          unsubscribeRef.current = null;
        }
      };
    }
  }, [user?.uid, dispatch]);

  const SectionLink = React.memo(({ section }) => (
    <Link to={section.path} className="admin-section-link">
      {section.name}
    </Link>
  ));

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <div className="admin-sections">
        {sections.map((section) => (
          <SectionLink key={section.path} section={section} />
        ))}
      </div>
    </div>
  );
});

AdminDashboard.displayName = 'AdminDashboard';

export default AdminDashboard;
