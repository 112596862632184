import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box } from '@mui/material';
import { generateUniqueUrl } from '../../../utils/url';

const TileClickDecision = ({ company, onClose }) => {
  const navigate = useNavigate();

  const handleViewCompany = () => {
    const uniqueUrl = generateUniqueUrl(company.companyName);
    navigate(`/${uniqueUrl}`);
    onClose();
  };

  const handleManagePoints = () => {
    navigate(`/manage-points/${company.companyId}`, { state: { company } });
    onClose();
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {company.companyName}
      </Typography>
      <Button onClick={handleViewCompany} fullWidth variant="contained" sx={{ mb: 2 }}>
        View Company Site
      </Button>
      <Button onClick={handleManagePoints} fullWidth variant="outlined">
        Manage My Points
      </Button>
    </Box>
  );
};

export default TileClickDecision;

