import React, { useState, useCallback } from 'react';
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, db, googleProvider } from '../firebaseConfig';
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaGoogle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { login, userIsLoggedIn } from '../redux/authSlice';
import { useTranslation } from 'react-i18next';
import { loginUser } from '../api/userApi';
import { doc, getDoc } from 'firebase/firestore';

function Login() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = useCallback(async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await processLogin(userCredential.user);
    } catch (error) {
      console.error("Error during login process:", error);
      setError(error.message || "An error occurred during login. Please try again.");
    }
  }, [email, password]);

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      await processLogin(result.user);
    } catch (error) {
      console.error("Error during Google login:", error);
      setError(error.message || "An error occurred during Google login. Please try again.");
    }
  };

  const processLogin = async (user) => {
    try {
      const idToken = await user.getIdToken();
      const userData = await loginUser(user.email, idToken);
      dispatch(login({ ...userData }));
      dispatch(userIsLoggedIn(true));
    } catch (error) {
      console.error("Error processing login:", error);
      setError(error.message || "An error occurred while processing your login. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <img src="/hyperlinkLogo.png" alt="HyperLink.it Logo" className="login-logo" />
        <h2>{t('loginToAccount')}</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            value={email}
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('email')}
            required
          />
          <div className="password-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('password')}
              required
            />
            <button
              type="button"
              className="toggle-password"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <button type="submit" className="login-button">{t('loginButton')}</button>
        </form>
        <div className="google-login-wrapper">
          <button onClick={handleGoogleLogin} className="google-login-button">
            <FaGoogle /> {t('loginWithGoogle')}
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}
        <div className="signup-link">
          {t('dontHaveAccount')} <Link to="/signup">{t('signup')}</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
