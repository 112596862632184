import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSection: null,
  selectedElement: null,
};

const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    setSelectedSection: (state, action) => {
      if(!action.payload) {
        state.selectedSection = null;
        return;
      } else if (state.selectedSection?.id !== action.payload.id) {
        state.selectedElement = null;
      }
      state.selectedSection = action.payload;
      // console.log('state.selectedSection');
      // console.log(state.selectedSection);
      // console.log('action.payload_____________________');
      // console.log(action.payload);
    },
    setSelectedElement: (state, action) => { 
      if (state.selectedElement === action.payload) {
        state.selectedElement = null;
      } else if (state.selectedElement?.id !== action.payload) {
        // state.selectedSection = null;
        state.selectedElement = action.payload;
      }

    },
  },
});

export const { setSelectedSection, setSelectedElement } = sectionSlice.actions;
export default sectionSlice.reducer;
