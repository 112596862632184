import apiCall from './apiCall';

const API_ENDPOINT = '/siteApi';


export const getPublicBusinessPage = async (companyName) => {
    try {
      const response = await apiCall('get', `${API_ENDPOINT}/${companyName}`, null, false);
      return response.data;
    } catch (error) {
      console.error('Error fetching business page:', error);
      throw error;
    }
  };
  