import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoggedOutHeader = ({ deferredPrompt, handleInstallClick }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="logged-out-header">
      <div className="container">
        {deferredPrompt && (
          <button onClick={handleInstallClick} className="install-button">
            {t('installButton')}
          </button>
        )}
        {/* <Link to="/" className="logo-link">
          <img src="/hyperlinkLogo.png" alt="Hyperlink.it Logo" className="logo" style={{ width: '100px', height: 'auto' }} />
        </Link>
        <nav>
          <Link to="/login" className="auth-link">{t('login')}</Link>
          <Link to="/signup" className="auth-link">{t('signup')}</Link>
          <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
            <option value="en">English</option>
            <option value="es">Español</option>
          </select>
        </nav> */}
      </div>
    </header>
  );
};

export default LoggedOutHeader;
