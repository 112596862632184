import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { registerCompany } from '../../api/companyApi';
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from '../../firebaseConfig';
import { useTranslation } from 'react-i18next';
import { login } from '../../redux/authSlice';
import { useDispatch } from 'react-redux';
import { doc, setDoc } from 'firebase/firestore';

const CompanySignUp = () => {
  const { t } = useTranslation();
  const [companyData, setCompanyData] = useState({
    companyName: '',
    email: '',
    password: '',
    token: '',
    // Add other necessary fields
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (token) {
      setCompanyData(prevData => ({ ...prevData, token }));
    }
  }, [location]);

  const handleChange = (e) => {
    setCompanyData({ ...companyData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      // Create Firebase user
      const userCredential = await createUserWithEmailAndPassword(auth, companyData.email, companyData.password);
      const user = userCredential.user;
      console.log({user});
      console.log({companyData});
      const userData = {
        uid: user.uid,
        isAdmin: false,
        name: '',
        email: companyData.email,
        isCompanyAdmin: true,
        isCompanyOwner: true,
        phoneNumber: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        avatar: '/avatars/avatar4.jpg',
        optedInCompanies: [],
        points: [],
        displayName: companyData.companyName,
        location: null,
      };
      
      await setDoc(doc(db, "users", user.uid), userData);
      // Register company with backend
      const registerResponse = await registerCompany({
        ...companyData,
      }, user.accessToken);
      
      console.log('Company registered successfully:', registerResponse);
      // Update user profile
      await updateProfile(user, { displayName: companyData.companyName });
      const userToSignIn = registerResponse.data;
      userToSignIn.companyProfile = companyData;
      dispatch(login(userToSignIn));
      navigate('/');
    } catch (error) {
      console.error('Error registering company:', error);
      setError(error.message || 'An error occurred during sign up. Please check your network connection and try again.');
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="company-signup-container">
      <div className="company-signup-form">
        <h2>{t('companySignUp')}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="companyName"
            value={companyData.companyName}
            onChange={handleChange}
            placeholder={t('companyName')}
            required
          />
          <input
            type="email"
            name="email"
            value={companyData.email}
            onChange={handleChange}
            placeholder={t('email')}
            required
          />
          <div className="password-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={companyData.password}
              onChange={handleChange}
              placeholder={t('password')}
              required
            />
            <button
              type="button"
              className="toggle-password"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {/* Add other necessary input fields */}
          <button type="submit" className="company-signup-button" disabled={loading}>
            {loading ? t('signingUp') : t('signUp')}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        <div className="login-link">
          {t('alreadyHaveAccount')} <Link to="/login">{t('login')}</Link>
        </div>
      </div>
    </div>
  );
};

export default CompanySignUp;
