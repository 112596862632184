import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearError } from '../../redux/errorSlice';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  background-color: ${props => props.type === 'error' ? '#ffcccc' : '#ccffcc'};
  color: ${props => props.type === 'error' ? '#cc0000' : '#006600'};
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  font-size: 18px;
  cursor: pointer;
`;

const ErrorDisplay = () => {
  const { message, type } = useSelector((state) => state.error);
  const dispatch = useDispatch();

  if (!message) return null;

  return (
    <ErrorContainer type={type}>
      <span>{message}</span>
      <CloseButton onClick={() => dispatch(clearError())}>×</CloseButton>
    </ErrorContainer>
  );
};

export default ErrorDisplay;