import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import HeaderEdit from './sidebarComponents/HeaderEdit';
import MenuEdit from './sidebarComponents/MenuEdit';

const SidebarContainer = styled.div`
  width: 200px;
  background-color: black;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  position: sticky;
  top: 0;
  left: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Input = styled.input`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Select = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FontSelect = styled.div`
  position: relative;
`;

const FontSearch = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FontList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1;
`;

const FontItem = styled.li`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const RemoveButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #dc352d;
  }
`;

const AddSectionButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;

  &:hover {
    background-color: #218838;
  }
`;

const Sidebar = ({
  companyData,
  templateRules,
  fontSearch,
  showFontList,
  fontSizeOptions,
  filteredFonts,
  handleUpdateSettings,
  setFontSearch,
  setShowFontList,
  fontSelectRef,
  selectedSection,
  updateSectionColumns,
  updateSectionType,
  sections,
  removeSection,
  addNewSection,
  selectedElement,
}) => {
  const { t } = useTranslation();

  const getSelectedSectionColumns = () => {
    if (!sections) return 1;
    const section = sections.find(s => s.id === selectedSection?.id);
    return section ? section.columns.length : 1;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add any form submission logic here if needed
  };
  console.log({templateRules});
  console.log({selectedSection});
  console.log({sections});
  const [selectedSectionData, setSelectedSectionData] = useState(null);

  useEffect(() => {
    if (selectedSection) {
      setSelectedSectionData(sections.find(s => s.id === selectedSection?.id));
    }
  }, [selectedSection, templateRules]);

  console.log({selectedSectionData});
  return (
    <SidebarContainer>
      {selectedElement ? (
        <h1>Selected Element: {selectedElement.split('-')[2]}</h1>
      ) : selectedSection ? (
        <h1>Selected Section: {selectedSectionData?.type}</h1>
      ) : null}
      <Form onSubmit={handleSubmit}>
        {selectedSection && (
          <>
            {/* <Label>
              {t('numberOfColumns')}:
              <Select
                value={getSelectedSectionColumns()}
                onChange={(e) => updateSectionColumns(selectedSection, parseInt(e.target.value))}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </Select>
            </Label> */}
            
            {!selectedElement && selectedSection && (
              <Label>
              {t('sectionType')}:
              <Select
                value={templateRules.sections.find(s => s.id === selectedSection?.id)?.type}
                onChange={(e) => updateSectionType(selectedSection?.id, e.target.value)}
              >
                <option value="Header">Header</option>
                <option value="Menu">Menu</option>
                <option value="Image">Image</option>
                <option value="Text">Text</option>
                <option value="Button">Button</option>
                <option value="iFrame">iFrame</option>
                <option value="Empty">Empty</option>
              </Select>
            </Label>
            )}
            
            {/* Existing form fields */}
            {selectedSectionData?.type === 'iFrame' && (
              <Label>
                {t('iFrameURL')}:
                <Input type="text" value={selectedSectionData.link || ''} onChange={(e) => handleUpdateSettings('link', e.target.value, selectedSection?.id)} />
              </Label>
            )}

            {selectedSectionData?.type === 'Header' && (
              <HeaderEdit 
                companyData={companyData}
                selectedElement={selectedElement}
                sectionId={selectedSection?.id}
                sectionData={selectedSectionData}
                templateRules={templateRules} 
                handleUpdateSettings={handleUpdateSettings} 
                t={t}
                fontSearch={fontSearch}
                showFontList={showFontList}
                fontSizeOptions={fontSizeOptions}
                filteredFonts={filteredFonts}
                setFontSearch={setFontSearch}
                setShowFontList={setShowFontList}
                fontSelectRef={fontSelectRef}
                Label={Label}
                Input={Input}
                Select={Select}
                FontSelect={FontSelect}
                FontSearch={FontSearch}
                FontList={FontList}
                FontItem={FontItem}
              />
            )}
            {selectedSectionData?.type === 'Menu' && (
              <MenuEdit 
              sectionId={selectedSection?.id}
              templateRules={templateRules} 
              handleUpdateSettings={handleUpdateSettings} 
              t={t}
              fontSizeOptions={fontSizeOptions}
              Label={Label}
              Input={Input}
              Select={Select}
              />
            )}
            <RemoveButton type="button" onClick={() => removeSection(selectedSection?.id)}>
              {t('removeSection')}
            </RemoveButton>
          </>
        )}
        {!selectedSection && <p>{t('selectSectionToCustomize')}</p>}
        <AddSectionButton type="button" onClick={addNewSection}>
          {t('addNewSection')}
        </AddSectionButton>
      </Form>
    </SidebarContainer>
  );
};

export default Sidebar;
