import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmWelcomeMessage } from '../../../redux/authSlice';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';

const WelcomeModal = ({ isOpen, onClose, showWelcomeInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const welcomeMessageConfirmed = useSelector(state => state.auth.welcomeMessageConfirmed);

  if (!showWelcomeInfo && (!isOpen || (user && (welcomeMessageConfirmed || user.welcomeMessageConfirmed)))) return null;

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleClose = async () => {
    dispatch(confirmWelcomeMessage());
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { welcomeMessageConfirmed: true });
    }
    onClose();
  };

  return (
    <Overlay>
      <ModalContainer onClick={handleModalClick}>
        <CloseIcon onClick={handleClose}>
          <X size={24} />
        </CloseIcon>
        <Content>
          {t('homeConstructionExplanation').split('\n').map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </Content>
        <CloseButton onClick={handleClose}>{t('understood')}</CloseButton>
      </ModalContainer>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  max-height: 75vh;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const CloseIcon = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #666;
  
  &:hover {
    color: #333;
  }
`;

const Content = styled.div`
  margin: 20px 0;
  overflow-y: auto;
  
  p {
    margin: 8px 0;
    font-size: 14px;
    line-height: 1;
    
    &:first-child {
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

const CloseButton = styled.button`
  background-color: #D32F2F;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  
  &:hover {
    background-color: #B71C1C;
  }
`;

export default WelcomeModal; 