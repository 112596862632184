import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Dialog } from '@mui/material';
import { listItems, createItem, updateItem, deleteItem } from '../../api/itemsApi';
import ItemList from './itemsComponents/ItemList';
import ItemForm from './itemsComponents/ItemForm';
import ConfirmDelete from './itemsComponents/ConfirmDelete';
import { useAuth } from '../../hooks/useAuth';

const ItemsManagement = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [newItem, setNewItem] = useState({
    name: '',
    price: 0,
    description: '',
    image: '',
    category: '',
    isAvailable: true,
    isFeatured: false,
    hasDiscount: false,
    discountPercentage: '',
    discountExpiration: '',
    stockQuantity: 0,
    itemType: 'product',
    otherCategory: '',
  });
  const [imageFile, setImageFile] = useState(null);
  const { idToken } = useAuth();

  useEffect(() => {
    idToken && fetchItems();
  }, [idToken, user.companyId]);

  console.log({user});
  
  const fetchItems = async () => {
    try {
      const fetchedItems = await listItems(user.companyId, false, false);
      setItems(fetchedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleEdit = (uid) => {
    const itemToEdit = items.find(item => item.uid === uid);
    setEditingItem(itemToEdit);
    setImageFile(null);
    setOpenDialog(true);
  };

  const handleDeleteClick = (uid) => {
    const itemToDelete = items.find(item => item.uid === uid);
    setItemToDelete(itemToDelete);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteItem(itemToDelete.uid, idToken);
      setOpenDeleteDialog(false);
      setItemToDelete(null);
      fetchItems();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleChange = (field, value) => {
    if (editingItem) {
      setEditingItem(prevItem => ({ ...prevItem, [field]: value }));
    } else {
      setNewItem(prevItem => ({ ...prevItem, [field]: value }));
    }
  };

  const handleImageChange = (file) => {
    setImageFile(file);
  };

  const handleSubmit = async () => {
    try {
      const itemData = editingItem || newItem;
      
      if (editingItem) {
        await updateItem(editingItem.uid, itemData, imageFile, idToken);
      } else {
        await createItem(user.companyId, itemData, imageFile, idToken);
      }
      handleCloseDialog();
      fetchItems();
      localStorage.removeItem('mainItems');
    } catch (error) {
      console.error('Error saving item:', error);
    }
  };

  const handleOpenDialog = () => {
    setEditingItem(null);
    setNewItem({
      name: '',
      price: 0,
      description: '',
      image: '',
      category: '',
      isAvailable: true,
      isFeatured: false,
      hasDiscount: false,
      discountPercentage: '',
      discountExpiration: '',
      stockQuantity: 0,
      itemType: 'product',
      otherCategory: '',
    });
    setImageFile(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEditingItem(null);
    setNewItem({
      name: '',
      price: 0,
      description: '',
      image: '',
      category: '',
      isAvailable: true,
      isFeatured: false,
      hasDiscount: false,
      discountPercentage: '',
      discountExpiration: '',
      stockQuantity: 0,
      itemType: 'product',
      otherCategory: '',
    });
    setImageFile(null);
    setOpenDialog(false);
  };

  return (
    <div className="items-management">
      <h2>{t('itemsManagement')}</h2>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        {t('createNewItem')}
      </Button>
      {!items?.length ? (
        <p>{t('noItemsFound')}</p>
      ) : (
        <ItemList
          country={user.companyProfile.country}
          items={items}
          onEdit={handleEdit}
          onDelete={handleDeleteClick}
        />
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <ItemForm
          item={editingItem || newItem}
          onChange={handleChange}
          onImageChange={handleImageChange}
          onSubmit={handleSubmit}
          onClose={handleCloseDialog}
          isNew={!editingItem}
        />
      </Dialog>
      <ConfirmDelete
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteConfirm}
        itemName={itemToDelete?.name || ''}
      />
    </div>
  );
};

export default ItemsManagement;
