import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

const CartModal = ({ isOpen, onClose, cartItems, onIncreaseQuantity, onDecreaseQuantity }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  const total = cartItems.items.reduce((sum, item) => sum + (item.price * item.quantity), 0);

  return (
    <ModalOverlay>
      <ModalContent>
        <Header>
          <BackButton onClick={onClose}>←</BackButton>
          <Title>{t('cart')}</Title>
        </Header>

        <CartItems>
          {cartItems.items.map(item => (
            <CartItem key={item.uid}>
              <ItemImage src={item.image} alt={item.name} />
              <ItemInfo>
                <ItemName>{item.name}</ItemName>
                <ItemPrice>${item.price}</ItemPrice>
              </ItemInfo>
              <QuantityContainer>
                <QuantityButton onClick={() => onDecreaseQuantity(item)}>-</QuantityButton>
                <Quantity>{item.quantity}</Quantity>
                <QuantityButton onClick={() => onIncreaseQuantity(item)}>+</QuantityButton>
              </QuantityContainer>
              <ItemTotal>${(item.price * item.quantity).toFixed(2)}</ItemTotal>
            </CartItem>
          ))}
        </CartItems>

        <Footer>
          <TotalContainer>
            <TotalLabel>{t('total')}</TotalLabel>
            <TotalAmount>${total.toFixed(2)}</TotalAmount>
          </TotalContainer>
          <Button variant="contained" onClick={onClose} disabled>{t('checkout')}</Button>
          <p><span style={{ color: 'red' }}>{t('underConstruction')}</span></p>
        </Footer>
      </ModalContent>
    </ModalOverlay>
  );
};

const TotalContainer = styled.div`
  display: inline-flex;
  /* flex-direction: row; */
  justify-content: space-between;
  background-color: #f0f3f6;
  width: 100%;
`;

// background: linear-gradient(to bottom, #3498db 0%, #3498db 30%, #f0f3f6 50%);

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, #3498db 0%, #3498db 30%, #f0f3f6 50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 20px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  color: white;
  text-shadow: 1px 1px 1px #333;
`;

const CartItems = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 5px;
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
`;

const ItemImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
`;

const ItemInfo = styled.div`
  flex-grow: 1;
`;

const ItemName = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ItemPrice = styled.div`
  color: #333;
  text-shadow: 1px 1px 1px #666;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 15px;
  margin: 0 10px;
`;

const QuantityButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  color: #3498db;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #2980b9;
  }
`;

const Quantity = styled.span`
  margin: 0 10px;
  min-width: 20px;
  text-align: center;
`;

const ItemTotal = styled.div`
  font-weight: bold;
  min-width: 70px;
  text-align: right;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f3f6;
`;

const TotalLabel = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const TotalAmount = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #3498db;
`;

export default CartModal;
