import { t } from 'i18next';

export const mobileSettings = {
  colors: {
    primary: '#3498db', // Nice blue color
    background: '#f0f3f6',
    text: '#333333',
  },
  mainBannerAds: [
    // Add 8 ad objects here
    { id: 1, image: '/AlazChelazAd.png', title: 'ad1', uniqueUrl: 'https://hyperlink.it.com/alaz-chelaz' },
    { id: 2, image: 'https://lh5.googleusercontent.com/p/AF1QipMTW1u2YEM3MEkRdsicteV0_kIDMtBRTfDZdUN5=s515-k-no', title: 'ad2', uniqueUrl: 'https://maps.app.goo.gl/BTZZPQPhpZxzsfFaA' },
    // ... more ads
  ],
  secondaryBannerAds: [
    // Add 2 ad objects here
    { id: 1, image: 'https://storage.googleapis.com/tecolotlanrewards-52e97.appspot.com/687d56b4-91a5-4ab8-ad5d-120bf2f61583.jpeg', titleKey: 'popularProduct', uniqueUrl: 'https://hyperlink.it.com/alaz-chelaz' },
    { id: 2, image: 'https://lh5.googleusercontent.com/p/AF1QipMTW1u2YEM3MEkRdsicteV0_kIDMtBRTfDZdUN5=s515-k-no', titleKey: 'popularBusiness', uniqueUrl: 'https://maps.app.goo.gl/BTZZPQPhpZxzsfFaA' },
  ],
  categories: [
    { id: 1, nameKey: "sales", name: "Sales", icon: '🏷️' },
    { id: 2, nameKey: "groceries", name: "Groceries", icon: '🛒' },
    { id: 3, nameKey: "restaurants", name: "Restaurants", icon: '🍽️' },
    { id: 4, nameKey: "carwash", name: "Carwash", icon: '🚗' },
    { id: 5, nameKey: "liquor", name: "Liquor", icon: '🍾' },
    // Add 10 more categories
    { id: 6, nameKey: "beauty", name: "Beauty", icon: '💄' },
    { id: 7, nameKey: "health", name: "Health", icon: '🏥' },
    { id: 8, nameKey: "electronics", name: "Electronics", icon: '📱' },
    { id: 9, nameKey: "furniture", name: "Furniture", icon: '🛋️' },
    { id: 10, nameKey: "home", name: "Home", icon: '🏠' },
    { id: 11, nameKey: "jewelry", name: "Jewelry", icon: '💍' },
    { id: 12, nameKey: "travel", name: "Travel", icon: '🌍' },
    { id: 13, nameKey: "automotive", name: "Automotive", icon: '🚗' },
    { id: 14, nameKey: "books", name: "Books", icon: '📚' },
    { id: 15, nameKey: "music", name: "Music", icon: '🎧' },
    { id: 16, nameKey: "sports", name: "Sports", icon: '🏃‍♂️' },
    { id: 17, nameKey: "toys", name: "Toys", icon: '🧸' },
    { id: 18, nameKey: "art", name: "Art", icon: '🎨' },
    { id: 19, nameKey: "crafts", name: "Crafts", icon: '🧵' },
    { id: 20, nameKey: "education", name: "Education", icon: '📚' },
  ],
};

export const webSettings = {
  // Add web-specific settings here
  colors: {
    primary: '#2980b9', // Slightly darker blue for web
    background: '#ffffff',
    text: '#333333',
  },
  // Add other web-specific settings
};

export const items = [
    {
      "id": "item1",
      "image": "/avatars/avatar1.jpg",
      "isAvailable": true,
      "discountExpiration": null,
      "itemType": "menuItem",
      "hasDiscount": false,
      "description": "PARA PEDIDOS CON SABORES COMBINADOS, SE APLICA UN COSTO ADICIONAL DE 10 PESOS.",
      "otherCategory": "",
      "stockQuantity": 0,
      "discountPercentage": 0,
      "createdAt": {
        "_seconds": 1698329618,
        "_nanoseconds": 0
      },
      "uid": "item1",
      "companyId": "company1",
      "price": 149,
      "name": "10 Alitas",
      "category": "main-courses",
      "isFeatured": false,
      "updatedAt": {
        "_seconds": 1698329618,
        "_nanoseconds": 0
      }
    },
    {
      "id": "item2",
      "image": "/avatars/avatar2.jpg",
      "isAvailable": true,
      "discountExpiration": null,
      "itemType": "menuItem",
      "hasDiscount": false,
      "description": "PARA PEDIDOS CON SABORES COMBINADOS, SE APLICA UN COSTO ADICIONAL DE 10 PESOS.",
      "otherCategory": "",
      "stockQuantity": 0,
      "discountPercentage": 0,
      "createdAt": {
        "_seconds": 1698329606,
        "_nanoseconds": 0
      },
      "uid": "item2",
      "companyId": "company1",
      "price": 279,
      "name": "20 Alitas",
      "category": "main-courses",
      "isFeatured": false,
      "updatedAt": {
        "_seconds": 1698329606,
        "_nanoseconds": 0
      }
    },
    {
      "id": "item3",
      "image": "/avatars/avatar3.jpg",
      "isAvailable": true,
      "discountExpiration": null,
      "itemType": "menuItem",
      "hasDiscount": false,
      "description": "300G DE PAPAS FRANCESAS O GAJO CON CHILI AMERICANO HECHO EN CASA",
      "otherCategory": "",
      "stockQuantity": 0,
      "discountPercentage": 0,
      "createdAt": {
        "_seconds": 1698330039,
        "_nanoseconds": 0
      },
      "uid": "item3",
      "companyId": "company2",
      "price": 119,
      "name": "Chili Cheese Fries",
      "category": "main-courses",
      "isFeatured": false,
      "updatedAt": {
        "_seconds": 1698330039,
        "_nanoseconds": 0
      }
    },
    {
      "id": "item4",
      "image": "/avatars/avatar4.jpg",
      "isAvailable": true,
      "discountExpiration": null,
      "itemType": "menuItem",
      "hasDiscount": false,
      "description": "ORDEN DE 6 DEDOS DE QUESO MOZZARELLA",
      "otherCategory": "",
      "stockQuantity": 0,
      "discountPercentage": 0,
      "createdAt": {
        "_seconds": 1698329841,
        "_nanoseconds": 0
      },
      "uid": "item4",
      "companyId": "company2",
      "price": 99,
      "name": "Dedos De Queso",
      "category": "appetizers",
      "isFeatured": false,
      "updatedAt": {
        "_seconds": 1698329841,
        "_nanoseconds": 0
      }
    },
    {
      "id": "item5",
      "image": "/avatars/avatar5.jpg",
      "isAvailable": true,
      "discountExpiration": null,
      "itemType": "menuItem",
      "hasDiscount": false,
      "description": "PARA PEDIDOS CON SABORES COMBINADOS, SE APLICA UN COSTO ADICIONAL DE 10 PESOS.",
      "otherCategory": "",
      "stockQuantity": 0,
      "discountPercentage": 0,
      "createdAt": {
        "_seconds": 1698329688,
        "_nanoseconds": 0
      },
      "uid": "item5",
      "companyId": "company3",
      "price": 279,
      "name": "20 Boneless",
      "category": "main-courses",
      "isFeatured": false,
      "updatedAt": {
        "_seconds": 1698329688,
        "_nanoseconds": 0
      }
    },
    {
      "id": "item6",
      "image": "/avatars/avatar6.jpg",
      "isAvailable": true,
      "discountExpiration": null,
      "itemType": "menuItem",
      "hasDiscount": false,
      "description": "300G DE PAPAS GAJO",
      "otherCategory": "",
      "stockQuantity": 0,
      "discountPercentage": 0,
      "createdAt": {
        "_seconds": 1698329911,
        "_nanoseconds": 0
      },
      "uid": "item6",
      "companyId": "company3",
      "price": 69,
      "name": "Papas Gajo",
      "category": "sides",
      "isFeatured": false,
      "updatedAt": {
        "_seconds": 1698329911,
        "_nanoseconds": 0
      }
    },
    {
      "id": "item7",
      "image": "/avatars/avatar7.jpg",
      "isAvailable": true,
      "discountExpiration": null,
      "itemType": "menuItem",
      "hasDiscount": false,
      "description": "SPICY CHICKEN SANDWICH ESTILO NASHVILLE",
      "otherCategory": "",
      "stockQuantity": 0,
      "discountPercentage": 0,
      "createdAt": {
        "_seconds": 1698329863,
        "_nanoseconds": 0
      },
      "uid": "item7",
      "companyId": "company4",
      "price": 155,
      "name": "Spicy Chicken Sandwich",
      "category": "sandwiches",
      "isFeatured": false,
      "updatedAt": {
        "_seconds": 1698329863,
        "_nanoseconds": 0
      }
    }
  ]