import React from 'react';
import { Modal, Box, Typography, ImageList, ImageListItem, Paper, Avatar, Button } from '@mui/material';

const ImageSelectionModal = ({ open, onClose, onSelect, title, images }) => {
  console.log('ImageSelectionModal rendered');
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="image-modal-title"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography id="image-modal-title" variant="h6" component="h2" gutterBottom>
          {title}
        </Typography>
        {images.length > 0 ? (
          <ImageList sx={{ width: '100%', height: 450 }} cols={3} rowHeight={164}>
            {images.map((image) => (
              <ImageListItem key={image.uid}>
                <Paper
                  elevation={2}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 1,
                  }}
                >
                  <Avatar
                    src={image.imageUrl}
                    alt={image.name}
                    variant="square"
                    sx={{ width: '100%', height: 100, mb: 1 }}
                  />
                  <Typography variant="caption" noWrap>{image.name}</Typography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => onSelect(image.imageUrl)}
                  >
                    Select
                  </Button>
                </Paper>
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <Typography>No images available. Please upload images to your gallery first.</Typography>
        )}
        <Button onClick={onClose} sx={{ mt: 2 }}>Close</Button>
      </Box>
    </Modal>
  );
};

export default React.memo(ImageSelectionModal);
