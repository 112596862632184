import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Button, Modal } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const ReceivePoints = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const qrData = JSON.stringify({
    userId: user.uid,
    name: user.displayName
  });

  return (
    <Box sx={{ textAlign: 'center', mt: 2 }}>
      <Button variant="contained" onClick={handleOpen}>
        {t('showReceivePointsQR')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="qr-code-modal"
        aria-describedby="qr-code-for-receiving-points"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}>
          <Typography variant="h6" gutterBottom color="#333333">
            {t('receivePoints')}
          </Typography>
          <QRCodeSVG value={qrData} size={200} />
          <Typography variant="body2" mt={1} color="#333333">
            {t('showQRToReceivePoints')}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default ReceivePoints;
