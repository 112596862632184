import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Box, Typography, Dialog, Link } from '@mui/material';
import QrScanner from 'react-qr-scanner';
import { useSelector } from 'react-redux';
import { addPointsToUser } from '../../../api/pointsApi';
import { useAuth } from '../../../hooks/useAuth';

const AddPoints = () => {
  const { t } = useTranslation();
  const [customerData, setCustomerData] = useState(null);
  const [receiptTotal, setReceiptTotal] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isScanning, setIsScanning] = useState(false);
  const [facingMode, setFacingMode] = useState('environment');
  const company = useSelector((state) => state.auth.user);
  const { idToken } = useAuth();
  const qrScannerRef = useRef(null);

  const handleScan = (data) => {
    if (data) {
      try {
        const parsedData = JSON.parse(data.text);
        setCustomerData(parsedData);
        setIsScanning(false);
      } catch (err) {
        console.error('Error parsing QR code data:', err);
        setError(t('errorScanningQR'));
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError(t('errorScanningQR'));
  };

  const handleAddPoints = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    // let devCustomerData;

    if (!customerData) {
    //* Add Back for production
        setError(t('pleaseScamCustomerQR'));
       return;

    //! Remove for production
    // devCustomerData = {
    //     userId: 'user1',
    //     name: 'User One'
    //     };
    }

    try {
      // await addPointsToUser(devCustomerData.userId, company.companyId, parseFloat(receiptTotal), idToken);
      await addPointsToUser(customerData?.userId, company?.companyId, parseFloat(receiptTotal), idToken);
      setSuccess(t('pointsAddedSuccessfully'));
      setReceiptTotal('');
      setCustomerData(null);
    } catch (err) {
      console.error('Error adding points:', err);
      setError(t('errorAddingPoints'));
    }
  };

  const toggleCamera = () => {
    setFacingMode(prevMode => prevMode === 'environment' ? 'user' : 'environment');
    if (qrScannerRef.current) {
      qrScannerRef.current.openImageDialog();
    }
  };

  return (
    <Box component="form" onSubmit={handleAddPoints}>
      {customerData ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t('customerInfo')}:{' '}
          <Link
            component="button"
            variant="body1"
            onClick={() => setIsScanning(true)}
          >
            {customerData.name}
          </Link>
        </Typography>
      ) : (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => setIsScanning(true)}
          sx={{ mb: 2 }}
        >
          {t('scanCustomerQR')}
        </Button>
      )}
      <TextField
        label={t('receiptTotal')}
        type="number"
        value={receiptTotal}
        onChange={(e) => setReceiptTotal(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      <Button type="submit" variant="contained" color="primary">
        {t('addPoints')}
      </Button>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="success.main" sx={{ mt: 2 }}>
          {success}
        </Typography>
      )}
      <Dialog open={isScanning} onClose={() => setIsScanning(false)}>
        <Box sx={{ width: '300px', height: '300px', position: 'relative' }}>
          <QrScanner
            ref={qrScannerRef}
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
            constraints={{
              video: { facingMode: facingMode }
            }}
          />
          <Button
            onClick={toggleCamera}
            sx={{
              position: 'absolute',
              bottom: 10,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1,
            }}
          >
            {t('switchCamera')}
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AddPoints;
