import apiCall from './apiCall';

const API_ENDPOINT = '/rewardApi';

export const getCompanyRewards = async (companyId, idToken) => {
  const response = await apiCall('get', `${API_ENDPOINT}/list?companyId=${companyId}`, null, false, idToken);
  return response;
};

export const createReward = async (companyId, rewardData, imageFile, idToken) => {
  const formData = new FormData();
  Object.keys(rewardData).forEach(key => {
    formData.append(key, rewardData[key]);
  });
  if (imageFile) {
    formData.append('image', imageFile);
  }
  formData.append('companyId', companyId);
  const response = await apiCall('post', `${API_ENDPOINT}/${companyId}`, formData, true, idToken);
  return response;
};

export const updateReward = async (companyId, rewardId, rewardData, imageFile, idToken) => {
  const formData = new FormData();
  Object.keys(rewardData).forEach(key => {
    formData.append(key, rewardData[key]);
  });
  if (imageFile) {
    formData.append('image', imageFile);
  }
  formData.append('companyId', companyId);
  const response = await apiCall('put', `${API_ENDPOINT}/${companyId}/${rewardId}`, formData, true, idToken);
  return response;
};

export const deleteReward = async (companyId, rewardId, idToken) => {
  const body = { delete: true };
  const response = await apiCall('delete', `${API_ENDPOINT}/${rewardId}/${companyId}`, body, false, idToken);
  return response;
};

export const getRewardsList = async (companies, idToken) => {
  const response = await apiCall('post', `${API_ENDPOINT}/lists`, { companies }, false, idToken);
  return response;
};

export const getRewardsListFromSingleCompany = async (companyId, idToken) => {
  const response = await apiCall('get', `${API_ENDPOINT}/list/${companyId}`, null, false, idToken);
  return response;
};
