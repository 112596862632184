import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PointsTileSlider from './PointsTileSlider';

const PointsOverview = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  // console.log({user});

  // check is mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const points = useMemo(() => {
    return user?.points;
  }, [user.points, user.optedInCompanies]);
  console.log({points});
  return (
    <Paper 
      elevation={3} 
      sx={{ 
        padding: { xs: 1, sm: 2 }, 
        backgroundColor: '#adcce9',
        borderRadius: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 16px)', 
        margin: 1,
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
        {t('pointsOverview')}
      </Typography>
      <Box 
        sx={{ 
          backgroundColor: '#adcce9',
          borderRadius: 1, 
          padding: { xs: 1, sm: 2 },
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <PointsTileSlider points={points || []} isMobile={isMobile}/>
      </Box>
    </Paper>
  );
};

export default PointsOverview;
