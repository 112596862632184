import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Dialog } from '@mui/material';
import { getRewardsListFromSingleCompany, createReward, updateReward, deleteReward } from '../../api/rewardsApi';
import RewardList from './rewardsComponents/RewardList';
import RewardForm from './rewardsComponents/RewardForm';
import ConfirmDelete from './rewardsComponents/ConfirmDelete';
import { useAuth } from '../../hooks/useAuth';

const RewardsManagement = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const [rewards, setRewards] = useState([]);
  const [editingReward, setEditingReward] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [rewardToDelete, setRewardToDelete] = useState(null);
  const [newReward, setNewReward] = useState({
    name: '',
    description: '',
    pointsCost: '',
    image: '',
    isAvailable: true,
  });
  const [imageFile, setImageFile] = useState(null);
  const { idToken } = useAuth();
  useEffect(() => {
    idToken && fetchRewards();
  }, [idToken]);

  const fetchRewards = async () => {
    try {
      const fetchedRewards = await getRewardsListFromSingleCompany(user.companyId, idToken);
      setRewards(fetchedRewards);
    } catch (error) {
      console.error('Error fetching rewards:', error);
    }
  };

  const handleEdit = (uid) => {
    const rewardToEdit = rewards.find(reward => reward.uid === uid);
    setEditingReward(rewardToEdit);
    setImageFile(null);
    setOpenDialog(true);
  };

  const handleDeleteClick = (uid) => {
    const rewardToDelete = rewards.find(reward => reward.uid === uid);
    setRewardToDelete(rewardToDelete);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteReward(user.companyId, rewardToDelete.uid, idToken);
      setOpenDeleteDialog(false);
      setRewardToDelete(null);
      fetchRewards();
    } catch (error) {
      console.error('Error deleting reward:', error);
    }
  };

  const handleChange = (field, value) => {
    if (editingReward) {
      setEditingReward({ ...editingReward, [field]: value });
    } else {
      setNewReward({ ...newReward, [field]: value });
    }
  };

  const handleImageChange = (file) => {
    setImageFile(file);
  };

  const handleSubmit = async () => {
    try {
      const rewardData = editingReward || newReward;
      
      if (editingReward) {
        await updateReward(user.companyId, editingReward.uid, rewardData, imageFile, idToken);
      } else {
        await createReward(user.companyId, rewardData, imageFile, idToken);
      }
      handleCloseDialog();
      fetchRewards();
    } catch (error) {
      console.error('Error saving reward:', error);
    }
  };

  const handleOpenDialog = () => {
    setEditingReward(null);
    setNewReward({
      name: '',
      description: '',
      pointsCost: '',
      image: '',
      isAvailable: true,
    });
    setImageFile(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEditingReward(null);
    setNewReward({
      name: '',
      description: '',
      pointsCost: '',
      image: '',
      isAvailable: true,
    });
    setImageFile(null);
    setOpenDialog(false);
  };
  console.log({rewards});
  return (
    <div className="rewards-management">
      <h2>{t('rewardsManagement')}</h2>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        {t('createNewReward')}
      </Button>
      {!rewards?.length ? (
        <p>{t('noRewardsFound')}</p>
      ) : (
        <RewardList
          rewards={rewards}
          onEdit={handleEdit}
          onDelete={handleDeleteClick}
        />
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <RewardForm
          reward={editingReward || newReward}
          onChange={handleChange}
          onImageChange={handleImageChange}
          onSubmit={handleSubmit}
          onClose={handleCloseDialog}
          isNew={!editingReward}
        />
      </Dialog>
      <ConfirmDelete
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteConfirm}
        itemName={rewardToDelete?.name || ''}
      />
    </div>
  );
};

export default RewardsManagement;