import { useTranslation } from 'react-i18next';

const MainFooter = () => {
  const { t } = useTranslation();


  return (
    <footer className="main-footer">
      <div className="container">
        <p>&copy; 2024 Hyperlink.it {t('allRightsReserved')}</p>
        <nav>
          <ul>
            <li><a href="/privacy">{t('privacyPolicy')}</a></li>
            <li><a href="/terms">{t('termsOfService')}</a></li>
            <li><a href="/contact">{t('contactUs')}</a></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default MainFooter;
