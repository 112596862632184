import apiCall from './apiCall';

const API_ENDPOINT = '/transactionApi'; 

export const getTransactions = async (userId, companyId = null, idToken = null) => {
  let endpoint = `${API_ENDPOINT}/?userId=${userId}`;
  if (companyId) {
    endpoint += `&companyId=${companyId}`;
  }
  const response = await apiCall('get', endpoint, null, false, idToken);
  return response;
};

// Add more transaction-related API calls here as needed
export const createTransaction = async (transactionData, idToken) => {
  try {
    const response = await apiCall('post', `${API_ENDPOINT}`, transactionData, false, idToken);
    return response;
  } catch (error) {
    console.error('Error creating transaction:', error);
    throw error;
  }
};

export const updateTransaction = async (transactionId, updateData, idToken) => {
  try {
    const response = await apiCall('put', `${API_ENDPOINT}/${transactionId}`, updateData, false, idToken);
    return response;
  } catch (error) {
    console.error('Error updating transaction:', error);
    throw error;
  }
};

export const deleteTransaction = async (transactionId, idToken) => {
  try {
    const response = await apiCall('delete', `${API_ENDPOINT}/${transactionId}`, null, false, idToken);
    return response;
  } catch (error) {
    console.error('Error deleting transaction:', error);
    throw error;
  }
};
