import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: black;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
`;

const PoweredBy = styled.div`
  font-size: 0.9rem;
  color: #666;
`;

const PublicPageHeader = () => {
  return (
    <HeaderContainer>
      <Logo to="/">Hyperlink.it</Logo>
      <PoweredBy>Powered by Hyperlink.it</PoweredBy>
    </HeaderContainer>
  );
};

export default PublicPageHeader;
