import React from 'react';
import { createCheckoutSession } from '../../api/stripeApi';
import { Button } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';

const StripeCheckout = ({ lookupKey, buttonText, user }) => {
  const { idToken } = useAuth();

  const handleCheckout = async () => {
    try {
      const { url } = await createCheckoutSession(lookupKey, idToken, user);
      window.location.href = url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <Button 
      variant="contained" 
      color="primary" 
      fullWidth 
      onClick={handleCheckout}
    >
      {buttonText || 'Start trial'}
    </Button>
  );
};

export default StripeCheckout;

