import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

const MenuDisplay = ({ settings, menuItems }) => {
  return (
    <Box sx={{ mb: 4 }} className="isEditableComponent-menuSection">
      <Typography 
        variant="h5" 
        sx={{ 
          mb: 2, 
          fontWeight: 'bold',
          fontSize: `${settings.itemsTitleFontSize}px`,
          color: settings.itemsTitleColor,
        }} 
        className="isEditableComponent-menuTitle"
      >
        Our Menu
      </Typography>
      <Grid container spacing={2} className="isEditableComponent-menuGrid">
        {menuItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={`${index}-${item.name}`}>
            <Card sx={{ bgcolor: settings.cardColor }}>
            <CardMedia
                component="img"
                height="140"
                image={item.image}
                alt={item.name}
              />
              <CardContent sx={{ color: settings.cardTextColor }}>
                <Typography variant="h6">{item.name}</Typography>
                <Typography>{item.description}</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>${item.price}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default React.memo(MenuDisplay);

