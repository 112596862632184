import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField, Button, Modal } from '@mui/material';
import { createUserPin, updateUserPin } from '../../api/userApi';
import { useAuth } from '../../hooks/useAuth';

const CreateUpdatePin = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const { idToken } = useAuth();
  const [open, setOpen] = useState(false);
  const [pin, setPin] = useState('');
  const [oldPin, setOldPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [error, setError] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPin('');
    setOldPin('');
    setNewPin('');
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (user.hasPin) {
        // Update PIN
        await updateUserPin(user.uid, oldPin, newPin, idToken);
      } else {
        // Create PIN
        await createUserPin(user.uid, pin, idToken);
      }
      handleClose();
    } catch (err) {
      setError(t('errorUpdatingPin'));
      console.error('Error updating PIN:', err);
    }
  };

  return (
    <Box sx={{ textAlign: 'center', mt: 2 }}>
      <Button variant="contained" onClick={handleOpen}>
        {user.hasPin ? t('updatePin') : t('createPin')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="pin-modal"
        aria-describedby="modal-for-creating-or-updating-pin"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}>
          <Typography variant="h6" gutterBottom>
            {user.hasPin ? t('updatePin') : t('createPin')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            {user.hasPin ? (
              <>
                <TextField
                  label={t('oldPin')}
                  type="password"
                  value={oldPin}
                  onChange={(e) => setOldPin(e.target.value)}
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 4, pattern: '[0-9]*' }}
                  required
                />
                <TextField
                  label={t('newPin')}
                  type="password"
                  value={newPin}
                  onChange={(e) => setNewPin(e.target.value)}
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 4, pattern: '[0-9]*' }}
                  required
                />
              </>
            ) : (
              <TextField
                label={t('pin')}
                type="password"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 4, pattern: '[0-9]*' }}
                required
              />
            )}
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              {user.hasPin ? t('updatePin') : t('createPin')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CreateUpdatePin;
