import React, { useEffect } from 'react';
import styled from 'styled-components';
import { setSelectedElement } from '../../../../redux/sectionSlice';
import { useDispatch, useSelector } from 'react-redux';

const HeaderContainer = styled.header`
  // if the section has a background image, use it, otherwise use the default
  background-image: ${props => props.sectionData?.bgImage ? `url(${props.sectionData.bgImage})` : ``};
  background-size: ${props => props.sectionData?.bgSize ? props.sectionData.bgSize : 'cover'};
  background-position: ${props => props.sectionData?.bgPosition ? props.sectionData.bgPosition : 'center'};
  background-repeat: ${props => props.sectionData?.bgRepeat ? props.sectionData.bgRepeat : 'no-repeat'}; 
  background-color: ${props => props.sectionData?.bgColor ? props.sectionData.bgColor : '#ffffff'};
  height: ${props => props.sectionData?.height ? props.sectionData.height : '100vh'};
  width: ${props => props.sectionData?.width ? props.sectionData.width : '100%'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Heading1 = styled.h1`
  font-family: ${props => props.sectionData?.heading1Font ? props.sectionData.heading1Font : 'sans-serif'};
  color: ${props => props.sectionData?.heading1TextColor ? props.sectionData.heading1TextColor : '#000000'};
  font-size: ${props => props.sectionData?.heading1FontSize ? `${props.sectionData.heading1FontSize}px` : '4rem'};
  margin-bottom: ${props => props.sectionData?.heading1MarginBottom ? `${props.sectionData.heading1MarginBottom}` : '1rem'};
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
`;

const ElementWrapper = styled.div`
  cursor: pointer;
  &:hover {
    outline: 2px solid #007bff;
  }
  outline: ${props => props.selected ? '2px solid #007bff' : 'none'};
  font-family: ${props => props.font}, sans-serif;
  color: ${props => props.textColor};
  background-color: ${props => props.bgColor};
`;

const Header = ({ sectionData }) => {
  const dispatch = useDispatch();
  const selectedElement = useSelector(state => state.section.selectedElement);

  const handleElementSelect = (elementID) => {
    console.log({elementID});
    const uniqueID = `${sectionData.id}-${elementID}`;
    console.log({uniqueID});
    dispatch(setSelectedElement(uniqueID));
  };
  useEffect(() => {
    console.log({selectedElement});
  }, [selectedElement]);
  console.log('_____________');
  console.log({sectionData});
  return (
    <HeaderContainer sectionData={sectionData}>
      <ElementWrapper 
        onClick={() => {handleElementSelect('Heading1')}}
        selected={selectedElement === `${sectionData.id}-Heading1`}
      >
        <Heading1 sectionData={sectionData}>{sectionData?.heading1Text}</Heading1>
      </ElementWrapper>
      <ElementWrapper 
        onClick={() => {handleElementSelect('Paragraph')}}
        selected={selectedElement === `${sectionData.id}-Paragraph`}
      >
        <Paragraph sectionData={sectionData}>{sectionData?.paragraphText}</Paragraph>
      </ElementWrapper>
    </HeaderContainer>
  );
};

export default Header;