import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemText, Select, MenuItem, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { getNearbyBusinesses } from '../../api/companyApi';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import ViewOrJoin from './nearbyBusinessesComponents/ViewOrJoin';
import { useAuth } from '../../hooks/useAuth';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { googleMapsConfig } from '../../utils/googleMapsConfig';

const containerStyle = {
  width: '100%',
  height: '300px'
};

const mapOptions = {
  gestureHandling: 'greedy'
};

const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds

const NearbyBusinesses = ({ user }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [businesses, setBusinesses] = useState([]);
  const [distance, setDistance] = useState(5);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 20.0176, lng: -104.0719 });
  const { idToken } = useAuth();
  const [showMap, setShowMap] = useState(false);
  const { isLoaded } = useJsApiLoader(googleMapsConfig);
  const [locationPermission, setLocationPermission] = useState('prompt');
  const [locationError, setLocationError] = useState(null);

  useEffect(() => {
    if (isMobile) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        setLocationPermission(result.state);
        result.onchange = () => {
          setLocationPermission(result.state);
        };
      });
    }
  }, [isMobile]);

  const onMapClick = useCallback((e) => {
    setSelectedLocation({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
  }, []);

  const fetchNearbyBusinesses = useCallback(async () => {
    if (selectedLocation) {
      setLoading(true);
      setError(null);
      const cacheKey = 'nearbyBusinesses';
      const cachedData = localStorage.getItem(cacheKey);
      
      if (cachedData) {
        const { data, timestamp, lastQuery } = JSON.parse(cachedData);
        const isSameQuery = lastQuery && 
          lastQuery.latitude === selectedLocation.latitude &&
          lastQuery.longitude === selectedLocation.longitude &&
          lastQuery.distance === distance;

        if (isSameQuery && Date.now() - timestamp < CACHE_DURATION) {
          setBusinesses(data);
          setLoading(false);
          return;
        }
      }

      try {
        const data = await getNearbyBusinesses(selectedLocation.latitude, selectedLocation.longitude, distance, idToken);
        setBusinesses(data);
        localStorage.setItem(cacheKey, JSON.stringify({
          data,
          timestamp: Date.now(),
          lastQuery: {
            latitude: selectedLocation.latitude,
            longitude: selectedLocation.longitude,
            distance
          }
        }));
      } catch (error) {
        console.error('Error fetching nearby businesses:', error);
        setError(t('errorFetchingBusinesses'));
      } finally {
        setLoading(false);
      }
    }
  }, [selectedLocation, distance, idToken, t]);

  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
  };

  const requestLocationAccess = () => {
    if (!navigator.geolocation) {
      setLocationError(t('geolocationNotSupported'));
      return;
    }

    setLocationError(null);
    setLoading(true);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setSelectedLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
        console.log('Location granted');
        console.log({position});
        setLocationPermission('granted');
        setLoading(false);
      },
      (err) => {
        console.error("Error getting location: ", err);
        setLocationPermission('denied');
        setLoading(false);
        switch(err.code) {
          case err.PERMISSION_DENIED:
            setLocationError(t('locationPermissionDenied'));
            break;
          case err.POSITION_UNAVAILABLE:
            setLocationError(t('locationUnavailable'));
            break;
          case err.TIMEOUT:
            setLocationError(t('locationTimeout'));
            break;
          default:
            setLocationError(t('locationError'));
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  };

  const handleSearch = () => {
    if (isMobile) {
      if (locationPermission === 'granted' && selectedLocation) {
        fetchNearbyBusinesses();
      } else {
        requestLocationAccess();
      }
    } else {
      fetchNearbyBusinesses();
    }
  };

  const isMetric = !['en-US', 'en-GB'].includes(i18n.language);

  const formatAddress = (address) => {
    if (typeof address === 'string') return address;
    if (typeof address === 'object') {
      return `${address.street || ''}, ${address.city || ''}, ${address.state || ''}, ${address.country || ''}`.trim();
    }
    return t('addressNotAvailable');
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        padding: { xs: 1, sm: 2 }, 
        backgroundColor: '#adcce9',
        borderRadius: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 20rem)',
        margin: 1,
        overflowY: 'auto',
        '@media (max-width: 600px)': {
          maxHeight: 'calc(100vh - 10rem)',
        },
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
        {t('nearbyBusinesses')}
      </Typography>
      {isMobile && (locationPermission !== 'granted' || locationError) && (
        <Box sx={{ mb: 2 }}>
          <Button
            onClick={requestLocationAccess}
            variant="contained"
            color="primary"
            sx={{ mb: 1 }}
          >
            {t('allowLocationAccess')}
          </Button>
          {locationError && (
            <Typography color="error" variant="body2">
              {locationError}
            </Typography>
          )}
        </Box>
      )}
      {!isMobile && (
        <>
          {!showMap ? (
            <Button
              onClick={() => setShowMap(true)}
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              sx={{ mb: 2 }}
            >
              {t('showMap')}
            </Button>
          ) : isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={10}
              onClick={onMapClick}
              options={mapOptions}
            >
              {selectedLocation && (
                <Marker
                  position={{ lat: selectedLocation.latitude, lng: selectedLocation.longitude }}
                />
              )}
            </GoogleMap>
          ) : (
            <Box sx={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>{t('loadingMap')}</Typography>
            </Box>
          )}
        </>
      )}
      <Box sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Select
          value={distance}
          onChange={handleDistanceChange}
          sx={{ backgroundColor: '#adcce9', color: '#333' }}
        >
          {[1, 5, 10, 25, 50].map((value) => (
            <MenuItem key={value} value={value}>
              {`${value} ${isMetric ? t('km') : t('miles')}`}
            </MenuItem>
          ))}
        </Select>
        <Button 
          onClick={handleSearch} 
          variant="contained" 
          color="primary"
          disabled={isMobile ? locationPermission !== 'granted' : !selectedLocation}
        >
          {t('search')}
        </Button>
      </Box>
      <Box 
        className="scrollbar-style"
        sx={{ 
          backgroundColor: '#adcce9',
          borderRadius: 1, 
          padding: { xs: 1, sm: 2 },
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          flexGrow: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {loading && <Typography color="#333">{t('loading')}</Typography>}
        {error && <Typography color="#ff6b6b">{error}</Typography>}
        {!loading && !error && (
          <List sx={{ flexGrow: 1, overflow: 'auto', width: '100%' }}>
            {businesses?.map((business) => (
              <ListItem 
                key={business.uid} 
                divider
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Box sx={{ flexGrow: 1, mr: 2 }}>
                  <ListItemText
                    primary={<Typography color="#333">{business.companyName}</Typography>}
                    secondary={
                      <Typography color="#333">
                        {`${business.distance.toFixed(1)} ${isMetric ? t('km') : t('miles')} - ${formatAddress(business.address)}`}
                      </Typography>
                    }
                  />
                </Box>
                <ViewOrJoin business={business} />
              </ListItem>
            ))}
            {businesses?.length === 0 && (
              <Typography color="#333">{t('noNearbyBusinesses')}</Typography>
            )}
          </List>
        )}
      </Box>
    </Paper>
  );
};

export default NearbyBusinesses;
