import apiCall from './apiCall';

const API_ENDPOINT = '/companyApi';

export const registerCompany = async (companyData, idToken) => {
  const response = await apiCall('post', `${API_ENDPOINT}/register`, companyData, false, idToken);
  return response; // Assuming the backend returns user data
};

export const updateCompanyProfile = async (companyId, companyData, avatarFile, idToken) => {
  const formData = new FormData();
  Object.keys(companyData).forEach(key => {
    if (key === 'address') {
      formData.append('address', JSON.stringify(companyData.address));
    } else if (key !== 'avatar') {
      formData.append(key, companyData[key]);
    }
  });
  if (avatarFile) {
    formData.delete('avatar');
    formData.append('avatar', avatarFile);
  }
  const response = await apiCall('put', `${API_ENDPOINT}/${companyId}`, formData, true, idToken);
  return response;
};

// Add this new function for fetching nearby businesses
export const getNearbyBusinesses = async (latitude, longitude, distance, idToken) => {
  const response = await apiCall('get', `${API_ENDPOINT}/nearby-businesses?lat=${latitude}&lon=${longitude}&distance=${distance}`, null, false, idToken);
  return response;
};

export const getAllCompanyItems = async (companyId, idToken) => {
    const response = await apiCall('get', `${API_ENDPOINT}/admin/company/${companyId}/items`, null, false, idToken);
    return response;
};

export const uploadGalleryImage = async (companyId, imageFile, imageName, idToken) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  formData.append('name', imageName);
  const response = await apiCall('post', `${API_ENDPOINT}/gallery/${companyId}`, formData, true, idToken);
  return response;
};

export const deleteGalleryImage = async (companyId, imageId, idToken) => {
  const response = await apiCall('delete', `${API_ENDPOINT}/gallery/${companyId}/${imageId}`, null, false, idToken);
  return response;
};

export const getCompanyGallery = async (companyId, idToken) => {
  const response = await apiCall('get', `${API_ENDPOINT}/gallery/${companyId}`, null, false, idToken);
  return response;
};

// Add this new function at the end of the file
export const getCompanyDetails = async (companyId, idToken) => {
  const response = await apiCall('get', `${API_ENDPOINT}/${companyId}`, null, false, idToken);
  return response;
};
