import React from 'react';
import { Box, Typography, Card, CardContent, Stack, Grid, CardMedia } from '@mui/material';
import ViewiFrame from './templates/template1/template1Components/ViewiFrame';

export const Header = React.memo(({ headerSettings, logoSettings, businessNameSettings, descriptionSettings }) => {
  console.log('Header rendered with settings:', headerSettings);
  return (
    <Box sx={{ 
      position: 'relative',
      overflow: 'hidden',
      color: 'white', 
      bgcolor: headerSettings.headerBackgroundColor,
      height: `${headerSettings.headerHeight}px`,
    }} className="isEditableComponent-header">
      {headerSettings.heroImage && (
        <Box
          sx={{
            position: 'absolute',
            top: '-5px',
            left: '-5px',
            right: '-5px',
            bottom: '-5px',
            backgroundImage: `url(${headerSettings.heroImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: `blur(${headerSettings.heroImageBlur}px)`,
            zIndex: 0,
          }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 1,
        }}
      />
      <Box 
        sx={{ 
          mx: 'auto', 
          position: 'relative', 
          zIndex: 2,
          p: 4,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }} 
        className="isEditableComponent-headerContent"
      >
        <Grid container spacing={2} direction={logoSettings.logoOrder === 'left' ? 'row' : 'row-reverse'}>
          <Grid item xs={logoSettings.logoColumnWidth}>
            <Logo logoSettings={logoSettings} />
          </Grid>
          <Grid item xs={12 - logoSettings.logoColumnWidth}>
            <Box>
              <BusinessName businessNameSettings={businessNameSettings} />
              <BusinessDescription descriptionSettings={descriptionSettings} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

const Logo = React.memo(({ logoSettings }) => {
  console.log('Logo rendered with settings:', logoSettings);
  if (!logoSettings.logoUrl) return null;
  
  const getPositionStyle = () => {
    switch (logoSettings.logoPosition) {
      case 'top-left':
        return { bottom: 0, left: 0 };
      case 'top-center':
        return { bottom: 0, left: '50%', transform: 'translateX(-50%)' };
      case 'top-right':
        return { bottom: 0, right: 0 };
      case 'center-left':
        return { top: '50%', left: 0, transform: 'translateY(-50%)' };
      case 'center':
        return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
      case 'center-right':
        return { top: '50%', right: 0, transform: 'translateY(-50%)' };
      case 'bottom-left':
        return { top: 0, left: 0 };
      case 'bottom-center':
        return { top: 0, left: '50%', transform: 'translateX(-50%)' };
      case 'bottom-right':
        return { top: 0, right: 0 };
      default:
        return {};
    }
  };

  return (
    <Box sx={{ 
      position: 'relative',
      height: '100%',
      width: '100%',
    }}>
      <Box
        sx={{
          position: 'absolute',
          ...getPositionStyle(),
        }}
      >
        <img 
          src={logoSettings.logoUrl} 
          alt="Logo" 
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: `${logoSettings.logoWidth * logoSettings.logoScale}px`,
            height: `${logoSettings.logoHeight * logoSettings.logoScale}px`,
            objectFit: 'contain',
            transform: `scale(${logoSettings.logoScale})`,
            transformOrigin: 'center center',
          }}
          className="isEditableComponent-logo"
        />
      </Box>
    </Box>
  );
});

export const BusinessName = React.memo(({ businessNameSettings }) => {
  console.log('BusinessName rendered');
  return (
    <Typography 
      variant="h4" 
      sx={{ 
        mb: 1, 
        fontWeight: businessNameSettings.businessNameFontWeight,
        fontSize: `${businessNameSettings.businessNameFontSize}px`,
        color: businessNameSettings.businessNameColor,
        textShadow: businessNameSettings.businessNameTextShadow,
        letterSpacing: businessNameSettings.businessNameLetterSpacing,
        textTransform: businessNameSettings.businessNameTextTransform,
        textAlign: businessNameSettings.businessNameAlignment,
      }} 
      className="isEditableComponent-businessName"
    >
      {businessNameSettings.businessName || 'Your Business Name'}
    </Typography>
  );
});

export const BusinessDescription = React.memo(({ descriptionSettings }) => {
  console.log('BusinessDescription rendered');
  return (
    <Typography 
      variant="h6" 
      sx={{ 
        opacity: 0.9,
        fontSize: `${descriptionSettings.descriptionFontSize}px`,
        color: descriptionSettings.descriptionColor,
        fontWeight: descriptionSettings.descriptionFontWeight,
        textShadow: descriptionSettings.descriptionTextShadow,
        letterSpacing: descriptionSettings.descriptionLetterSpacing,
        lineHeight: descriptionSettings.descriptionLineHeight,
        textAlign: descriptionSettings.descriptionAlignment,
      }} 
      className="isEditableComponent-description"
    >
      {descriptionSettings.description || 'Your business description'}
    </Typography>
  );
});

export const ProductsSection = React.memo(({ settings, products }) => {
  console.log('ProductsSection rendered');
  return (
    <Box sx={{ mb: 4 }} className="isEditableComponent-productsSection">
      <Typography 
        variant="h5" 
        sx={{ 
          mb: 2, 
          fontWeight: 'bold',
          fontSize: `${settings.productsTitleFontSize}px`,
          color: settings.productsTitleColor,
        }} 
        className="isEditableComponent-productsTitle"
      >
        Our Products
      </Typography>
      <Grid container spacing={2} className="isEditableComponent-productsGrid">
        {products.map(product => (
          <ProductItem key={product.id} product={product} cardColor={settings.cardColor} cardTextColor={settings.cardTextColor} />
        ))}
      </Grid>
    </Box>
  );
});

export const ProductItem = React.memo(({ product, cardColor, cardTextColor }) => {
  console.log(`ProductItem rendered: ${product.id}`);
  return (
    <Grid item xs={12} sm={6} md={4} className="isEditableComponent-productItem">
      <Card sx={{ bgcolor: cardColor }} className="isEditableComponent-productCard">
        <img 
          src={product.image}
          alt={product.name}
          style={{ width: '100%', height: '10rem', objectFit: 'cover' }}
          className="isEditableComponent-productImage"
        />
        <CardContent sx={{ color: cardTextColor }} className="isEditableComponent-productContent">
          <Typography variant="h6" className="isEditableComponent-productName">{product.name}</Typography>
          <Typography className="isEditableComponent-productPrice">${product.price}</Typography>
          <Typography variant="body2" sx={{ mt: 1 }} className="isEditableComponent-productDescription">{product.description}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
});

export const RewardsSection = React.memo(({ settings, rewards }) => {
  console.log('RewardsSection rendered');
  return (
    <Box className="isEditableComponent-rewardsSection">
      <Typography 
        variant="h5" 
        sx={{ 
          mb: 2, 
          fontWeight: 'bold',
          fontSize: `${settings.rewardsTitleFontSize}px`,
          color: settings.rewardsTitleColor,
        }} 
        className="isEditableComponent-rewardsTitle"
      >
        Rewards Program
      </Typography>
      <Stack spacing={2} className="isEditableComponent-rewardsList">
        {rewards.map(reward => (
          <RewardItem key={reward.uid} reward={reward} cardColor={settings.cardColor} cardTextColor={settings.cardTextColor} secondaryColor={settings.rewardsSecondaryColor} />
        ))}
      </Stack>
    </Box>
  );
});

export const RewardItem = React.memo(({ reward, cardColor, cardTextColor, secondaryColor }) => {
  console.log(`RewardItem rendered: ${reward.id}`);
  return (
    <Card sx={{ bgcolor: cardColor, borderLeft: `4px solid ${secondaryColor}` }} className="isEditableComponent-rewardItem">
      <Grid container>
        <Grid item xs={10}>
          <CardContent sx={{ color: cardTextColor }} className="isEditableComponent-rewardContent">
            <Typography variant="h6" className="isEditableComponent-rewardTitle">{reward.title}</Typography>
            <Typography className="isEditableComponent-rewardDescription">{reward.description}</Typography>
            <Typography variant="body2" sx={{ mt: 1, color: secondaryColor }} className="isEditableComponent-rewardPoints">
              {reward.pointsCost} points required
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={2}>
          <CardMedia
            component="img"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
            image={reward.image}
            alt={reward.name}
          />
        </Grid>
      </Grid>
    </Card>
  );
});

export const ViewiFrameOnly = React.memo(({ link }) => {
  return <ViewiFrame src={link} />
});