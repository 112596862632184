import apiCall from './apiCall';

const API_ENDPOINT = '/adminApi';           

export const createCompany = (companyData, idToken) => apiCall('post', `${API_ENDPOINT}/companies`, companyData, false, idToken);

export const getCompanies = (idToken) => apiCall('get', `${API_ENDPOINT}/companies`, null, false, idToken);

export const getCompanyMenuItems = (companyId, idToken) => apiCall('get', `${API_ENDPOINT}/companies/${companyId}/items`, null, false, idToken);

export const getCompanyProducts = (companyId, idToken) => apiCall('get', `${API_ENDPOINT}/companies/${companyId}/products`, null, false, idToken);

export const getCompanyRewards = (companyId, idToken) => apiCall('get', `${API_ENDPOINT}/companies/${companyId}/rewards`, null, false, idToken);

// Add other admin-related API calls here
