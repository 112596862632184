const menuCategories = [
    { value: 'appetizers', enName: 'Appetizers', esName: 'Entradas' },
    { value: 'soups', enName: 'Soups', esName: 'Sopas' },
    { value: 'salads', enName: 'Salads', esName: 'Ensaladas' },
    { value: 'main-courses', enName: 'Main Courses', esName: 'Platos Principales' },
    { value: 'seafood', enName: 'Seafood', esName: 'Mariscos' },
    { value: 'vegetarian', enName: 'Vegetarian', esName: 'Vegetariano' },
    { value: 'vegan', enName: 'Vegan', esName: 'Vegano' },
    { value: 'pasta', enName: 'Pasta', esName: 'Pasta' },
    { value: 'pizza', enName: 'Pizza', esName: 'Pizza' },
    { value: 'sandwiches', enName: 'Sandwiches', esName: 'Sándwiches' },
    { value: 'burgers', enName: 'Burgers', esName: 'Hamburguesas' },
    { value: 'sides', enName: 'Side Dishes', esName: 'Guarniciones' },
    { value: 'desserts', enName: 'Desserts', esName: 'Postres' },
    { value: 'beverages', enName: 'Beverages', esName: 'Bebidas' },
    { value: 'alcoholic-drinks', enName: 'Alcoholic Drinks', esName: 'Bebidas Alcohólicas' },
    { value: 'breakfast', enName: 'Breakfast', esName: 'Desayuno' },
    { value: 'lunch', enName: 'Lunch', esName: 'Almuerzo' },
    { value: 'dinner', enName: 'Dinner', esName: 'Cena' },
    { value: 'kids-menu', enName: 'Kids Menu', esName: 'Menú Infantil' },
    { value: 'specials', enName: 'Specials', esName: 'Especiales' },
];

const productCategories = [
    { value: 'produce', enName: 'Produce', esName: 'Productos Frescos' },
    { value: 'dairy', enName: 'Dairy', esName: 'Lácteos' },
    { value: 'bakery', enName: 'Bakery', esName: 'Panadería' },
    { value: 'meat', enName: 'Meat', esName: 'Carnes' },
    { value: 'seafood', enName: 'Seafood', esName: 'Mariscos' },
    { value: 'frozen-foods', enName: 'Frozen Foods', esName: 'Alimentos Congelados' },
    { value: 'canned-goods', enName: 'Canned Goods', esName: 'Productos Enlatados' },
    { value: 'dry-goods', enName: 'Dry Goods', esName: 'Productos Secos' },
    { value: 'beverages', enName: 'Beverages', esName: 'Bebidas' },
    { value: 'snacks', enName: 'Snacks', esName: 'Aperitivos' },
    { value: 'cereal', enName: 'Cereal', esName: 'Cereales' },
    { value: 'condiments', enName: 'Condiments', esName: 'Condimentos' },
    { value: 'spices', enName: 'Spices', esName: 'Especias' },
    { value: 'baking-supplies', enName: 'Baking Supplies', esName: 'Suministros para Hornear' },
    { value: 'alcoholic-beverages', enName: 'Alcoholic Beverages', esName: 'Bebidas Alcohólicas' },
    { value: 'health-foods', enName: 'Health Foods', esName: 'Alimentos Saludables' },
    { value: 'international-foods', enName: 'International Foods', esName: 'Alimentos Internacionales' },
    { value: 'pet-supplies', enName: 'Pet Supplies', esName: 'Suministros para Mascotas' },
    { value: 'household-items', enName: 'Household Items', esName: 'Artículos para el Hogar' },
    { value: 'cleaning-supplies', enName: 'Cleaning Supplies', esName: 'Artículos de Limpieza' },
    { value: 'personal-care', enName: 'Personal Care', esName: 'Cuidado Personal' },
    { value: 'baby-care', enName: 'Baby Care', esName: 'Cuidado del Bebé' },
    { value: 'electronics', enName: 'Electronics', esName: 'Electrónica' },
    { value: 'office-supplies', enName: 'Office Supplies', esName: 'Artículos de Oficina' },
    { value: 'clothing', enName: 'Clothing', esName: 'Ropa' },
    { value: 'footwear', enName: 'Footwear', esName: 'Calzado' },
    { value: 'accessories', enName: 'Accessories', esName: 'Accesorios' },
    { value: 'toys', enName: 'Toys', esName: 'Juguetes' },
    { value: 'sporting-goods', enName: 'Sporting Goods', esName: 'Artículos Deportivos' },
    { value: 'garden-supplies', enName: 'Garden Supplies', esName: 'Suministros de Jardinería' },
    { value: 'automotive', enName: 'Automotive', esName: 'Automotriz' },
];

const serviceCategories = [
    { value: 'auto-repair', enName: 'Auto Repair', esName: 'Reparación de Autos' },
    { value: 'oil-change', enName: 'Oil Change', esName: 'Cambio de Aceite' },
    { value: 'tire-service', enName: 'Tire Service', esName: 'Servicio de Neumáticos' },
    { value: 'car-wash', enName: 'Car Wash', esName: 'Lavado de Autos' },
    { value: 'paint-job', enName: 'Paint Job', esName: 'Trabajo de Pintura' },
    { value: 'body-work', enName: 'Body Work', esName: 'Trabajo de Carrocería' },
    { value: 'detailing', enName: 'Detailing', esName: 'Detallado' },
    { value: 'medical-checkup', enName: 'Medical Checkup', esName: 'Chequeo Médico' },
    { value: 'dental-cleaning', enName: 'Dental Cleaning', esName: 'Limpieza Dental' },
    { value: 'dental-procedure', enName: 'Dental Procedure', esName: 'Procedimiento Dental' },
    { value: 'eye-exam', enName: 'Eye Exam', esName: 'Examen de la Vista' },
    { value: 'haircut', enName: 'Haircut', esName: 'Corte de Pelo' },
    { value: 'hair-coloring', enName: 'Hair Coloring', esName: 'Coloración de Cabello' },
    { value: 'manicure', enName: 'Manicure', esName: 'Manicura' },
    { value: 'pedicure', enName: 'Pedicure', esName: 'Pedicura' },
    { value: 'massage', enName: 'Massage', esName: 'Masaje' },
    { value: 'spa-treatment', enName: 'Spa Treatment', esName: 'Tratamiento de Spa' },
    { value: 'house-cleaning', enName: 'House Cleaning', esName: 'Limpieza de Casa' },
    { value: 'lawn-care', enName: 'Lawn Care', esName: 'Cuidado del Césped' },
    { value: 'plumbing', enName: 'Plumbing', esName: 'Plomería' },
    { value: 'electrical-work', enName: 'Electrical Work', esName: 'Trabajo Eléctrico' },
    { value: 'hvac-service', enName: 'HVAC Service', esName: 'Servicio de HVAC' },
    { value: 'pest-control', enName: 'Pest Control', esName: 'Control de Plagas' },
    { value: 'computer-repair', enName: 'Computer Repair', esName: 'Reparación de Computadoras' },
    { value: 'phone-repair', enName: 'Phone Repair', esName: 'Reparación de Teléfonos' },
];

export { menuCategories, productCategories, serviceCategories };
