import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSection } from '../../../../redux/sectionSlice';
import Menu from '../template1Components/Menu';
import Header from '../template1Components/Header';
import Image from '../template1/template1Components/Image';
import Text from '../template1/template1Components/Text';
import Button from '../template1/template1Components/Button';
import ViewiFrame from '../template1/template1Components/ViewiFrame';

const SectionWrapper = styled.div`
  cursor: pointer;
  &:hover {
    outline: 2px solid #007bff;
  }
  outline: ${props => props.selected ? '2px solid #007bff' : 'none'};
  font-family: ${props => props.font}, sans-serif;
  color: ${props => props.textColor};
  background-color: ${props => props.bgColor};
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

// const Column = styled.div`
//   flex: ${props => props.width || '1'};
//   padding: 10px;
//   box-sizing: border-box;
// `;

const Section1 = ({ id, columns, sectionData, companyName, description, menuItems }) => {
  const dispatch = useDispatch();
  const selectedSection = useSelector(state => state.section.selectedSection);
  const handleSectionClick = () => {
    dispatch(setSelectedSection({id: sectionData.id, sectionData: sectionData}));
  };

  const getComponentForType = (sectionData) => {
    const {type, link, image, text, alt} = sectionData;
    switch (type) {
      case 'Header':
        return <Header companyName={companyName} description={description} sectionData={sectionData} />;
      case 'Menu':
        return <Menu menuData={menuItems} sectionData={sectionData} />;
      case 'Image':
        return <Image src={image} alt={alt} sectionData={sectionData} />;
      case 'Text':
        return <Text text={text} sectionData={sectionData} />;
      case 'Button':
        return <Button text={text} link={link} sectionData={sectionData} />;
      case 'iFrame':
        return <ViewiFrame src={link} />;
      case 'Empty':
        return <div>Empty Column</div>;
      default:
        return null;
    }
  };
  console.log({id});
  console.log({sectionData});
  console.log({columns})
  return (
    <SectionWrapper 
    onClick={handleSectionClick} 
    selected={selectedSection?.id === sectionData.id}
      style={{ 
        fontFamily: `${sectionData.font}, sans-serif`, 
        color: sectionData.textColor, 
        backgroundColor: sectionData.bgColor,
        // conditionally add background settings if it exists
        backgroundImage: sectionData.bgImage ? `url(${sectionData.bgImage})` : 'none',
        backgroundSize: sectionData.bgSize ? sectionData.bgSize : 'cover',
        backgroundPosition: sectionData.bgPosition ? sectionData.bgPosition : 'center',
        backgroundRepeat: sectionData.bgRepeat ? sectionData.bgRepeat : 'no-repeat',
      }}
    >
      <ColumnContainer>
        {getComponentForType(sectionData)}
      </ColumnContainer>
    </SectionWrapper>
  );
};

export default Section1;
