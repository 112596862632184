import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Paper, List, ListItem, Button, CircularProgress, Modal, TextField, Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getRewardsList } from '../../api/rewardsApi';
import { useAuth } from '../../hooks/useAuth';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../../redux/authSlice';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const AvailableRewards = () => {
  const { t } = useTranslation();
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { idToken } = useAuth();
  const [selectedReward, setSelectedReward] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pinCode, setPinCode] = useState('');
  const [showQR, setShowQR] = useState(false);
  
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const unsubscribeRef = useRef();
  const lastFetchTimeRef = useRef(0);

  const fetchRewards = useCallback(async () => {
    try {
      setLoading(true);
      const companies = user?.points?.map(point => ({
        uid: point.companyId,
        pointsBalance: point.pointsBalance
      }));
      const rewardsData = await getRewardsList(companies, idToken);
      // console.log({rewardsData});
      localStorage.setItem('rewards', JSON.stringify({
        data: rewardsData.rewards,
        timestamp: Date.now()
      }));
      setRewards(rewardsData.rewards);
      lastFetchTimeRef.current = Date.now();
      setLoading(false);
    } catch (err) {
      console.error('Error fetching rewards:', err);
      setError(t('errorFetchingRewards'));
      setLoading(false);
    }
  }, [user?.points, idToken, t]);

  useEffect(() => {
    if (user && user.uid) {
      const userRef = doc(db, 'users', user.uid);
      unsubscribeRef.current = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          if (userData.createdAt && typeof userData.createdAt.toDate === 'function') {
            userData.createdAt = userData.createdAt.toDate().toISOString();
          }
          dispatch(updateUser(userData));
        }
      });

      return () => {
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
        }
      };
    }
  }, [user?.uid, dispatch]);

  useEffect(() => {
    const loadRewards = () => {
      const cachedRewards = localStorage.getItem('rewards');
      if (cachedRewards) {
        const { data, timestamp } = JSON.parse(cachedRewards);
        if (Date.now() - timestamp < CACHE_DURATION) {
          setRewards(data);
          setLoading(false);
          return;
        }
      }
      fetchRewards();
    };

    if (idToken && user?.points) {
      loadRewards();
    }
  }, [idToken, user?.points, fetchRewards]);

  const handleRedeem = (reward) => {
    setSelectedReward(reward);
    setIsModalOpen(true);
    setPinCode('');
    setShowQR(false);
  };

  const handleConfirmRedeem = () => {
    if (pinCode.length === 4) {
      setShowQR(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedReward(null);
    setPinCode('');
    setShowQR(false);
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        padding: { xs: 1, sm: 2 }, 
        backgroundColor: '#adcce9',
        borderRadius: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 20rem)',
        margin: 1,
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
        {t('availableRewards')}
      </Typography>
      <Box 
        sx={{ 
          backgroundColor: '#adcce9',
          borderRadius: 1, 
          padding: { xs: 1, sm: 2 },
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex',
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : !rewards.length ? (
          <Typography color="#333">{t('noRewardsAvailable')}</Typography>
        ) : (
          <List 
            sx={{ 
              overflowY: 'auto',
              overflowX: 'hidden',
              maxWidth: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              maxHeight: '100%',
              width: '100%',
            }}
          >
            {rewards?.map((reward) => {
              const hasEnoughPoints = user?.points?.find(p => p.companyId === reward?.companyId)?.pointsBalance >= reward?.pointsCost;
              return (
                <ListItem key={reward?.uid} divider 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center'
                  }}
                >
                  <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Typography 
                      color="#333" 
                      sx={{ 
                        borderBottom: '1px solid #333', 
                        marginBottom: 1,
                        display: 'inline-block',
                      }}
                    >
                      {`${reward?.companyName || 'Alaz Chelaz'}`}
                    </Typography>
                    <Typography color="#333">{`${reward?.name}`}</Typography>
                  </Box>
                  <Box sx={{ width: '100%', textAlign: 'center', mt: 1 }}>
                    <Typography component="div" color="#333">
                      <Tooltip title={reward?.description} arrow>
                        <span style={{ cursor: 'pointer' }}> 
                          {`${reward?.description?.slice(0, 50)}${reward?.description?.length > 50 ? '...' : ''}`}
                        </span>
                      </Tooltip>
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                      <Chip label={`${reward?.pointsCost} ${t('points')}`} size="small" color="primary" />
                    </Box>
                  </Box>
                  <Button 
                    variant="contained" 
                    size="small" 
                    sx={{ 
                      marginTop: 1,
                      backgroundColor: '#4285F4', 
                      color: '#333',
                      '&:hover': { backgroundColor: '#4285F4' }, 
                      '&.Mui-disabled': { 
                        // slight opacity
                        opacity: 0.7,
                        backgroundColor: '#4285F4', 
                        color: 'white' 
                      } 
                    }}
                    disabled={!hasEnoughPoints}
                    onClick={() => handleRedeem(reward)}
                  >
                    {hasEnoughPoints ? t('redeem') : t('notEnoughPoints')}
                  </Button>
                </ListItem>
              )
            })}
          </List>
        )}
      </Box>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="redeem-modal-title"
        aria-describedby="redeem-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          color: '#333',
          backgroundColor: '#adcce9',
        }}>
          {!showQR ? (
            <>
              <Typography id="redeem-modal-title" variant="h6" component="h2" gutterBottom>
                {t('confirmRedeem', { name: selectedReward?.name, pointsCost: selectedReward?.pointsCost })}
              </Typography>
              <TextField
                label={t('pin')}
                type="password"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 4, pattern: '[0-9]*' }}
                required
              />
              <Button onClick={handleConfirmRedeem} variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                {t('confirm')}
              </Button>
            </>
          ) : (
            <>
              <Typography id="redeem-modal-title" variant="h6" component="h2" gutterBottom>
                {t('showQRToRedeem')}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <QRCodeSVG value={JSON.stringify({
                  userId: user.uid,
                  userPinCode: pinCode,
                  reward: {
                    uid: selectedReward.uid,
                    name: selectedReward.name,
                    description: selectedReward.description,
                    pointsCost: selectedReward.pointsCost,
                    isAvailable: selectedReward.isAvailable,
                    image: selectedReward.image
                  }
                })} size={200} />
              </Box>
            </>
          )}
          <Button onClick={handleCloseModal} variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            {t('close')}
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
};

export default AvailableRewards;
