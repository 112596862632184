import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import pointsReducer from './pointsSlice';
import sectionReducer from './sectionSlice';
import errorReducer from './errorSlice';
import editPageReducer from './editPageSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    points: pointsReducer,
    section: sectionReducer,
    error: errorReducer,
    editPage: editPageReducer,
    // ... other reducers
  },
});