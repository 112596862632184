import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const RecommendedProducts = ({ items, cartItems, onAddToCart, onRemoveFromCart }) => {
  const { t } = useTranslation();
  
  const handleIncreaseQuantity = (item) => {
    onAddToCart(item);
  };

  const handleDecreaseQuantity = (item) => {
    onRemoveFromCart(item);
  };

  // Only show first 5 items
  const recommendedItems = items.slice(0, 5);

  return (
    <Container>
      <Title>{t('recommendedProducts')}</Title>
      {recommendedItems.map((item) => {
        const quantity = cartItems.items.find(cartItem => cartItem.uid === item.uid)?.quantity || 0;
        
        return (
          <ItemCard key={item.uid}>
            <ItemImage src={item.image} alt={item.name} />
            <ItemContent>
              <ItemInfo>
                <CompanyName>{item.companyName}</CompanyName>
                <ItemName>{item.name}</ItemName>
                <ItemDescription>{item.description}</ItemDescription>
                <ItemPrice>${item.price}</ItemPrice>
              </ItemInfo>
              <CartActions>
                {quantity > 0 ? (
                  <QuantityContainer>
                    <QuantityButton onClick={() => handleDecreaseQuantity(item)}>
                      -
                    </QuantityButton>
                    <Quantity>{quantity}</Quantity>
                    <QuantityButton onClick={() => handleIncreaseQuantity(item)}>
                      +
                    </QuantityButton>
                  </QuantityContainer>
                ) : (
                  <CartButton 
                    onClick={() => handleIncreaseQuantity(item)}
                    $isAdded={false}
                  >
                    +
                  </CartButton>
                )}
              </CartActions>
            </ItemContent>
          </ItemCard>
        );
      })}
    </Container>
  );
};

const CompanyName = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const Container = styled.div`
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
  font-size: 16px;
  margin: 0 0 10px 0;
  color: #333;
`;

const ItemCard = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
`;

const ItemContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const ItemInfo = styled.div`
  flex: 1;
`;

const ItemName = styled.h4`
  font-size: 14px;
  margin: 0 0 5px 0;
`;

const ItemDescription = styled.p`
  font-size: 12px;
  color: #666;
  margin: 0 0 5px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ItemPrice = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #333;
`;

const CartActions = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
`;

const CartButton = styled.button`
  background-color: ${props => props.$isAdded ? '#2ecc71' : '#3498db'};
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 2px;
`;

const QuantityButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  color: #3498db;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 2px;
  
  &:hover {
    color: #2980b9;
  }
`;

const Quantity = styled.span`
  font-size: 14px;
  margin: 0 5px;
  min-width: 20px;
  text-align: center;
`;

export default RecommendedProducts;