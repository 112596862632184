import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, useMediaQuery, useTheme } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import StarIcon from '@mui/icons-material/Star';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const MobileMenu = () => {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItems = useMemo(() => [
    { label: t('home'), icon: <HomeIcon />, to: "/" },
    { label: t('pointsTitle'), icon: <StarIcon />, to: "/points" },
    { label: t('rewardsTitle'), icon: <CardGiftcardIcon />, to: "/rewards" },
    ...(user?.isCompanyAdmin ? [{ label: t('company'), icon: <BusinessIcon />, to: "/company-dashboard" }] : []),
    ...(user?.isAdmin ? [{ label: t('admin'), icon: <AdminPanelSettingsIcon />, to: "/admin" }] : []),
    { label: t('settings'), icon: <SettingsIcon />, to: "/settings" },
  ], [t, user?.isCompanyAdmin, user?.isAdmin]);

  useEffect(() => {
    const currentPath = location.pathname;
    const index = menuItems.findIndex(item => {
      if (item.to === "/" && currentPath === "/") {
        return true;
      }
      return currentPath.startsWith(item.to) && item.to !== "/";
    });
    setValue(index !== -1 ? index : -1);
  }, [location, menuItems]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        height: user?.isAdmin ? 'auto' : '40px',
        flexWrap: 'wrap',
        '& .MuiBottomNavigationAction-root': {
          minWidth: isSmallScreen ? '20%' : 'auto',
          padding: isSmallScreen ? '6px 0' : '6px 12px',
        },
      }}
    >
      {menuItems.map((item, index) => (
        <BottomNavigationAction
          key={item.to}
          label={isSmallScreen ? '' : item.label}
          icon={item.icon}
          component={Link}
          to={item.to}
          sx={{
            fontSize: isSmallScreen ? '0.7rem' : '0.8rem',
          }}
        />
      ))}
    </BottomNavigation>
  );
};

export default MobileMenu;
