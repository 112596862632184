import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SecondaryBanner = ({ ads }) => {
  const { t } = useTranslation();
  
  return (
    <BannerContainer>
      {ads.map((ad) => (
        <BannerItem key={ad.id} onClick={() => window.open(ad.uniqueUrl, '_blank')}>
          <BannerTitle>{t(ad.titleKey) || ad.title}</BannerTitle>
          <ImageContainer>
            <BannerImage src={ad.image} alt={t(ad.titleKey) || ad.title} />
          </ImageContainer>
        </BannerItem>
      ))}
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 5px 5px 5px;
`;

const BannerItem = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BannerTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
  padding: 0 5px;
`;

const ImageContainer = styled.div`
  width: 90%;
  height: 50%;
  padding-bottom: 56%; // 16:9 aspect ratio
  position: relative;
  overflow: hidden;
  /* border: 1px solid green; */
`;

const BannerImage = styled.img`
  position: absolute;
  border-radius: 8px;
  /* top: 0;
  left: 0; */
  padding: auto;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border: 1px solid blue; */

`;

export default SecondaryBanner;
