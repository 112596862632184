import React from 'react';
import { Outlet } from 'react-router-dom';
import LoggedOutHeader from '../components/common/LoggedOutHeader';
import MainFooter from '../components/common/mainFooter';

const LoggedOutLayout = ({ deferredPrompt, handleInstallClick }) => {
  return (
    <div className="logged-out-layout">
      <LoggedOutHeader deferredPrompt={deferredPrompt} handleInstallClick={handleInstallClick} />
      <main>
        <Outlet />
      </main>
      {/* <MainFooter /> */}
    </div>
  );
};

export default LoggedOutLayout;
