import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Avatar, Button, Paper } from '@mui/material';

const UserProfile = ({ user }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEditProfile = () => {
    navigate('/edit-profile');
  };

  const defaultAvatar = '/avatars/avatar1.jpg';

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        padding: { xs: 1, sm: 2 }, 
        backgroundColor: '#adcce9', // Updated background color
        borderRadius: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 16px)', 
        margin: 1,
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
        {t('userProfile')}
      </Typography>
      <Box 
        sx={{ 
          backgroundColor: '#adcce9', // Slightly lighter background for contrast
          borderRadius: 1, 
          padding: { xs: 1, sm: 2 },
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Avatar
          src={user.avatar || defaultAvatar}
          alt='user avatar'
          sx={{ width: 80, height: 80, mb: 2 }}
        />
        <Typography variant="h6" gutterBottom sx={{ color: '#333' }}>
          {user.displayName || user.email}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
          {user.email}
        </Typography>
        <Button 
          variant="contained" 
          onClick={handleEditProfile}
          sx={{ mt: 2, backgroundColor: '#3498db', '&:hover': { backgroundColor: '#3498db' } }}
        >
          {t('editProfile')}
        </Button>
      </Box>
    </Paper>
  );
};

export default UserProfile;
