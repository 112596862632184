import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField, Button, Switch, FormControlLabel, IconButton, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { updatePointsRules } from '../../../api/pointsApi';
import { useSelector, useDispatch } from 'react-redux';
import { setPointsRules } from '../../../redux/pointsSlice';
import { useAuth } from '../../../hooks/useAuth';

const PointsRules = ({ user, fetchPointsRules }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pointsRules = useSelector((state) => state.points.pointsRules);
  const [isTiered, setIsTiered] = useState(false);
  const [basePercentage, setBasePercentage] = useState('');
  const [tiers, setTiers] = useState([{ total: '', percentage: '' }]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { idToken } = useAuth();
  const currency = user.companyProfile.country === 'Mexico' ? 'MXN' : 'USD';

  useEffect(() => {
    if (!pointsRules) {
      setIsLoading(true);
      fetchPointsRules().finally(() => setIsLoading(false));
    } else {
      setIsTiered(pointsRules.isTiered);
      setBasePercentage(pointsRules.basePercentage.toString());
      setTiers(pointsRules.tiers.length > 0 
        ? pointsRules.tiers.map(tier => ({...tier}))  // Create a new object for each tier
        : [{ total: '', percentage: '' }]
      );
    }
  }, [pointsRules, fetchPointsRules]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const rulesData = {
      isTiered,
      basePercentage: parseFloat(basePercentage) || 0,
      tiers: isTiered ? tiers.map(tier => ({
        total: parseFloat(tier.total),
        percentage: parseFloat(tier.percentage)
      })) : []
    };

    try {
      await updatePointsRules(user.companyId, rulesData, idToken);
      dispatch(setPointsRules(rulesData));
      setSuccess(t('pointsRulesUpdatedSuccessfully'));
    } catch (err) {
      console.error('Error updating points rules:', err);
      setError(t('errorUpdatingPointsRules'));
    }
  };

  const addTier = () => {
    setTiers([...tiers, { total: '', percentage: '' }]);
  };

  const removeTier = (index) => {
    setTiers(tiers.filter((_, i) => i !== index));
  };

  const updateTier = (index, field, value) => {
    const newTiers = tiers.map((tier, i) => 
      i === index ? { ...tier, [field]: value } : tier
    );
    setTiers(newTiers);
  };

  if (isLoading) {
    return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
  }

  if (!pointsRules) {
    return <Typography>{t('errorFetchingPointsRules')}</Typography>;
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        {t('pointsRules')}
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={isTiered}
            onChange={(e) => setIsTiered(e.target.checked)}
          />
        }
        label={t('useTieredSystem')}
      />
      {!isTiered && !isLoading && pointsRules && (
        <TextField
          label={t('basePercentage')}
          type="number"
          value={basePercentage}
          onChange={(e) => setBasePercentage(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
      )}
      {isTiered && !isLoading && pointsRules && (
        <>
          {tiers.map((tier, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <TextField
                label={`${t('totalOver')} (${currency})`}
                type="number"
                value={tier.total}
                onChange={(e) => updateTier(index, 'total', e.target.value)}
                sx={{ mr: 2 }}
                required
              />
              <TextField
                label={t('percentage')}
                type="number"
                value={tier.percentage}
                onChange={(e) => updateTier(index, 'percentage', e.target.value)}
                sx={{ mr: 2 }}
                required
              />
              <IconButton onClick={() => removeTier(index)} disabled={tiers.length === 1}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button startIcon={<AddIcon />} onClick={addTier}>
            {t('addTier')}
          </Button>
        </>
      )}
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        {t('savePointsRules')}
      </Button>
      {error && !isLoading && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {success && !isLoading && (
        <Typography color="success.main" sx={{ mt: 2 }}>
          {success}
        </Typography>
      )}
    </Box>
  );
};

export default PointsRules;
