import React from 'react';
import styled from 'styled-components';
import Section1 from '../sections/Section1';
import './template1CSS/Template1.css';

const Template1Container = styled.div`
  font-family: ${props => props.font}, sans-serif;
  color: ${props => props.textColor};
  background-color: ${props => props.bgColor};
`;

const EmptyTemplate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #666;
`;

const Template1 = ({ businessPageData = {}, companyData, isEditable }) => {
  const {companyName, description, menuItems} = companyData;
  let templateRules;
  if (isEditable) {
    templateRules = {...companyData?.templateRules, isEditable: true};
  } else {
    templateRules = businessPageData?.templateRules;
  }
  console.log({templateRules});
  console.log({businessPageData});
  console.log({companyData});
  return (
    <Template1Container 
      style={{ 
        fontFamily: `${templateRules.font}, sans-serif`, 
        color: templateRules.textColor, 
        backgroundColor: templateRules.bgColor 
      }}
    >
      {templateRules.sections?.length && (templateRules.isLive || isEditable) ? (
        templateRules.sections?.map((section, index) => (
          <Section1 
            key={index} 
            id={section.id}
            sectionData={section}
            companyName={companyName}
            description={description}
            menuItems={menuItems}
            columns={section.columns}
          />
        ))
      ) : (
        <EmptyTemplate>
          {isEditable ? 'No sections available. Start by adding a section to your template.' : 'Website is under construction'}
        </EmptyTemplate>
      )}
    </Template1Container>
  );
};

export default Template1;