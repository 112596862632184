import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, Box, useMediaQuery, useTheme, Card, CardContent, Typography, CircularProgress, List, ListItem, ListItemIcon, ListItemText, IconButton, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StripeCheckout from '../common/StripeCheckout';
import { logout } from '../../redux/authSlice';
import { useNavigate } from 'react-router';
import { auth } from '../../firebaseConfig';
import { signOut } from 'firebase/auth';
import StripeCustomerPortal from '../common/StripeCustomerPortal';
import EditProfile from './EditProfile';
import LanguageSelector from '../LanguageSelector';

const TabPanel = styled(Box)`
  padding: 24px;
`;

const PricingCard = styled(Card)`
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const SwipeableContainer = styled(SwipeableViews)`
  .react-swipeable-view-container {
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  width: 100%;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: ${props => props.$isMobile ? '10px' : '20px'};
  height: ${props => props.$isMobile ? 'auto' : '600px'};
  width: ${props => props.$isMobile ? '85%' : '300px'};
  margin: ${props => props.$isMobile ? '0 auto' : '0 10px'};
`;

const TestModeTag = styled.div`
  background-color: #ffd700;
  color: #000;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-bottom: 8px;
`;

const ArrowButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const LeftArrow = styled(ArrowButton)`
  left: 10px;
`;

const RightArrow = styled(ArrowButton)`
  right: 10px;
`;

const DesktopCardContainer = styled.div`
  display: flex;
  justify-content: center; // Center the cards
  align-items: stretch;
  width: 100%;
  max-width: 1200px; // Limit the maximum width
  margin: 0 auto; // Center the container
`;

const CardContentDiv = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
`;

const FeatureList = styled(List)`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 16px;
`;

const PlanHeader = styled.div`
  margin-bottom: 16px;
`;

const MobileCardContainer = styled(Box)`
  margin-bottom: 0;
  position: relative;
  height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const Settings = () => {
  const { t, i18n } = useTranslation();
  const [currentTab, setCurrentTab] = useState(1);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [currentPlanIndex, setCurrentPlanIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setCurrentPlanIndex(index);
  };

  const handleLogout = async () => {
    console.log('Logging out');
    try {
      await signOut(auth);
      dispatch(logout());
      navigate('/', { replace: true });
      // reload the page
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  useEffect(() => {
    const fetchPricingPlans = async () => {
      setIsLoading(true);
      try {
        const plans = [
          {
            id: 'basic',
            name: 'Basic',
            price: 355,
            currency: 'MX$',
            features: [
              '10 Items to Display on your Website',
              '25 Customers and 5 Rewards',
              'Email Support'
            ]
          },
          {
            id: 'growth',
            name: 'Growth',
            price: 475,
            currency: 'MX$',
            features: [
              '20 Items on your Website',
              '75 Customers and 10 Rewards',
              'Priority Email Support',
              '1 Monthly Feature Home Page'
            ]
          },
          {
            id: 'unlimited',
            name: 'Unlimited',
            price: 600,
            currency: 'MX$',
            features: [
              'Unlimited Items on your Website',
              'Unlimited Customers and Rewards',
              'Priority Email/Chat/Phone Support',
              '1 Weekly Feature on Main Page'
            ]
          }
        ];
        setPricingPlans(plans);
        setCurrentPlanIndex(1); // Set to Growth plan after loading
      } catch (error) {
        console.error('Error fetching pricing plans:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPricingPlans();
  }, []);

  const handlePrevious = () => {
    setCurrentPlanIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : pricingPlans.length - 1));
  };

  const handleNext = () => {
    setCurrentPlanIndex((prevIndex) => (prevIndex < pricingPlans.length - 1 ? prevIndex + 1 : 0));
  };

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );

  const SuccessDisplay = ({ isExistingSubscription }) => {
    return (
      <section>
        {!isExistingSubscription && (
          <div className="product Box-root">
            <div className="description Box-root">
              <h3>Subscription to starter plan successful!</h3>
            </div>
          </div>
        )}
        <StripeCustomerPortal user={user} />
      </section>
    );
  };

  const renderPricingCard = (plan, index) => (
    <CardWrapper key={plan.id} $isMobile={isMobile}>
      <PricingCard 
        style={{ 
          opacity: isMobile && currentPlanIndex !== index ? 0.7 : 1,
          transform: isMobile && currentPlanIndex !== index ? 'scale(0.75)' : 'scale(0.9)',
          transition: 'all 0.3s ease',
        }}
      >
        <CardContentDiv>
          <PlanHeader>
            <TestModeTag>TEST MODE</TestModeTag>
            <Typography variant="h5" component="div">
              {plan.name}
            </Typography>
            <Typography variant="h4" color="text.primary" style={{ margin: '16px 0' }}>
              {plan.currency}{plan.price}
              <Typography variant="caption" style={{ verticalAlign: 'super' }}>/month</Typography>
            </Typography>
          </PlanHeader>
          <FeatureList dense>
            <Typography variant="subtitle1" align="left" style={{ marginBottom: '8px' }}>
              This includes:
            </Typography>
            {plan.features.map((feature, idx) => (
              <ListItem key={idx} disableGutters>
                <ListItemIcon style={{ minWidth: '30px' }}>
                  <CheckCircleOutlineIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            ))}
          </FeatureList>
          <StripeCheckout lookupKey={plan.id} buttonText="Start trial" user={user} />
        </CardContentDiv>
      </PricingCard>
    </CardWrapper>
  );

  const renderPricingCards = () => {
    if (isMobile) {
      return (
        <MobileCardContainer>
          <SwipeableContainer 
            enableMouseEvents 
            index={currentPlanIndex} 
            onChangeIndex={handleChangeIndex}
            style={{ overflow: 'visible' }}
          >
            {pricingPlans.map((plan, index) => renderPricingCard(plan, index))}
          </SwipeableContainer>
          <LeftArrow onClick={handlePrevious}>
            <ArrowBackIosNewIcon />
          </LeftArrow>
          <RightArrow onClick={handleNext}>
            <ArrowForwardIosIcon />
          </RightArrow>
        </MobileCardContainer>
      );
    } else {
      return (
        <DesktopCardContainer>
          {pricingPlans.map((plan, index) => renderPricingCard(plan, index))}
        </DesktopCardContainer>
      );
    }
  };

  const renderBillingContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      );
    }

    if (user?.validStripe && user?.pricePlan) {
      return <SuccessDisplay isExistingSubscription={true} />;
    }

    if (success && sessionId) {
      return <SuccessDisplay isExistingSubscription={false} />;
    }

    if (message) {
      return <Message message={message} />;
    }

    return renderPricingCards();
  };
  console.log('user', user);
  return (
    <div className="settings-page">
      <h1 style={{textAlign: 'center'}}>{t('settings')}</h1>
      <Tabs 
        value={currentTab} 
        onChange={handleTabChange}
        variant={isMobile ? "fullWidth" : "standard"}
        centered={!isMobile}
      >
        <Tab label={t('billing')} style={{color: 'white'}} />
        <Tab label={t('preferences')} style={{color: 'white'}} />
        <Tab label={t('profile')} style={{color: 'white'}} />
      </Tabs>

      <TabPanel hidden={currentTab !== 0} style={{ overflow: 'hidden', padding: isMobile ? '12px' : '24px' }}>
        {user.isCompanyOwner 
          ? renderBillingContent() 
          : <><p>You are not the owner of this company, please contact your administrator for access.</p></>
        }
        <Box display="flex" justifyContent="center" mt={1}>
          <Button color="primary" variant="contained" onClick={handleLogout}>Logout</Button>
        </Box>
      </TabPanel>

      <TabPanel hidden={currentTab !== 1} style={{textAlign: 'center'}}>
        {/* Add the language selector here */}
        <LanguageSelector />
        <Box display="flex" justifyContent="center" mt={1}>
          <Button color="primary" variant="contained" onClick={handleLogout}>Logout</Button>
        </Box>
      </TabPanel>

      <TabPanel hidden={currentTab !== 2}>
        {/* Add profile content here */}
        <EditProfile />
        <Box display="flex" justifyContent="center" mt={1}>
          <Button color="primary" variant="contained" onClick={handleLogout}>Logout</Button>
        </Box>
      </TabPanel>
    </div>
  );
};

export default Settings;
