import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { X, User, LogIn, UserPlus, Settings } from 'lucide-react';
import { changeLanguage } from 'i18next';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';
import { logout } from '../../../redux/authSlice';
import { Logout } from '@mui/icons-material';
import LanguageSelector from '../../LanguageSelector';

const UserSideBar = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  if (!isOpen) return null;

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  const handleLogout = async () => {
    console.log('Logging out');
    try {
      await signOut(auth);
      dispatch(logout());
      navigate('/', { replace: true });
      // reload the page
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };


  return (
    <SideBarOverlay onClick={onClose}>
      <SideBarContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <X size={24} />
        </CloseButton>
        
        <TopSection>
          {userData ? (
            <UserSection>
              <UserAvatar src={userData.avatar} alt="user avatar" />
              <UserName>{userData.displayName || userData.email}</UserName>
              <MenuButton onClick={() => handleNavigation('/settings')}>
                <Settings size={20} />
                {t('settings')}
              </MenuButton>
              <MenuButton onClick={handleLogout}>
                <Logout size={20} />
                {t('logout')}
              </MenuButton>
            </UserSection>
          ) : (
            <AuthSection>
              <LanguageSelector />
              <AuthButton onClick={() => handleNavigation('/login')}>
                <LogIn size={20} />
                {t('login')}
              </AuthButton>
              <AuthButton onClick={() => handleNavigation('/signup')}>
                <UserPlus size={20} />
                {t('signup')}
              </AuthButton>
            </AuthSection>
          )}
        </TopSection>

        {/* Additional menu items can be added here */}
      </SideBarContainer>
    </SideBarOverlay>
  );
};


const SideBarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const SideBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  background-color: #3498db;
  height: 30%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1002;
`;

const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`;

const UserAvatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid white;
`;

const UserName = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
`;

const AuthSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const AuthButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  color: #3498db;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  
  &:hover {
    background-color: #f8f9fa;
  }
`;

const LanguageSelect = styled.select`
  background-color: white;
  color: #3498db;
  border: none;
  padding: 5px;
`;

const MenuButton = styled(AuthButton)`
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export default UserSideBar;