import React, { useState } from 'react';
import { createCompany } from '../../api/adminApi';
import '../../styles/CreateCompany.css';
import { useAuth } from '../../hooks/useAuth';

const CreateCompany = () => {
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { idToken } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    try {
      const companyData = { companyName, email, country };
      await createCompany(companyData, idToken);
      
      setSuccess(true);
      // Clear the form
      setCompanyName('');
      setEmail('');
      setCountry('');
    //   // Optionally, redirect to the companies list after a short delay
    //   setTimeout(() => navigate('/admin/companies'), 2000);
    } catch (error) {
      setError(error.message || 'An error occurred while creating the company.');
    }
  };

  return (
    <div className="create-company">
      <h1>Create Company</h1>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">Company created successfully!</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="companyName">Company Name:</label>
          <input
            type="text"
            id="companyName"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country:</label>
          <select
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          >
            <option value="">Select a country</option>
            <option value="usa">USA</option>
            <option value="mexico">Mexico</option>
          </select>
        </div>
        <button type="submit" className="submit-button">Create Company</button>
      </form>
    </div>
  );
};

export default CreateCompany;