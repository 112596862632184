import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import MainHeader from '../components/common/mainHeader';
import MainFooter from '../components/common/mainFooter';
import MobileMenu from '../components/common/MobileMenu';
import { useSelector } from 'react-redux';

const MainLayout = ({ deferredPrompt, handleInstallClick }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const userData = useSelector(state => state.auth.user);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="main-layout">
      {!isMobile && <MainHeader deferredPrompt={deferredPrompt} handleInstallClick={handleInstallClick} />}
      <main className="main-layout-main">
        <Outlet/>
      </main>
      {isMobile && userData ? <MobileMenu className="mobile-menu" /> : <MainFooter />}
    </div>
  );
};

export default MainLayout;
