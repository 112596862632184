import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import AddPoints from './pointsComponents/AddPoints';
import RedeemPoints from './pointsComponents/RedeemPoints';
import PointsRules from './pointsComponents/PointsRules';
import { useSelector, useDispatch } from 'react-redux';
import { getPointsRules } from '../../api/pointsApi';
import { setPointsRules } from '../../redux/pointsSlice';
import { useAuth } from '../../hooks/useAuth';
import '../../styles/PointsManagement.css';

const PointsManagement = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { idToken } = useAuth();

  const fetchPointsRules = useCallback(async () => {
    if (!idToken) return;
    try {
      const rules = await getPointsRules(user.companyId, idToken);
      dispatch(setPointsRules(rules));
    } catch (err) {
      console.error('Error fetching points rules:', err);
    }
  }, [user?.companyId, dispatch, idToken]);

  useEffect(() => {
    idToken && fetchPointsRules();
  }, [fetchPointsRules, idToken]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box className="points-management">
      <Typography variant="h4" gutterBottom>
        {t('pointsManagement')}
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label={t('addPoints')} />
        <Tab label={t('redeemPoints')} />
        <Tab label={t('pointsRules')} />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {tabValue === 0 && <AddPoints />}
        {tabValue === 1 && <RedeemPoints />}
        {tabValue === 2 && <PointsRules user={user} fetchPointsRules={fetchPointsRules} />}
      </Box>
    </Box>
  );
};

export default PointsManagement;
