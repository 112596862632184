import React from 'react';
import styled from 'styled-components';

const IFrameWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const ViewiFrame = ({ src }) => {
  return (
    <IFrameWrapper>
      <StyledIFrame 
      src={src} 
      title="Embedded Content" 
      allowFullScreen 
      />
    </IFrameWrapper>
  );
};

export default ViewiFrame;

