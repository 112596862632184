import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/authSlice';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LanguageSelector from '../LanguageSelector';

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
`;

const MenuItem = styled(Link)`
  color: white;
  text-decoration: none;
`;

const LogoutButton = styled.button`
  background-color: #4E9EF5;
  color: #E8E6E3;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 15px;
`;

const MainHeader = ({ deferredPrompt, handleInstallClick }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLogout = async () => {
    console.log('handleLogout');
    try {
      await signOut(auth);
      dispatch(logout());
      navigate('/', { replace: true });
      // reload the page
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="main-header">
      <div className="container">
        {deferredPrompt && (
          <button onClick={handleInstallClick} className="install-button">
              {t('installButton')}
            </button>
          )}
        <Logo to="/">Hyperlink.it</Logo>
        <div className="menu-wrapper">
          <LanguageSelector />
          <Menu
            right
            isOpen={menuOpen}
            onStateChange={handleStateChange}
          >
            <MenuItem  onClick={closeMenu} to="/">{t('home')}</MenuItem>
            {/* <MenuItem onClick={closeMenu} to="/rewards">{t('rewards')}</MenuItem> */}
            <MenuItem onClick={closeMenu} to="/edit-profile">{t('profile')}</MenuItem>
            <MenuItem onClick={closeMenu} to="/company-dashboard">{t('companyDashboard')}</MenuItem>
            {/*//! Might need to make it href to refresh the page  */}
            <LogoutButton onClick={() => { closeMenu(); handleLogout(); }}>{t('logout')}</LogoutButton>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;
