export const isValidAddress = (address) => {
  // Basic address validation regex
  const addressRegex = /^[a-zA-Z0-9\s,'-]*$/;
  return addressRegex.test(address) && address.length > 5;
};

export const isValidPhoneNumber = (phone) => {
  // Basic phone number validation regex
  // eslint-disable-next-line no-useless-escape
  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  return phoneRegex.test(phone);
};

export const isValidDescription = (description) => {
  // Check for potential script tags or suspicious patterns
  const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  const suspiciousPatterns = /(\b(alert|console|eval|document)\b|\(\))/gi;
  return !scriptRegex.test(description) && !suspiciousPatterns.test(description);
};

export const isValidZipCode = (zipCode) => {
  // This regex allows for common zip code formats:
  // - 5 digits (US)
  // - 5 digits + 4 digits (US)
  // - 6 characters (alphanumeric, for Canada and other countries)
  const zipCodeRegex = /^[A-Za-z0-9]{5,6}(-[0-9]{4})?$/;
  return zipCodeRegex.test(zipCode);
};

export const sanitizeInput = (input) => {
  // Basic input sanitization
  return input.replace(/[&<>"']/g, function(m) {
    return {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;'
    }[m];
  });
};

export const sanitizeFontSize = (value) => {
  // Remove any non-digit and non-decimal point characters
  let sanitized = value.replace(/[^\d.]/g, '');

  // Ensure there's only one decimal point
  const parts = sanitized.split('.');
  if (parts.length > 2) {
    sanitized = parts[0] + '.' + parts.slice(1).join('');
  }

  // Convert to a number and limit to two decimal places
  let number = parseFloat(sanitized);
  if (isNaN(number)) {
    number = 0;
  }
  number = Math.round(number * 100) / 100;

  // Ensure the value is between 1 and 100
  number = Math.max(1, Math.min(number, 100));

  // Return the sanitized value with 'px' appended
  return `${number}px`;
};
