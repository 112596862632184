import apiCall from './apiCall';

const API_ENDPOINT = '/adminApi';
export const submitContactFormAPI = async (formData) => {
  try {
    const response = await apiCall('post', `${API_ENDPOINT}/contact`, formData);
    return response;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};