import React from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';

const ContactAndHours = ({ companyProfile, backgroundColor, secondaryColor, contactTextColor }) => {
  const { address, email, hours, latitude, longitude } = companyProfile || {};

  const formatHours = (day) => {
    const open = hours?.[day]?.open || 'closed'; //!
    const close = hours?.[day]?.close || 'closed'; //!
    return open === 'closed' ? 'Closed' : `${open} - ${close}`;
  };

  return (
    <Box sx={{ mt: 4, p: 2, bgcolor: backgroundColor, color: '#fff', borderRadius: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} sx={{ color: contactTextColor }}>
          <Typography variant="h6" sx={{ mb: 2, color: secondaryColor }}>Contact</Typography>
          <Typography>{address?.street || ' '} {/* //! */}</Typography>
          <Typography>{address?.city || ' '}, {address?.state || ' '}, {address?.zipCode || ' '}</Typography>
          <Typography>{address?.country || ' '}</Typography>
          <Typography>Email: {email || ' '}</Typography> {/* //! */}
          <Typography>Phone: {companyProfile?.phone || ' '}</Typography> {/* //! */}
        </Grid>
        <Grid item xs={12} md={4} sx={{ color: contactTextColor }}>
          <Typography variant="h6" sx={{ mb: 2, color: secondaryColor }}>Opening Hours</Typography>
          <Typography>Monday: {formatHours('monday')}</Typography>
          <Typography>Tuesday: {formatHours('tuesday')}</Typography>
          <Typography>Wednesday: {formatHours('wednesday')}</Typography>
          <Typography>Thursday: {formatHours('thursday')}</Typography>
          <Typography>Friday: {formatHours('friday')}</Typography>
          <Typography>Saturday: {formatHours('saturday')}</Typography>
          <Typography>Sunday: {formatHours('sunday')}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" sx={{ mb: 2, color: secondaryColor }}>Find Us</Typography>
          <Box
            component="iframe"
            src={`https://www.google.com/maps?q=${latitude || 0},${longitude || 0}&z=15&output=embed`}
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
          <IconButton
            color="primary"
            href={`https://wa.me/${companyProfile?.phone || ''}`} //!
            target="_blank"
            sx={{ mt: 2, bgcolor: '#25D366', color: '#fff' }}
          >
            <WhatsApp />
            <Typography sx={{ ml: 1 }}>Let's Chat!</Typography>
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactAndHours;
