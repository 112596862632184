import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile, signInWithPopup } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db, googleProvider } from '../firebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaGoogle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { login, userIsLoggedIn } from '../redux/authSlice';
import { useDispatch } from 'react-redux';

function SignUp() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSignUp = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName: displayName });
      console.log("User created successfully");

      const userData = {
        uid: user.uid,
        isAdmin: false,
        name: '',
        email: user.email,
        phoneNumber: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        avatar: '/avatars/avatar4.jpg',
        optedInCompanies: [],
        points: [],
        displayName: displayName,
        location: null,
        createdAt: new Date()
      };
      
      await setDoc(doc(db, "users", user.uid), userData);

      console.log("User created successfully");
      dispatch(login(userData));
      navigate('/'); // Redirect to home page after successful sign up
    } catch (error) {
      setError(error.message);
      console.error("Error creating user:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleSignUp = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Check if the user already exists in Firestore
      const userDoc = await getDoc(doc(db, "users", user.uid));
      const userData = userDoc.data();
      console.log({user});
      console.log({userData});

      if (!userData) {
        // If the user doesn't exist, create a new user document
        const userData = {
          uid: user.uid,
          isAdmin: false,
          name: user?.displayName || '',
          email: user.email,
          phoneNumber: user?.phoneNumber || '',
          address: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
          avatar: user?.photoURL || '/avatars/avatar4.jpg',
          optedInCompanies: [],
          points: [],
          displayName: user?.displayName || user.email.split('@')[0],
          location: null,
          createdAt: new Date()
        };

        await setDoc(doc(db, "users", user.uid), userData);
      } else {
        // If the user already exists, dispatch login with existing data
        dispatch(login(userDoc.data()));
      }
      console.log("User signed up with Google successfully");
      navigate('/'); // Redirect to home page after successful sign up
      dispatch(userIsLoggedIn(true));
    } catch (error) {
      setError(error.message);
      console.error("Error signing up with Google:", error);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <img src="/hyperlinkLogo.png" alt="HyperLink.it Logo" className="signup-logo" />
        <h2>{t('createNewAccount')}</h2>
        <form onSubmit={handleSignUp}>
          <input
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder={t('displayName')}
            required
          />
          <input
            type="email"
            value={email}
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('email')}
            required
          />
          <div className="password-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('newPassword')}
              required
            />
            <button
              type="button"
              className="toggle-password"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <button type="submit" className="signup-button">{t('signupButton')}</button>
        </form>
        <div className="google-signup-wrapper">
          <button onClick={handleGoogleSignUp} className="google-signup-button">
            <FaGoogle /> {t('signupWithGoogle')}
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}
        <div className="login-link">
          {t('alreadyHaveAccount')} <Link to="/login">{t('login')}</Link>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
