import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { submitContactFormAPI } from '../api/contactApi';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  contactFormStatus: 'idle',
  contactFormError: null,
  welcomeMessageConfirmed: localStorage.getItem('welcomeMessageConfirmed') === 'true',
};

export const submitContactForm = createAsyncThunk(
  'auth/submitContactForm',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await submitContactFormAPI(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    logout: (state) => {
      console.log('logout________');
      state.user = null;
      localStorage.removeItem('user');
      const isConfirmed = localStorage.getItem('welcomeMessageConfirmed');
      //remove all local storage items except for welcomeMessageConfirmed
      localStorage.clear();
      if (isConfirmed) {
        localStorage.setItem('welcomeMessageConfirmed', 'true');
      }
    },
    userIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    optInCompany: (state, action) => {
      if (!state.user.optedInCompanies) {
        state.user.optedInCompanies = [];
      }
      if (!state.user.optedInCompanies.includes(action.payload.companyId)) {
        state.user.optedInCompanies.push(action.payload.companyId);
      }
      
      const pointIndex = state.user.points.findIndex(point => point.companyId === action.payload.companyId);
      if (pointIndex === -1) {
        state.user.points.push({
          companyId: action.payload.companyId,
          companyName: action.payload.companyName,
          pointsBalance: 0,
          logo: action.payload.logo,
          isActive: true
        });
      } else {
        state.user.points[pointIndex].isActive = true;
      }
      
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    optOutCompany: (state, action) => {
      // state.user.optedInCompanies = state.user.optedInCompanies.filter(
      //   companyId => companyId !== action.payload
      // );
      
      const pointIndex = state.user.points.findIndex(point => point.companyId === action.payload);
      if (pointIndex !== -1) {
        state.user.points[pointIndex].isActive = false;
      }
      
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    updatePoints: (state, action) => {
      const { companyId, newBalance } = action.payload;
      const pointIndex = state.user.points.findIndex(point => point.companyId === companyId);
      if (pointIndex !== -1) {
        state.user.points[pointIndex].pointsBalance = newBalance;
        localStorage.setItem('user', JSON.stringify(state.user));
      }
    },
    updateUser: (state, action) => {
      const userToUpdate = { ...action.payload };

      state.user = { ...state.user, ...userToUpdate };
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    confirmWelcomeMessage: (state) => {
      state.welcomeMessageConfirmed = true;
      localStorage.setItem('welcomeMessageConfirmed', 'true');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitContactForm.pending, (state) => {
        state.contactFormStatus = 'loading';
      })
      .addCase(submitContactForm.fulfilled, (state) => {
        state.contactFormStatus = 'succeeded';
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.contactFormStatus = 'failed';
        state.contactFormError = action.payload;
      });
  }
});

export const { login, logout, optInCompany, optOutCompany, updatePoints, updateUser, userIsLoggedIn, confirmWelcomeMessage } = authSlice.actions;

export default authSlice.reducer;
