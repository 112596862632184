import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import { getTransactions } from '../../../api/transactionsApi';
import { useAuth } from '../../../hooks/useAuth';

const ManageMyPoints = () => {
  const { companyId } = useParams();
  const location = useLocation();
  const company = location.state?.company;
  const user = useSelector((state) => state.auth.user);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { idToken } = useAuth();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const transactionsResponse = await getTransactions(user.uid, companyId, idToken);
        setTransactions(transactionsResponse.transactions);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError('Failed to fetch transactions. Please try again later.');
        setLoading(false);
      }
    };

    idToken && fetchTransactions();
  }, [user.uid, companyId, idToken]);

  const getTransactionTypeDisplay = (type) => {
    switch (type) {
      case 'points_earned':
        return 'Points Earned';
      case 'points_adjustment':
        return 'Points Adjustment';
      case 'reward_redeemed':
        return 'Reward Redeemed';
      default:
        return type;
    }
  };

  if (loading) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>;
  if (error) return <Typography color="error" sx={{ p: 3 }}>{error}</Typography>;
console.log('transactions', transactions);
  return (
    <Box sx={{ p: 3 }} className="manage-my-points">
      <Typography variant="h4" gutterBottom>
        Manage My Points for {company?.companyName}
      </Typography>
      {transactions.length === 0 ? (
        <Typography>No transactions found for this company.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => (
                <TableRow key={`${transaction.id}-${transaction.createdAt._seconds}`}>
                  <TableCell>
                    {new Date(transaction.createdAt._seconds * 1000).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{getTransactionTypeDisplay(transaction.type)}</TableCell>
                  <TableCell align="right" sx={{ color: transaction.receiptTotal >= 0 ? 'green' : 'red' }}>
                    {transaction.receiptTotal >= 0 ? '+' : '-'}{Math.abs(transaction.receiptTotal)}
                  </TableCell>
                  <TableCell>{transaction.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ManageMyPoints;
