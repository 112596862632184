import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { updateUser } from '../redux/authSlice';
import UserProfile from '../components/users/UserProfile';
import PointsOverview from '../components/users/userPointsOverviewComponents/PointsOverview';
import RecentTransactions from '../components/users/RecentTransactions';
import AvailableRewards from '../components/users/AvailableRewards';
import ReceivePoints from '../components/users/ReceivePoints';
import NearbyBusinesses from '../components/users/NearbyBusinesses';
import CreateUpdatePin from '../components/users/CreateUpdatePin';
import AdSenseAd from '../services/AdSenseAd';

const UserDashboard = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const unsubscribeRef = useRef();

  useEffect(() => {
    if (user && user.uid) {
      const userRef = doc(db, 'users', user.uid);
      unsubscribeRef.current = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          // console.log({userData});
          if (userData.createdAt && typeof userData.createdAt.toDate === 'function') {
            userData.createdAt = userData.createdAt.toDate().toISOString();
          }
          dispatch(updateUser(userData));
        }
      });

      return () => {
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
        }
      };
    }
  }, [user?.uid, dispatch]);

  return (
    user?.email && (
      <Box className="user-dashboard" sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom color="#333">
          {t('welcome', { name: user?.displayName || user?.email })}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            <Box sx={{ flexBasis: { xs: '100%', md: 'calc(50% - 12px)' }, flexGrow: 1 }}>
              <UserProfile user={user} />
            </Box>
            <Box sx={{ flexBasis: { xs: '100%', md: 'calc(50% - 12px)' }, flexGrow: 1 }}>
              <PointsOverview user={user} />
            </Box>
          </Box>
          {/* <AdSenseAd /> */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            <Box sx={{ flexBasis: { xs: '100%', md: 'calc(50% - 12px)' }, flexGrow: 1 }}>
              <NearbyBusinesses user={user} />
            </Box>
            <Box sx={{ flexBasis: { xs: '100%', md: 'calc(50% - 12px)' }, flexGrow: 1, maxWidth: '100%', }}>
              <AvailableRewards user={user} />
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box sx={{ mb: 3 }}>
              <RecentTransactions user={user} />
            </Box>
            <Box sx={{ mb: 3 }}>
              <ReceivePoints />
            </Box>
            <Box>
              <CreateUpdatePin />
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <RecentTransactions user={user} />
            <Box sx={{ mt: 3 }}>
              <ReceivePoints />
            </Box>
            <Box sx={{ mt: 3 }}>
              <CreateUpdatePin />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default UserDashboard;
