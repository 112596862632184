import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pointsRules: null,
};

const pointsSlice = createSlice({
  name: 'points',
  initialState,
  reducers: {
    setPointsRules: (state, action) => {
      state.pointsRules = action.payload;
    },
  },
});

export const { setPointsRules } = pointsSlice.actions;

export default pointsSlice.reducer;