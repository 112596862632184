import React, { useState, useEffect, useCallback } from 'react';
import { Box, Card, CardContent, IconButton, Button, CircularProgress, useMediaQuery } from '@mui/material';
import { ChevronRight } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import { createBusinessPage, getBusinessPage } from '../../api/businessPagesApi';
import { useAuth } from '../../hooks/useAuth';
import { setPageSettings, updateSetting } from '../../redux/editPageSlice';
import '../../styles/WebsiteGenerator.css';
import CustomizeSidebar from './CustomizeSidebar';
import PreviewWebsite from './PreviewWebsite';
import ImageSelectionModal from './ImageSelectionModal';
import { getCompanyGallery } from '../../api/companyApi';

const WebsiteGenerator = () => {
  console.log('WebsiteGenerator rendered');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
  const [isHeroImageModalOpen, setIsHeroImageModalOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [pageFound, setPageFound] = useState(false);
  const [businessPageId, setBusinessPageId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { idToken } = useAuth();
  const userData = useSelector(state => state.auth.user);
  const settings = useSelector(state => state.editPage.settings);
  const defaultSettings = useSelector(state => state.editPage.defaultSettings);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const dispatch = useDispatch();

  console.log('Business page ID:', businessPageId);
  useEffect(() => {
    const fetchBusinessPage = async () => {
      try {
        const images = await getCompanyGallery(userData.companyProfile.uid, idToken);
        setGalleryImages(images);
        const response = await getBusinessPage(userData.companyProfile.uid, idToken);
        console.log('Business page response:', response.found);
        if (response.found) {
          console.log('Business page data:', response.data); 
          response.data.isDefault && dispatch(setPageSettings(defaultSettings));
          !response.data.isDefault && dispatch(setPageSettings(response.data.testModeTemplateRules));
          setBusinessPageId(response.data.uid);
          setPageFound(true);
          setIsLoading(false);
        } else {
          console.log('Business page is not default');
          // dispatch(updateSetting(defaultSettings));
          setPageFound(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log('Error fetching business page:', error);
        console.error('Error fetching business page:', error);
        // dispatch(updateSetting(defaultSettings));
        setPageFound(false);
        setIsLoading(false);
      }
    };

    if (idToken && userData.companyProfile) {
      fetchBusinessPage();
    }
  }, [idToken, userData.companyProfile, dispatch, defaultSettings]);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen(prev => !prev);
  }, []);

  const handleLogoModalOpen = useCallback(() => setIsLogoModalOpen(true), []);
  const handleLogoModalClose = useCallback(() => setIsLogoModalOpen(false), []);
  const handleHeroImageModalOpen = useCallback(() => setIsHeroImageModalOpen(true), []);
  const handleHeroImageModalClose = useCallback(() => setIsHeroImageModalOpen(false), []);

  const handleLogoSelect = useCallback((imageUrl) => {
    dispatch(updateSetting({ key: 'logoUrl', value: imageUrl }));
    handleLogoModalClose();
  }, [dispatch, handleLogoModalClose]);

  const handleHeroImageSelect = useCallback((imageUrl) => {
    dispatch(updateSetting({ key: 'heroImage', value: imageUrl }));
    handleHeroImageModalClose();
  }, [dispatch, handleHeroImageModalClose]);

  const handleGetStarted = async () => {
    setIsLoading(true);
    const images = await getCompanyGallery(userData.companyProfile.uid, idToken);
    setGalleryImages(images);
    const response = await createBusinessPage(userData.companyProfile.uid, idToken);
    console.log('Business page created:', response);
    console.log('Gallery images:', images);
    if (response.uid) {
      dispatch(updateSetting(defaultSettings));
      setPageFound(true);
      setBusinessPageId(response.uid);
      console.log('Business page created successfully');
    } else {
      console.log('Failed to create business page');
      console.error('Failed to create business page');
      setPageFound(false);
    }
    setIsLoading(false);
  };

  console.log('WebsiteGenerator re-rendered');
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!pageFound && !isLoading && JSON.stringify(settings) === JSON.stringify(defaultSettings)) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh'
        }}
      >
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleGetStarted}
        >
          Get Started
        </Button>
      </Box>
    );
  }

  return (
    <>
      {pageFound && 
        <>
          <Box sx={{ display: isMobile ? 'block' : 'flex' }} className='website-generator-container'>
            <CustomizeSidebar 
              open={sidebarOpen} 
              onClose={toggleSidebar}
              onLogoModalOpen={handleLogoModalOpen}
              onHeroImageModalOpen={handleHeroImageModalOpen}
              idToken={idToken}
              businessPageId={businessPageId}
            />
            <Box sx={{ flexGrow: 1, transition: 'margin 0.2s', marginLeft: sidebarOpen ? '30px' : 0 }}>
              {!sidebarOpen && (
                <IconButton 
                  onClick={toggleSidebar}
                  sx={{ position: 'fixed', left: 15, top: 50, zIndex: 1200, color: 'white', backgroundColor: '#4E9EF5' }}
                >
                  <ChevronRight />
                </IconButton>
              )}
                  <PreviewWebsite isEditable={true}/>
              {/* <Card>
                <CardContent>
                </CardContent>
              </Card> */}
            </Box>
            <ImageSelectionModal 
              open={isLogoModalOpen}
              onClose={handleLogoModalClose}
              onSelect={handleLogoSelect}
              title="Choose Logo"
              images={galleryImages}
            />
            <ImageSelectionModal 
              open={isHeroImageModalOpen}
              onClose={handleHeroImageModalClose}
              onSelect={handleHeroImageSelect}
              title="Choose Hero Image"
              images={galleryImages}
            />
          </Box>
        </>
      }
    </>
  );
};

export default React.memo(WebsiteGenerator);
