import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const ItemList = ({ country, items, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const handleImageError = (e, itemName) => {
    console.error(`Error loading image for item: ${itemName}`);
    e.target.style.display = 'none';
    e.target.nextSibling.style.display = 'inline';
  };
  console.log({items});
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('name')}</TableCell>
            <TableCell>{t('price')}</TableCell>
            <TableCell>{t('description')}</TableCell>
            <TableCell>{t('image')}</TableCell>
            <TableCell>{t('category')}</TableCell>
            <TableCell>{t('isAvailable')}</TableCell>
            <TableCell>{t('actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.uid}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{country === 'usa' ? "$" : "MX$"}{item.price}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>
                {item.image ? (
                  <>
                    <img 
                      src={item.image}
                      alt={item.name} 
                      style={{ width: '50px', height: '50px' }} 
                      onError={(e) => handleImageError(e, item.name)}
                    />
                    <span style={{ display: 'none' }}>{t('imageNotAvailable')}</span>
                  </>
                ) : (
                  <span>{t('noImage')}</span>
                )}
              </TableCell>
              <TableCell>{item.category}</TableCell>
              <TableCell>{item.isAvailable ? t('yes') : t('no')}</TableCell>
              <TableCell>
                <IconButton onClick={() => onEdit(item.uid)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onDelete(item.uid)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ItemList;
