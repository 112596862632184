import React from 'react';
import { useMediaQuery } from '@mui/material';
import MobileUserHome from '../components/users/userHome/MobileUserHome';
import WebUserHome from '../components/users/userHome/WebUserHome';

const UserHome = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <>
      <MobileUserHome />
      {/* {isMobile ? <MobileUserHome /> : <WebUserHome />}  */}
    </>
  );
};

export default UserHome;