import React, { useState, useCallback } from 'react';
import { Button, Typography, Box, Popover } from '@mui/material';
import { HexColorPicker } from 'react-colorful';

const ColorPickerButton = ({ color, colorType, label, onChange }) => {
  console.log(`ColorPickerButton rendered for ${colorType}`);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleColorChange = useCallback((newColor) => {
    onChange(colorType, newColor);
  }, [onChange, colorType]);

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        fullWidth
        onClick={handleClick}
        sx={{
          justifyContent: 'space-between',
          color: 'inherit',
          textTransform: 'none',
          border: '1px solid #ccc',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <Typography>{label}</Typography>
        <Box
          sx={{
            width: 24,
            height: 24,
            backgroundColor: color,
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <HexColorPicker color={color} onChange={handleColorChange} />
        </Box>
      </Popover>
    </>
  );
};

export default React.memo(ColorPickerButton);
