import React, { useState, useEffect } from 'react';
import { TextField, Button, DialogActions, DialogContent, DialogTitle, Input, MenuItem, Switch, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { menuCategories, productCategories, serviceCategories } from '../../../utils/categories';
import { sanitizeInput } from '../../../utils/validation';

const ItemForm = ({ item, onChange, onImageChange, onSubmit, onClose, isNew }) => {
  const { t, i18n } = useTranslation();
  const [imagePreview, setImagePreview] = useState(item.image || '');
  const [categories, setCategories] = useState([]);
  const [showOtherCategory, setShowOtherCategory] = useState(false);

  useEffect(() => {
    setImagePreview(item.image || '');
    updateCategories(item.itemType || 'product');
    setShowOtherCategory(item.category === 'other');
  }, [item.image, item.itemType, item.category]);

  const updateCategories = (itemType) => {
    switch (itemType) {
      case 'menuItem':
        setCategories(menuCategories);
        break;
      case 'product':
        setCategories(productCategories);
        break;
      case 'service':
        setCategories(serviceCategories);
        break;
      default:
        setCategories([]);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        onImageChange(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const handleItemTypeChange = (e) => {
    const newItemType = e.target.value;
    onChange('itemType', newItemType);
    updateCategories(newItemType);
    onChange('category', '');
    setShowOtherCategory(false);
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    onChange('category', newCategory);
    setShowOtherCategory(newCategory === 'other');
  };

  const currentLanguage = i18n.language;

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <DialogTitle>{isNew ? t('createNewItem') : t('editItem')}</DialogTitle>
      <DialogContent>
        <TextField
          select
          label={t('itemType')}
          value={item.itemType || 'product'}
          onChange={handleItemTypeChange}
          fullWidth
          margin="normal"
        >
          <MenuItem value="product">{t('product')}</MenuItem>
          <MenuItem value="menuItem">{t('menuItem')}</MenuItem>
          <MenuItem value="service">{t('service')}</MenuItem>
        </TextField>
        
        <TextField
          select
          label={t('category')}
          value={item.category || ''}
          onChange={handleCategoryChange}
          fullWidth
          margin="normal"
          required
        >
          {categories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              {currentLanguage === 'es' ? category.esName : category.enName}
            </MenuItem>
          ))}
          <MenuItem value="other">{t('other')}</MenuItem>
        </TextField>

        {showOtherCategory && (
          <TextField
            label={t('otherCategory')}
            value={item.otherCategory || ''}
            onChange={(e) => onChange('otherCategory', sanitizeInput(e.target.value))}
            fullWidth
            margin="normal"
            required
          />
        )}

        <TextField
          label={t('name')}
          value={item.name || ''}
          onChange={(e) => onChange('name', e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label={t('price')}
          type="number"
          value={item.price || 0}
          onChange={(e) => onChange('price', e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label={t('description')}
          value={item.description || ''}
          onChange={(e) => onChange('description', e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={3}
          required
        />
        <Input
          type="file"
          onChange={handleImageChange}
          accept="image/*"
          style={{ display: 'none' }}
          id="item-image-upload"
        />
        <label htmlFor="item-image-upload">
          <Button variant="contained" component="span">
            {imagePreview ? t('changeImage') : t('uploadImage')}
          </Button>
        </label>
        {imagePreview && (
          <img src={imagePreview} alt="Item preview" style={{ maxWidth: '100%', marginTop: '10px' }} />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={item.isAvailable || false}
              onChange={(e) => onChange('isAvailable', e.target.checked)}
            />
          }
          label={t('isAvailable')}
        />
        <FormControlLabel
          control={
            <Switch
              checked={item.isFeatured || false}
              onChange={(e) => onChange('isFeatured', e.target.checked)}
            />
          }
          label={t('isFeatured')}
        />
        <FormControlLabel
          control={
            <Switch
              checked={item.hasDiscount || false}
              onChange={(e) => onChange('hasDiscount', e.target.checked)}
            />
          }
          label={t('hasDiscount')}
        />
        {item.hasDiscount && (
          <>
            <TextField
              label={t('discountPercentage')}
              type="number"
              value={item.discountPercentage || ''}
              onChange={(e) => onChange('discountPercentage', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('discountExpiration')}
              type="datetime-local"
              value={item.discountExpiration || ''}
              onChange={(e) => onChange('discountExpiration', e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        )}
        <TextField
          label={t('stockQuantity')}
          type="number"
          value={item.stockQuantity || 0}
          onChange={(e) => onChange('stockQuantity', e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button type="submit" color="primary">{isNew ? t('create') : t('save')}</Button>
      </DialogActions>
    </form>
  );
};

export default ItemForm;
