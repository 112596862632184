import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mobileSettings } from './settings';

const SearchBar = ({ value, onChange, onSearch }) => {
  const { t } = useTranslation();
  
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    onChange(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value.trim()) {
      onSearch(value);
    }
  };

  const handleClear = () => {
    const event = { target: { value: '' } };
    onChange(event);
    onSearch('');
  };

  return (
    <SearchContainer onSubmit={handleSubmit}>
      <SearchInput 
        type="text" 
        placeholder={t('searchPlaceholder')}
        value={value}
        onChange={handleInputChange}
      />
      {value && (
        <ClearButton type="button" onClick={handleClear}>
          ✕
        </ClearButton>
      )}
      <SearchIcon type="submit">🔍</SearchIcon>
    </SearchContainer>
  );
};

const SearchContainer = styled.form`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 5px 10px;
  flex-grow: 1;
  position: relative;
  margin: 0.4rem 1rem 0 1.5rem;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  padding: 1px;
  padding-right: 64px; /* Make room for both buttons */
`;

const SearchIcon = styled.button`
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
`;

const ClearButton = styled.button`
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  position: absolute;
  right: 40px;
  color: #666;
  
  &:hover {
    color: #333;
  }
`;

export default SearchBar;
