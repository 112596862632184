import apiCall from './apiCall';

const API_ENDPOINT = '/businessPageApi';

export const saveBusinessPage = async (businessPageId, templateRules, userId, companyId, idToken, isTestMode, testModeTemplateRules) => {
  try {
    const response = await apiCall('put', `${API_ENDPOINT}/saveBusinessPage/${businessPageId}`, {
      templateRules,
      testModeTemplateRules,
      isTestMode,
      userId,
      companyId
    }, false, idToken);
    return response;
  } catch (error) {
    console.error('Error saving business page:', error);
    throw error;
  }
};

export const getBusinessPage = async (companyId, idToken) => {
  try {
    const response = await apiCall('get', `${API_ENDPOINT}/${companyId}`, null, false, idToken);
    return response;
  } catch (error) {
    console.error('Error fetching business page:', error);
    throw error;
  }
};

export const createBusinessPage = async (companyId, idToken) => {
  try {
    const response = await apiCall('post', `${API_ENDPOINT}/`, {companyId}, false, idToken);
    return response;
  } catch (error) {
    console.error('Error fetching business page:', error);
    throw error;
  }
};


