import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { updateUser } from '../redux/authSlice';
import '../styles/CompanyDashboard.css';

const CompanyDashboard = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const unsubscribeRefs = useRef({ user: null, company: null });

  useEffect(() => {
    if (user && user.uid && user.companyId) {
      const userRef = doc(db, 'users', user.uid);
      const companyRef = doc(db, 'companies', user.companyId);

      unsubscribeRefs.current.user = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          if (userData.createdAt && typeof userData.createdAt.toDate === 'function') {
            userData.createdAt = userData.createdAt.toDate().toISOString();
          }
          dispatch(updateUser(userData));
        }
      });

      unsubscribeRefs.current.company = onSnapshot(companyRef, (doc) => {
        if (doc.exists()) {
          const companyData = doc.data();
          dispatch(updateUser({ companyProfile: companyData }));
        }
      });

      return () => {
        if (unsubscribeRefs.current.user) unsubscribeRefs.current.user();
        if (unsubscribeRefs.current.company) unsubscribeRefs.current.company();
      };
    }
  }, [user?.uid, user?.companyId, dispatch]);

  console.log({ user });

  let sections = [
    { name: 'profileSettings', path: '/company/profile' },
    { name: 'customizeBusinessPage', path: '/company/website' },
    { name: 'itemsManagement', path: '/company/items' },
    { name: 'rewardsManagement', path: '/company/rewards' },
    { name: 'pointsManagement', path: '/company/points' },
    { name: 'eventsManagement', path: '/company/events' },
    { name: 'calendarManagement', path: '/company/calendar' },
    { name: 'adsManagement', path: '/company/ads' },
    { name: 'galleryManagement', path: '/company/gallery' },
  ];

  if (!user?.isCompanyOwner) {
    //! filter out the ones they are not allowed to access
    // sections = sections.filter(section => section.name !== 'profileSettings');
    sections = [
      { name: 'pointsManagement', path: '/company/points' },
    ];
  }

  return (
    <div className="company-dashboard">
      <h1>{t('companyDashboard')}</h1>
      <h2 style={{ marginBottom: '1rem' }}>{t('welcome', { name: user?.companyProfile?.companyName })}!</h2>
      <div className="company-sections">
        {sections.map((section) => (
          <Link key={section.path} to={section.path} className="company-section-link">
            {t(section.name)}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CompanyDashboard;
