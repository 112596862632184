import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SearchBar from './SearchBar';
import MainBanner from './MainBanner';
import SecondaryBanner from './SecondaryBanner';
import CategoryList from './CategoryList';
import { mobileSettings } from './settings';
import ItemList from './ItemsList';
import { listItems, searchItems } from '../../../api/itemsApi';
import { getNearbyBusinesses } from '../../../api/companyApi';
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import CartModal from './CartModal';
import { useSelector } from 'react-redux';
import { ChevronRight, UserIcon, Check, Info } from 'lucide-react';
import WelcomeModal from './WelcomeModal';
import UserSideBar from './UserSideBar';
import RecommendedProducts from './RecommendedProducts';
import { useNavigate } from 'react-router';
import AdSenseAd from '../../../services/AdSenseAd';
import CompanyActionModal from './CompanyActionModal';
import { userOptIn, userOptOut } from '../../../api/userApi';
import { useAuth } from './../../../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/authSlice';

const CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

const MobileUserHome = () => {
  const { t } = useTranslation();
  const welcomeMessageConfirmed = useSelector(state => state.auth.welcomeMessageConfirmed);
  const userData = useSelector(state => state.auth.user);
  const { idToken } = useAuth();
  const dispatch = useDispatch();
  const [showWelcomeModal, setShowWelcomeModal] = useState(
    !welcomeMessageConfirmed && !userData?.welcomeMessageConfirmed
  );
  const [showWelcomeInfo, setShowWelcomeInfo] = useState(false);
  const [searchResults, setSearchResults] = useState({ items: [], companies: [] });
  const [cartItems, setCartItems] = useState({totalItems: 0, items: []});
  const [locationPermission, setLocationPermission] = useState('prompt');
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isUserIconClicked, setIsUserIconClicked] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [selectedItem, setSelectedItem] = useState(null);
  // const [locationError, setLocationError] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false);

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  console.log('searchResults', searchResults);
  console.log('showSearchResults', showSearchResults);
  console.log('searchQuery', searchQuery);

  useEffect(() => {
    if (isMobile) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        setLocationPermission(result.state);
        result.onchange = () => {
          setLocationPermission(result.state);
        };
      });
    }
  }, [isMobile]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const itemsResponse = await listItems(null, null);
        setItems(itemsResponse);
        localStorage.setItem('mainItems', JSON.stringify({
          data: itemsResponse,
          timestamp: Date.now()
        }));
        setIsInitialLoading(false);
      } catch (error) {
        setError(error);
        setIsInitialLoading(false);
      }
    };

    const loadItems = () => {
      const cachedItems = localStorage.getItem('mainItems');
      if (cachedItems) {
        const { data, timestamp } = JSON.parse(cachedItems);
        if (Date.now() - timestamp < CACHE_DURATION) {
          setItems(data);
          setIsInitialLoading(false);
          return;
        }
      }
      fetchItems();
    };

    loadItems();
  }, []);

  const handleInfoClick = (show) => {
    setShowWelcomeInfo(show);
  };

  const handleUserIconClick = () => {
    setIsUserIconClicked(!isUserIconClicked);
  };
  console.log('searchResults', searchResults);
  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
    setShowCompanyModal(true);
  };
  const handleAddToCart = (item) => {
    if (cartItems.items.find(cartItem => cartItem.uid === item.uid)) {
      setCartItems(prev => {
        const updatedCart = prev.items.map(cartItem => cartItem.uid === item.uid ? { ...cartItem, quantity: ++cartItem.quantity } : cartItem);
        return { totalItems: updatedCart.reduce((acc, curr) => acc + curr.quantity, 0), items: updatedCart };
      });
    } else {
      setCartItems(prev => {
        const updatedCart = [...prev.items, { ...item, quantity: 1 }];
        return { totalItems: updatedCart.reduce((acc, curr) => acc + curr.quantity, 0), items: updatedCart };
      });
    }
  };

  console.log('cartItems', cartItems);

  const handleRemoveFromCart = (item) => {
    if (cartItems.items.find(cartItem => cartItem.uid === item.uid && cartItem.quantity > 1)) {
      setCartItems(prev => {
        const updatedCart = prev.items.map(cartItem => cartItem.uid === item.uid ? { ...cartItem, quantity: --cartItem.quantity } : cartItem);
        return { totalItems: updatedCart.reduce((acc, curr) => acc + curr.quantity, 0), items: updatedCart };
      });
    } else {
      setCartItems(prev => {
        const updatedCart = prev.items.filter(cartItem => cartItem.uid !== item.uid);
        return { totalItems: updatedCart.reduce((acc, curr) => acc + curr.quantity, 0), items: updatedCart };
      });
    }
  };

  const handleSearch = async (query) => {
    if (!query.trim()) {
      setShowSearchResults(false);
      setSearchResults({ items: [], companies: [] });
      return;
    }

    try {
      setIsSearchLoading(true);
      const response = await searchItems(query);
      
      if (locationPermission === 'granted') {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            const companyResults = await getNearbyBusinesses(latitude, longitude, 25);
            const filteredCompanies = companyResults.filter(company =>
              company.companyName.toLowerCase().includes(query.toLowerCase())
            );

            setSearchResults({
              items: response.items || [],
              companies: filteredCompanies || []
            });
            setShowSearchResults(true);
            setIsSearchLoading(false);
          },
          (error) => {
            console.error('Geolocation error:', error);
            setSearchResults({
              items: response.items || [],
              companies: []
            });
            setShowSearchResults(true);
            setIsSearchLoading(false);
          }
        );
      } else {
        setSearchResults({
          items: response.items || [],
          companies: []
        });
        setShowSearchResults(true);
        setIsSearchLoading(false);
      }
    } catch (error) {
      console.error('Search error:', error);
      setError('Failed to perform search');
      setIsSearchLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery === '') {
      setShowSearchResults(false);
      setSearchResults({ items: [], companies: [] });
    }
  }, [searchQuery]);

  console.log('userData', userData);

  const handleVisitSite = () => {
    if (selectedCompany) {
      navigate(`/${selectedCompany.uniqueUrl}`, { replace: true });
      setShowCompanyModal(false);
    }
  };

  const handleToggleRewards = async (isOptedIn) => {

    if (isOptedIn) {  
      try {
        console.log('Toggle rewards for company:', selectedCompany?.uid);
        await userOptOut(userData?.uid, selectedCompany?.uid, idToken);
        dispatch(updateUser({ ...userData, optedInCompanies: userData.optedInCompanies.filter(companyId => companyId !== selectedCompany?.uid) }));
      } catch (error) {
        console.error('Error toggling rewards:', error);
      }
    } else {
      try {
        console.log('Toggle rewards for company:', selectedCompany?.uid);
        await userOptIn(userData?.uid, selectedCompany?.uid, idToken);
        dispatch(updateUser({ ...userData, optedInCompanies: [...userData.optedInCompanies, selectedCompany?.uid] }));
      } catch (error) {
        console.error('Error toggling rewards:', error);
      }
    }
    setShowCompanyModal(false);
  };

  if (isInitialLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#3498db' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container className={userData?.uid ? 'mobile-user-home' : 'mobile-user-home-guest'}>
      <Header style={{ backgroundColor: "#3498db", padding: '0px' }}>
        <TopRow>
          {
            userData ?
              <img onClick={handleUserIconClick} src={userData.avatar} alt='user' style={{ width: '25px', height: '25px', borderRadius: '50%', marginLeft: '10px', marginTop: '0.4rem' }} />
            :
              <UserIcon onClick={handleUserIconClick} size={25} color='white' style={{ marginLeft: '10px' }} />
          }
          <SearchBar 
            onSearch={handleSearch}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <CartIcon style={{marginTop: '0.4rem' }}>
            🛒
            {cartItems.totalItems > 0 && <CartBadge onClick={() => setIsCartOpen(!isCartOpen)}>{cartItems.totalItems}</CartBadge>}
          </CartIcon>
        </TopRow>
        {
          userData?.zipCode ? (
            <PostalCodeContainer>
              <PostalCodeText><span style={{ marginRight: '4px', marginLeft: '10px', fontSize: '13px' }}>📍</span> {userData?.zipCode || 'Unknown'} </PostalCodeText>
              <PostalCodeText> <ChevronRight size={16} /> </PostalCodeText>
              <PostalCodeText onClick={() => { handleInfoClick(true); }} style={{ cursor: 'pointer', position: 'absolute', right: '40px'}}> <Info size={20} color='white' /> </PostalCodeText>
            </PostalCodeContainer>
          ) 
          :
          (
            <PostalCodeContainer style={{ padding: '15px 5px' }}>
              <PostalCodeText onClick={() => { handleInfoClick(true); }} style={{ cursor: 'pointer', position: 'absolute', right: '40px' }}> <Info size={20} color='white' /> </PostalCodeText>
            </PostalCodeContainer>
          )
        }
      </Header>

      {showSearchResults ? (
        <SearchResultsContainer>
          {isSearchLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <>
              {searchResults.items.length > 0 && (
                <ResultSection>
                  <ResultTitle>{t('products')}</ResultTitle>
                  {searchResults.items.map(item => (
                    <ResultItem key={item.uid}>
                      <ItemImage src={item.image} alt={item.name} />
                      <ItemInfo>
                        <ItemName>{item.name}</ItemName>
                        <ItemPrice>${item.price}</ItemPrice>
                        <ItemDescription>{item.description}</ItemDescription>
                      </ItemInfo>
                      <ItemCompany>{item.companyName}</ItemCompany>
                    </ResultItem>
                  ))}
                </ResultSection>
              )}

              {searchResults.companies.length > 0 && (
                <ResultSection>
                  <ResultTitle>{t('nearbyBusinesses')}</ResultTitle>
                  {searchResults.companies.map(company => (
                    <ResultItem key={company.uid} onClick={() => handleCompanyClick(company)}>
                      <CompanyImage src={company.logo} alt={company.companyName} />
                      <CompanyInfo>
                        <CompanyName>{company.companyName}</CompanyName>
                        <CompanyAddress>
                          {company.address?.street || t('addressNotAvailable')}
                        </CompanyAddress>
                      </CompanyInfo>
                      {userData?.optedInCompanies?.includes(company.uid) && (
                        <OptedInCheck>
                          <Check size={20} color="#22c55e" />
                        </OptedInCheck>
                      )}
                    </ResultItem>
                  ))}
                </ResultSection>
              )}

              {searchResults.items.length === 0 && searchResults.companies.length === 0 && (
                <NoResults>{t('noItemsFound')}</NoResults>
              )}
            </>
          )}
        </SearchResultsContainer>
      ) : (
        <ContentWrapper>
          <MainBanner ads={mobileSettings.mainBannerAds} />
          <CategoryList categories={mobileSettings.categories} />
          {/* <AdSenseContainer>
            <AdSenseAd />
          </AdSenseContainer> */}
          <SecondaryBanner ads={mobileSettings.secondaryBannerAds} />
          {
            items?.length > 0 && (
              <>
                <ItemList 
                items={items} 
                cartItems={cartItems}
                onAddToCart={handleAddToCart}
                onRemoveFromCart={handleRemoveFromCart}
                />
                <RecommendedProducts 
                  items={items} 
                  cartItems={cartItems} 
                  onAddToCart={handleAddToCart} 
                  onRemoveFromCart={handleRemoveFromCart} 
                />
              </>
            )
          }
        </ContentWrapper>
      )}
      <CartModal 
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        cartItems={cartItems}
        onIncreaseQuantity={handleAddToCart}
        onDecreaseQuantity={handleRemoveFromCart}
      />
      <WelcomeModal 
        isOpen={showWelcomeModal}
        onClose={() => { setShowWelcomeModal(false); handleInfoClick(false); }}
        showWelcomeInfo={showWelcomeInfo}
      />
      <UserSideBar 
        isOpen={isUserIconClicked}
        onClose={() => setIsUserIconClicked(false)}
      />
      <CompanyActionModal 
        isOpen={showCompanyModal}
        onClose={() => setShowCompanyModal(false)}
        company={selectedCompany}
        isOptedIn={userData?.optedInCompanies?.includes(selectedCompany?.uid)}
        onVisitSite={handleVisitSite}
        onToggleRewards={handleToggleRewards}
        userData={userData}
      />
    </Container>
  );
};


const AdSenseContainer = styled.div`
  /* border: 1px solid red;
  height: 70px;
  max-height: 70px;
  position: relative;
  margin-top: 10px;
  overflow: hidden; */
`;

const PostalCodeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1px 5px 0px 0px;
`;

const PostalCodeText = styled.div`
  padding: 2px;
  font-size: 14px;
`;

const ItemCompany = styled.span`
  color: #666;
  font-size: 14px;
  margin-top: 4px;
`;

const ItemDescription = styled.span`
  color: #666;
  font-size: 14px;
  margin-top: 4px;
`;

const Container = styled.div`
  /* gradient from the red to the white, but red only for the first 100 pixels */
  background: linear-gradient(to bottom, #3498db 0%, #3498db 17%, #f0f3f6 27%);
  color: ${mobileSettings.colors.text};
  font-family: Arial, sans-serif;
`;

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${mobileSettings.colors.primary};
  position: sticky;
  top: 0;
  z-index: 100;
  padding-top: 0px;
  margin-top: 0px;
`;

const TopRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 7px;
`;

const MenuIcon = styled.div`
  font-size: 24px;
  margin-right: 10px;
`;

const CartIcon = styled.div`
  font-size: 24px;
  margin-left: 10px;
  position: relative;
`;

const ContentWrapper = styled.div`
  padding: 0px 5px 5px 5px;
`;

const CartBadge = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
`;

const SearchResultsContainer = styled.div`
  padding: 16px;
  background-color: white;
`;

const ResultSection = styled.div`
  margin-bottom: 24px;
`;

const ResultTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 12px;
  color: #333;
`;

const ResultItem = styled.div`
  display: flex;
  padding: 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  position: relative;
  
  &:hover {
    background-color: #f5f5f5;
  }
`;

const ItemImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 12px;
`;

const CompanyImage = styled(ItemImage)`
  border-radius: 25px;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyInfo = styled(ItemInfo)``;

const ItemName = styled.span`
  font-weight: bold;
  margin-bottom: 4px;
`;

const CompanyName = styled(ItemName)``;

const ItemPrice = styled.span`
  color: #666;
`;

const CompanyAddress = styled.span`
  color: #666;
  font-size: 14px;
`;

const NoResults = styled.div`
  text-align: center;
  padding: 24px;
  color: #666;
`;

const OptedInCheck = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  background: white;
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

export default MobileUserHome;
