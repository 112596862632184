import apiCall from './apiCall';

const API_ENDPOINT = '/userApi';

export const loginUser = async (email, idToken) => {
  try {
    console.log('Attempting to login user:', email);
    const response = await apiCall('post', `${API_ENDPOINT}/login`, { email }, false, idToken);
    console.log('API Response:', response);
    const userData = response?.user || {};
    console.log('userData:', userData);
    if (!userData.uid) {
      console.error('User data does not contain a uid. Full response:', response);
      throw new Error('Invalid user data received from server');
    }
    return userData;
  } catch (error) {
    console.error("Error during login process:", error);
    throw error;
  }
};

export const createUserPin = async (userId, pin, idToken) => {
  try {
    const response = await apiCall('post', `${API_ENDPOINT}/${userId}/pin`, { pin }, false, idToken);
    return response;
  } catch (error) {
    console.error("Error creating user PIN:", error);
    throw error;
  }
};

export const updateUserPin = async (userId, oldPin, newPin, idToken) => {
  try {
    const response = await apiCall('put', `${API_ENDPOINT}/${userId}/pin`, { oldPin, newPin }, false, idToken);
    return response;
  } catch (error) {
    console.error("Error updating user PIN:", error);
    throw error;
  }
};

export const userOptIn = async (userId, companyId, idToken) => {
  const response = await apiCall('post', `${API_ENDPOINT}/${userId}/opt-in/${companyId}`, { optedIn: true }, false, idToken);
  return response;
};

export const userOptOut = async (userId, companyId, idToken) => {
  const response = await apiCall('post', `${API_ENDPOINT}/${userId}/opt-out/${companyId}`, { optedIn: false }, false, idToken);
  return response;
};

export const logUser = async (message) => {
  const response = await apiCall('post', `${API_ENDPOINT}/log`, { log: message }, false);
  return response;
};

// Add this new function
export const getOptedInCompanies = async (userId, idToken) => {
  try {
    const response = await apiCall('get', `${API_ENDPOINT}/${userId}/opted-in-companies`, null, false, idToken);
    return response;
  } catch (error) {
    console.error("Error fetching opted-in companies:", error);
    throw error;
  }
};
