import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemText, CircularProgress, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getTransactions } from '../../api/transactionsApi';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';
import { updateUser } from '../../redux/authSlice';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const RecentTransactions = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { idToken } = useAuth();
  const dispatch = useDispatch();
  const unsubscribeRef = useRef();
  const lastFetchTimeRef = useRef(0);

  const fetchTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const transactionsResponse = await getTransactions(user.uid, null, idToken);
      // Sort transactions by date (newest first) and take the last 3
      const recentTransactions = transactionsResponse.transactions
        .sort((a, b) => b.createdAt?._seconds - a.createdAt?._seconds)
        .slice(0, 3);
      localStorage.setItem('recentTransactions', JSON.stringify({
        data: recentTransactions,
        timestamp: Date.now()
      }));
      setTransactions(recentTransactions);
      lastFetchTimeRef.current = Date.now();
      setLoading(false);
    } catch (err) {
      console.error('Error fetching transactions:', err);
      setError(t('errorFetchingTransactions'));
      setLoading(false);
    }
  }, [user?.uid, idToken, t]);

  useEffect(() => {
    if (user && user.uid) {
      const userRef = doc(db, 'users', user.uid);
      unsubscribeRef.current = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          if (userData.createdAt && typeof userData.createdAt.toDate === 'function') {
            userData.createdAt = userData.createdAt.toDate().toISOString();
          }
          dispatch(updateUser(userData));
        }
      });

      return () => {
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
        }
      };
    }
  }, [user?.uid, dispatch]);

  useEffect(() => {
    const loadTransactions = () => {
      const cachedTransactions = localStorage.getItem('recentTransactions');
      if (cachedTransactions) {
        const { data, timestamp } = JSON.parse(cachedTransactions);
        if (Date.now() - timestamp < CACHE_DURATION) {
          setTransactions(data);
          setLoading(false);
          return;
        }
      }
      fetchTransactions();
    };

    if (idToken && user?.uid) {
      loadTransactions();
    }
  }, [idToken, user?.uid, fetchTransactions]);

  const getTransactionTypeDisplay = (type) => {
    switch (type) {
      case 'points_added':
        return t('pointsEarned');
      case 'points_adjustment':
        return t('pointsAdjustment');
      case 'reward_redemption':
        return t('rewardRedeemed');
      default:
        return type;
    }
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'success';
      case 'pending':
        return 'warning';
      case 'failed':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        padding: { xs: 1, sm: 2 }, 
        backgroundColor: '#adcce9',
        borderRadius: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 16px)',
        margin: 1,
        marginTop: 3,
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
        {t('recentTransactions')}
      </Typography>
      <Box 
        sx={{ 
          backgroundColor: '#adcce9',
          borderRadius: 1, 
          padding: { xs: 1, sm: 2 },
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : transactions.length === 0 ? (
          <Typography color="#B0B3B8">{t('noRecentTransactions')}</Typography>
        ) : (
          <List>
            {transactions.map((transaction) => (
              <ListItem key={`${transaction.uid}-${transaction?.createdAt?._seconds}`} divider>
                <ListItemText
                  primary={
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography color="#333">{getTransactionTypeDisplay(transaction?.type)}</Typography>
                      {transaction?.receiptTotal && <Typography color="#333">Receipt Total: {transaction?.receiptTotal}</Typography>}
                      {transaction?.pointsUsed && <Typography color="#333">Points Used: {transaction?.pointsUsed}</Typography>}
                      <Chip 
                        label={t(transaction?.status?.toLowerCase() || 'unknown')}
                        color={getStatusColor(transaction?.status?.toLowerCase() || 'unknown')}
                        size="small"
                      />
                    </Box>
                  }
                  secondary={
                    <Typography color="#333">
                      {`${new Date(transaction?.createdAt?._seconds * 1000)?.toLocaleDateString() || 'Date Error'} - ${transaction?.pointsAdded || transaction?.pointsUsed || 0} ${t('points')}`}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Paper>
  );
};

export default RecentTransactions;
